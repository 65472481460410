import Group = Phaser.GameObjects.Group;
import {SkinItem} from "../../../types/skin";

export default class NormalBgParticles extends Group {
    private _bgLayer1: Phaser.GameObjects.Image;
    constructor(scene, parent: Phaser.GameObjects.Container, bgLayer1: Phaser.GameObjects.Image, skin:SkinItem) {
        super(scene);

        const grid = {
            width: 8,
            height: 12,
            cellWidth: 100,
            cellHeight: 100,
        }

        this._bgLayer1 = bgLayer1;

        this.createMultiple({
            classType: Phaser.GameObjects.Image,
            quantity: 30,
            key: skin.key,
            frame: skin.data!.frames.slice(),
            randomKey: true,
            gridAlign: grid
        });

        this.getChildren().forEach((child) => {
            const particle = child as Phaser.GameObjects.Image;
            particle.x -= grid.cellWidth * grid.width / 2;
            particle.y -= grid.cellHeight * grid.height / 2 - this._bgLayer1.displayHeight * 0.1;
            particle.setScale(Phaser.Math.FloatBetween(0.05, 0.15));
            parent.add(particle);
            this._animate(particle);
        });


    }

    private _animate(particle) {
        const iterateX = Phaser.Math.Between(-50, 50);
        const iterateY = Phaser.Math.Between(-200, 200);
        let duration = Phaser.Math.Between(1200, 2400);

        let newPos = {
            x: particle.x + iterateX,
            y: particle.y + iterateY,
        }

        if (newPos.x < this._bgLayer1.x - this._bgLayer1.displayWidth * 0.31) {
            newPos.x = this._bgLayer1.x + this._bgLayer1.displayWidth * 0.31;
            duration = 0;
        } else if (newPos.x > this._bgLayer1.x + this._bgLayer1.displayWidth * 0.31) {
            newPos.x = this._bgLayer1.x - this._bgLayer1.displayWidth * 0.31;
            duration = 0;
        } else if (newPos.y < this._bgLayer1.y - this._bgLayer1.displayHeight * 0.5) {
            newPos.y = this._bgLayer1.y + this._bgLayer1.displayHeight * 0.5;
            duration = 0;
        } else if (newPos.y > this._bgLayer1.y + this._bgLayer1.displayHeight * 0.5) {
            newPos.y = this._bgLayer1.y - this._bgLayer1.displayHeight * 0.5;
            duration = 0;
        }

        if (duration === 0) {
            this.scene.tweens.add({
                targets: particle,
                alpha: 0,
                duration: 100,
                ease: 'Sine.easeOut',
                onComplete: () => {
                    particle.x = newPos.x;
                    particle.y = newPos.y;
                    this._animate(particle);
                }
            });
        } else {
            this.scene.tweens.add({
                targets: particle,
                x: newPos.x,
                y: newPos.y,
                alpha: 1,
                duration: duration,
                ease: 'Sine.easeInOut',
                onComplete: () => {
                    this._animate(particle);
                }
            });
        }

    }
}
