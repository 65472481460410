import {VisualObject} from "../../types/visualObject";

export function createPinsWaveAnimation(scene: Phaser.Scene, pinPositions: { tileIndex: number, rowIndex: number, view:VisualObject }[], startRowIndex: number) {
    pinPositions.forEach((pinPosition, index) => {
        const pin = pinPosition.view;
        const rowIndex = pinPosition.rowIndex;
        const pinLight = pin.getData('light');
        const blinkTween = scene.tweens.chain({
            targets: pinLight,
            delay: 2000 - (rowIndex - startRowIndex) * 100,
            repeat: -1,
            repeatDelay: 1000,
            tweens: [
                {
                    alpha: 1,
                    duration: 200,
                    ease: 'Sine.easeIn',
                }, {
                    alpha: 0,
                    duration: 200,
                    delay: 1000,
                    ease: 'Sine.easeOut',
                }
            ]
        });

        pin.setData('bonusBlinkTween', blinkTween);
    });
}
