import {createBorderGlowAnimation, createWheelBackgroundFxAnimation} from "./tweens/wheelBackgroundFxAnimation";

export default class WheelSceneBackground extends Phaser.GameObjects.Container {
    private _borderGlowLeft: Phaser.GameObjects.Image;
    private _bgGlow: Phaser.GameObjects.Image;
    private _glowPostFx: Phaser.FX.Bloom;
    private _glowPostFxTween: Phaser.Tweens.TweenChain;
    private _starEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private _borderGlowRight: Phaser.GameObjects.Image;
    private _borderGlowAnimation: Phaser.Tweens.TweenChain;
    private _wsbg: Phaser.GameObjects.Image;

    public get bgWidth(): number {
        return this._wsbg.displayWidth * this.scaleX;
    }

    public get bgHeight(): number {
        return this._wsbg.displayHeight * this.scaleY;
    }
    constructor(scene: Phaser.Scene) {
        super(scene);

        this._create();
    }

    _create() {
        this._wsbg = this.scene.add.image(0, 0, 'wheel_screen_bg');
        this.add(this._wsbg);

        this._createBgEffect();
        this._createBgParticles();
        this._createBgBorder();

    }

    private _createBgEffect() {
        this._bgGlow = this.scene.add.image(0, 0, 'wheel_screen_bg_glow');
        this._bgGlow.setPosition(0, (this._wsbg.displayHeight - this._bgGlow.displayHeight) * 0.5);
        this._glowPostFx = this._bgGlow.postFX.addBloom(0xF2F57E, 1, 1, 1, 1);
        this.add(this._bgGlow);
    }

    _createBgParticles() {
        const emitZone1 = { type: 'random', source: this._wsbg.getBounds(), quantity: 30 };

        const emitter = this._starEmitter = this.scene.add.particles(0, 0, 'wheel_sparkle', {
            speed: 50,
            lifespan: 1200,
            scale: { start: 1, end: 0, random: true },
            rotate: { start: 0, end: 360, random: true },
            emitZone: emitZone1,
            frequency: 100,
            emitting: false,
        });

        this.add(emitter);
    }

    _createBgBorder() {
        const border = this.scene.add.container(0, 0);
        const borderBars = this.scene.add.image(0, 0, 'wheel_screen_border_bars');
        border.add(borderBars);

        const borderGlowLeft = this._borderGlowLeft = this.scene.add.image(-borderBars.displayWidth * 0.44, 0, 'wheel_screen_border_glow');
        border.add(borderGlowLeft);
        borderGlowLeft.blendMode = Phaser.BlendModes.ADD;

        const borderGlowRight = this._borderGlowRight = this.scene.add.image(borderBars.displayWidth * 0.44, 0, 'wheel_screen_border_glow');
        border.add(borderGlowRight);
        borderGlowRight.blendMode = Phaser.BlendModes.ADD;
        this.add(border);
    }

    playEffect() {
        this._glowPostFxTween = this.scene.tweens.chain(createWheelBackgroundFxAnimation(this._glowPostFx));

        this._borderGlowAnimation = this.scene.tweens.chain(createBorderGlowAnimation(this._borderGlowLeft, this._borderGlowRight));

        this._starEmitter.start();
    }

    hideGlow() {
        if (this._glowPostFxTween) {
            this._glowPostFxTween.stop();
        }

        this.scene.tweens.add({
            targets: this._bgGlow,
            alpha: 0,
            duration: 300,
            ease: 'Quad.easeIn',
            onComplete: () => {
                this._bgGlow.setVisible(false);
            }
        });
    }

    stop () {
        if (this._glowPostFxTween) {
            this._glowPostFxTween.stop();
        }

        if (this._borderGlowAnimation) {
            this._borderGlowAnimation.stop();
        }

        if (this._starEmitter) {
            this._starEmitter.stop();
        }
    }
}
