import {EVENTS} from '../utils/events';
import {Skin, SkinItem} from "../types/skin";
export default class Launcher extends Phaser.GameObjects.Container {
    // @ts-ignore
    private _directionTweenUp: Phaser.Tweens.Timeline | null;
    private _directionTweenDown: Phaser.Tweens.Tween | null;
    private _direction: { x: number };
    private _border: Phaser.Physics.Arcade.Group;
    private _balls: Phaser.Physics.Arcade.Group;
    private _ballsCount: number;
    private _inContainerQuantity: number;
    private _power: number = 1;
    private _activeSkin: Skin;
    get isRunning(): boolean {
        return this._isRunning;
    }
    static readonly EVENTS = {
        POWER_RELEASED: 'power_released',
    };
    private _sign: number = 1;
    private _isRunning: boolean = false;
    constructor(scene: Phaser.Scene, x: number, y: number, private _maxPower: number, skin:Skin, ballsCount: number = 100, bonusType?: string) {
        super(scene);
        this.x = x;
        this.y = y;
        this._direction = {
            x: 0,
        }
        this._ballsCount = ballsCount;
        this._inContainerQuantity = 14;
        this._activeSkin = skin;

        this._create(bonusType);
    }

    private _create(bonusType?: string) {
        const launcherBottomSkin = this._activeSkin.launcherBottom as SkinItem;
        const launcher = this.scene.add.image(launcherBottomSkin.position.x, launcherBottomSkin.position.y, launcherBottomSkin.key);
        if (bonusType && launcherBottomSkin.data && launcherBottomSkin.data.highlights) {
            launcher.setTint(launcherBottomSkin.data.highlights[bonusType][0]);
        }
        launcher.setOrigin(launcherBottomSkin.origin.x, launcherBottomSkin.origin.y);
        this.add(launcher);

        const border = this._border = this.scene.physics.add.group({
            defaultKey: 'ball',
            immovable: true,
            allowGravity: false,
        });

        const created = border.createMultiple({
            quantity: 20,
            key: border.defaultKey,
            frame: 0,
            visible: false,
        });
        this.add(created);

        for (const ball of border.getChildren()) {
            // @ts-ignore
            ball.setCircle(20);
        }

        // @ts-ignore
        Phaser.Actions.PlaceOnCircle(created, { x: launcherBottomSkin.position.x, y: launcherBottomSkin.position.y, radius: 140 });

        this._addBalls();

        this.scene.physics.world.on(EVENTS.WORLD_STEP, () => {
            this._border.rotateAround(new Phaser.Math.Vector2(0, 0), 0.03);
        });
    }

    private _addBalls() {
        const balls = this._balls = this.scene.physics.add.group({
            defaultKey: 'ball',
            bounceX: 1,
            bounceY: 1,
        });

        const created = balls.createMultiple({
            quantity: this._inContainerQuantity,
            key: balls.defaultKey,
            frame: 0,
        });
        this.add(created);

        for (const ball of balls.getChildren()) {
            // @ts-ignore
            ball.setCircle(15);
        }

        this.scene.physics.add.collider(balls, this._border);
        this.scene.physics.add.collider(balls, balls);
    }

    private _addBall() {
        const currentCount = this._balls.children.size;
        if (this._ballsCount > currentCount && currentCount < this._inContainerQuantity) {
            const ball = this._balls.create(0, 0, 'ball');
            ball.setCircle(15);
            this.add(ball);
        }
    }

    public setBallsCount(count: number) {
        console.log('setBallsCount', count, this._ballsCount);
        const currentCount = this._balls.children.size;
        if (count < currentCount) {
            const difference = currentCount - count;
            for (let i = 0; i < difference; i++) {
                this._balls.remove(this._balls.getFirst(true), true, true);
            }
        } else if (count > currentCount && count < this._inContainerQuantity) {
            const difference = count - currentCount;
            for (let i = 0; i < difference; i++) {
                this._addBall();
            }

        }
        this._ballsCount = count;
    }

    public setPower(power: number) {
        this._power =  1 / power;
    }

    public start() {
        this._isRunning = true;

        this.scene.events.once(EVENTS.LAUNCH_UP, this._onLaunch, this);

        if (this._directionTweenUp) {
            this._directionTweenUp.stop();
            this._directionTweenUp = null;
        }

        if (this._directionTweenDown) {
            this._directionTweenDown.stop();
            this._directionTweenDown = null;
        }

        this._directionTweenUp = this.scene.tweens.chain({
            targets: [this._direction],
            tweens: [{
                x: 0,
                duration: 500 * this._power,
            }],
            ease: 'Linear',
            onComplete: () => {
                this._onLaunch();
            }
        });
    }

    private _onLaunch() {
        if (this._directionTweenUp) {
            this._directionTweenUp.stop();
            this._directionTweenUp = null;
        }

        this.scene.events.off(EVENTS.LAUNCH_UP, this._onLaunch, this);
        this.emit(Launcher.EVENTS.POWER_RELEASED, this._direction.x);

        this._sign = Math.random() > 0.5 ? 1 : -1;
        this._direction.x = this._sign * this._maxPower;

        this._directionTweenDown = this.scene.tweens.add({
            targets: [this._direction],
            x: 0,
            ease: 'Linear',
            duration: 500 * this._power
        });

        this._isRunning = false;
    }

    public getPower(): number {
        return this._direction.x / this._maxPower;
    }

    public destroy(fromScene?: boolean) {
        if (this._directionTweenUp) {
            this._directionTweenUp.stop();
            this._directionTweenUp = null;
        }

        if (this._directionTweenDown) {
            this._directionTweenDown.stop();
            this._directionTweenDown = null;
        }

        this.scene.events.off(EVENTS.LAUNCH_UP, this._onLaunch, this);
        super.destroy(fromScene);
    }
}
