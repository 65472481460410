import 'phaser'
import MainScene from './scenes/mainScene'
import PreloadScene from './scenes/preloadScene'
import Game from './game'
import {SpinePlugin} from "@esotericsoftware/spine-phaser"
import WheelScene from "./scenes/wheelScene"
import type { LinkoApi } from "./types/api/responseTypes"
import { setImplementation } from "./api";
const DEFAULT_WIDTH = 1820
const DEFAULT_HEIGHT = 1024

const config = {
  type: Phaser.AUTO,
  transparent: true,
  scale: {
    parent: 'phaser-game',
    mode: Phaser.Scale.HEIGHT_CONTROLS_WIDTH,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT
  },
  physics: {
    default: "arcade",
    arcade: {
        gravity: { y: 440 },
        debug: false
    }
  },
  plugins: {
    scene: [
      { key: "spine.SpinePlugin", plugin: SpinePlugin, mapping: "spine" }
    ]
  },
  scene: [PreloadScene, MainScene, WheelScene],
}

export default (linkoApi?: LinkoApi) => {
  new Game(config);

  console.log(linkoApi, '__LINKO_API__');

  if (linkoApi) {
    setImplementation(linkoApi);
  }
}
