import PlinkoController from "../controllers/plinkoController";
import { EVENTS } from './events';
import {BalanceResponse, BonusRoundConfig, WheelRoundConfig} from "../types/api/responseTypes";
export default class Balance {
  get serverBalanceChanges(): number[] {
    return this._serverBalanceChanges;
  }

  private _serverBalance: number | null;
  private _localBalance: number = 0;
  private _bonusBalance: number = 0;

  private _serverBalanceChanges: number[] = [];
  private _totalBonusEarnings: number = 0;
  private _bonusRoundId: string | null = null;

  private _controller: PlinkoController;
  private _pendingWheelRounds: WheelRoundConfig[] = [];
  private _pendingBonusRounds: BonusRoundConfig[] = [];

  constructor(controller: PlinkoController) {
    this._controller = controller;
  }

  get hasBalance(): boolean {
    if (this.isBonusRound) {
      return this._bonusBalance > 0;
    }
    return this._localBalance > 0 && (this._serverBalance == null || this._serverBalance > 0);
  }

  get currentBonusRoundId(): string {
    if (this._bonusRoundId == null) {
      throw new Error("Invalid state: no bonus round");
    }
    return this._bonusRoundId!;
  }

  get isBonusRound(): boolean {
    return this._bonusRoundId != null;
  }

  get totalBonusEarnings(): number {
    return this._totalBonusEarnings;
  }

  get localBalance(): number {
    return this._localBalance;
  }

  get displayBalance(): number {
    if (this.isBonusRound) {
      return this._bonusBalance;
    }
    return this._localBalance;
  }

  public setBonusRound(bonusRound: BonusRoundConfig) {
      this._totalBonusEarnings = 0;
      this._bonusRoundId = bonusRound.id;
      this._bonusBalance = bonusRound.balls;
      this._serverBalanceChanges = [];
      this.validateBalanceChange(null, null, false);
  }

  public endBonusRound() {
    this._totalBonusEarnings = 0;
    this._bonusRoundId = null;
    this._serverBalanceChanges = [];
    this.validateBalanceChange(null, null, false);
  }

  public getPendingBonusRound(): string | null {
    if (this._pendingBonusRounds.length > 0) {
        return this._pendingBonusRounds[0].id;
    }
    return null;
  }

  public getPendingWheelRound(): string | null {
    if (this._pendingWheelRounds.length > 0) {
        return this._pendingWheelRounds[0].id;
    }
    return null;
  }

  public updateServerState(response: BalanceResponse, operationComplete: boolean): Balance {
    console.log('BALANCE: updateServerState', response);
    this._serverBalance = response.balance;
    this._pendingBonusRounds = response.bonusRounds || this._pendingBonusRounds;
    this._pendingWheelRounds = response.wheelRounds || this._pendingWheelRounds;
    this.validateBalanceChange(null, null, operationComplete);
    return this;
  }

  public pushBalanceChange(balanceChange: number, bonusBalanceChange: number = 0): Balance {
    if (balanceChange !== -Infinity && bonusBalanceChange!== -Infinity) {
      this._serverBalanceChanges.push(balanceChange || bonusBalanceChange);
      if (this.isBonusRound && bonusBalanceChange > 0 && bonusBalanceChange !== -Infinity) {
        this._totalBonusEarnings += bonusBalanceChange;
      }
    }
    console.log('BALANCE: pushBalanceChange', balanceChange, bonusBalanceChange, this._serverBalanceChanges);
    return this;
  }

  public validateBalanceChange(balanceChange: number | null = null, bonusBalanceChange: number | null = null, operationComplete: boolean = true): Balance {
    console.log('BALANCE: validateBalanceChange', 'hasBalance', this.hasBalance, 'isBonusRound', this.isBonusRound, balanceChange, operationComplete, this._serverBalanceChanges, this._serverBalance);
    if (balanceChange != null || bonusBalanceChange != null) {

      if (balanceChange != null) {
          this._removeBalanceChange(balanceChange);
      }

      if (bonusBalanceChange != null) {
          this._removeBalanceChange(bonusBalanceChange);
      }

      if (this.isBonusRound) {
        if (balanceChange) {
          this._bonusBalance = Math.max(0, Math.max(this._bonusBalance + balanceChange));
        }
        if (bonusBalanceChange) {
          this._localBalance = Math.max(0, Math.max(this._localBalance + bonusBalanceChange));
        }
      } else if (balanceChange) {
        this._localBalance = Math.max(0, Math.max(this._localBalance + balanceChange));
      }
    }
    if (operationComplete) {
      if (this._serverBalanceChanges.length === 0) {
        if (this._serverBalance != null) {
          console.log('BALANCE: No balance changes! update', this._serverBalance);
          this._localBalance = this._serverBalance;
          this._serverBalance = null;
        }
        this._controller.emit(EVENTS.BALANCE_CHANGE_VALIDATED, this, this._localBalance);
      }
    }

    this._controller.emit(EVENTS.BALANCE_CHANGE, this, balanceChange, bonusBalanceChange);

    if (!this.hasBalance) {
        if(this.isBonusRound) {
          if (operationComplete && this._serverBalanceChanges.length === 0) {
            console.log('BALANCE: Emit bonus round complete', this._totalBonusEarnings);
            this._controller.emit(EVENTS.BONUS_ROUND_COMPLETE, this._totalBonusEarnings);
          }
        } else {
          this._controller.emit(EVENTS.NO_BALLS_LEFT, this);
        }
    }
    return this;
  }

  _removeBalanceChange(balanceChange: number) {
    let idx = this._serverBalanceChanges.indexOf(balanceChange);
    if (idx > -1) {
      this._serverBalanceChanges.splice(idx, 1);
    } else {
      console.log('WARN: balance change not found', { balanceChange });
    }
  }

  public updateBalance(balanceChange: number) {
    console.log('BALANCE: updateBalance', {balanceChange, localBalance: this._localBalance});
    this.pushBalanceChange(balanceChange);
    this.validateBalanceChange(balanceChange, null, false);
  }
}
