import seedrandom from "seedrandom";

import * as EasyStar from "../libs/easystar";
import {VisualObject} from "../types/visualObject";
import Balance from '../utils/balance';
import api from "../api";
import {TargetSlotResponse, MissSide} from "../types/api/responseTypes";
import {BOARD_INDEX} from "../data/constants";
import Tabs from "../objects/tabs";
import Phaser, {Scene} from "phaser";
import Tween = Phaser.Tweens.Tween;
import TweenChain = Phaser.Tweens.TweenChain;
import {createRingGlowTween} from "../objects/factories/railFactory";
import Ball from "../objects/ball";

console.log('EasyStar', EasyStar);

type PlinkoPosition = { tileIndex: number; rowIndex: number };
type PlinkoObjectPosition = { tileIndex: number; rowIndex: number; radius: number; view: VisualObject };

export default class PlinkoController extends Phaser.Events.EventEmitter {
    get tabs(): { tileIndex: number; rowIndex: number; view: VisualObject }[] {
        return this._tabs;
    }
    get rails(): Array<{startTile: PlinkoPosition, endTile: PlinkoPosition, path: Phaser.Curves.Path, view: VisualObject}> {
        return this._rails;
    }
    get bumpers(): PlinkoObjectPosition[] {
        return this._bumpers;
    }
    get pinPositions(): PlinkoObjectPosition[] {
        return this._pinPositions;
    }
    private _launcherPosition: PlinkoPosition;
    get topStartPosition(): PlinkoPosition {
        return this._topStartPosition;
    }
    private _lastPathId: number;
    get specialPinPositions(): PlinkoObjectPosition[] {
        return this._specialPinPositions;
    }
    get startPositions(): PlinkoPosition[] {
        return this._startPositions;
    }
    get droppedItems(): Ball[] {
        return this._droppedItems;
    }
    get balance(): Balance {
        return this._balance;
    }
    private _map: Array<Array<number>>;
    private _easyStar: EasyStar.js;
    private _finishPositions: PlinkoPosition[];
    private _outsideFinishPositions: PlinkoPosition[];
    private _startPositions: PlinkoPosition[];
    private _availableTiles:  PlinkoPosition[];
    private _scene: Phaser.Scene;
    private _costFields: PlinkoPosition[];
    private _tileSize: { width: number; height: number };
    private _movementRandomness: number;
    private _droppedItems: Ball[];
    private _pinPositions: PlinkoObjectPosition[];
    private _specialPinPositions: PlinkoObjectPosition[];
    private _topStartPosition: PlinkoPosition;
    private _bumpers: PlinkoObjectPosition[];
    private _tabs: { tileIndex: number, rowIndex: number, view: VisualObject }[];
    private _rails: Array<{startTile: PlinkoPosition, endTile: PlinkoPosition, path: Phaser.Curves.Path, view: VisualObject}>;
    private _balance: Balance;
    public static EVENTS = {
        MOVE_UPDATE: 'move_update',
        MOVE_COMPLETE: 'move_complete',
        PATH_READY: 'path_ready',
        PIN_HIT: 'pin_hit',
        ENTER_RAIL: 'enter_rail',
        EXIT_RAIL: 'exit_rail',
    }
    constructor(scene: Scene, {
        tileSize = {width: 64, height: 64},
        movementRandomness = 0.15,
    }: { tileSize?: { width: number, height: number }, movementRandomness?: number }
    ) {
        super();
        this._scene = scene;

        this._tileSize = tileSize;
        this._movementRandomness = movementRandomness;
        this._balance = new Balance(this);
    }

    changeMap(map: Array<Array<number>>) {
        this._map = map;

        this._availableTiles = this._map.reduce((acc: PlinkoPosition[], row, rowIndex) => {
            row.forEach((tile, tileIndex) => {
                if (tile === 0) {
                    acc.push({tileIndex, rowIndex});
                }
            });
            return acc;
        }, []);

        this._droppedItems = [];
        this._startPositions = [];
        this._finishPositions = [];
        this._outsideFinishPositions = [];
        this._bumpers = [];
        this._costFields = [];
        this._pinPositions = [];
        this._specialPinPositions = [];
        this._rails = [];
        this._tabs = [];

        this._initPathFinding();
    }

    public addFinishPositions(position: PlinkoPosition | PlinkoPosition[]) {
        if (Array.isArray(position)) {
            this._finishPositions.push(...position);
            return;
        }
        this._finishPositions.push(position);
    }

    public addStartPositions(position: PlinkoPosition | PlinkoPosition[]) {
        if (Array.isArray(position)) {
            this._startPositions.push(...position);
            return;
        }
        this._startPositions.push(position);
        this._startPositions.sort((a, b) => a.tileIndex - b.tileIndex);
        if (!this._topStartPosition) {
            this._topStartPosition = position;
        } else if (this._topStartPosition.rowIndex > position.rowIndex) {
            this._topStartPosition = position;
        }
    }

    public addLauncherPosition(position: PlinkoPosition) {
        this._launcherPosition = position;
    }

    public addPinPositions(position: PlinkoObjectPosition | PlinkoObjectPosition[]) {
        if (Array.isArray(position)) {
            this._pinPositions.push(...position);
            return;
        }
        this._pinPositions.push(position);
    }

    public addSpecialPinPositions(position: PlinkoObjectPosition | PlinkoObjectPosition[]) {
        if (Array.isArray(position)) {
            this._specialPinPositions.push(...position);
            return;
        }
        this._specialPinPositions.push(position);
    }

    public addOutsideFinishPositions(position: PlinkoPosition | PlinkoPosition[]) {
        if (Array.isArray(position)) {
            this._outsideFinishPositions.push(...position);
            return;
        }
        this._outsideFinishPositions.push(position);
    }

    public addBumpers(position: PlinkoObjectPosition | PlinkoObjectPosition[]) {
        if (Array.isArray(position)) {
            this._bumpers.push(...position);
            return;
        }
        this._bumpers.push(position);
    }

    public addRail(rail: {startTile:PlinkoPosition, endTile: PlinkoPosition, path: Phaser.Curves.Path, view: VisualObject}) {
        this._rails.push(rail);
    }

    addTabs(tabs: { tileIndex: number, rowIndex: number, view: VisualObject } | { tileIndex: number, rowIndex: number, view: VisualObject }[]) {
        if (Array.isArray(tabs)) {
            this._tabs.push(...tabs);
            return;
        }
        this._tabs.push(tabs);
    }

    private _random(ball: Ball) {
        const random = ball.getData('random');
        return random();
    }

    private _initPathFinding() {
        this._easyStar = new EasyStar.js();
        this._easyStar.setGrid(this._map);
        this._easyStar.setAcceptableTiles([
            BOARD_INDEX.movable,
            BOARD_INDEX.finish,
            BOARD_INDEX.fixedHigherCostTile,
            BOARD_INDEX.skippedTile,
            BOARD_INDEX.outsideFinish,
            BOARD_INDEX.specialFinishTile,
            BOARD_INDEX.bumper,
        ]);
        this._easyStar.enableDiagonals();// we want path to have diagonals
        this._easyStar.setDirectionCosts([
            [3, 3, 3],
            [3, 0, 3],
            [1, 1, 1],
        ])
        this._easyStar.setHeuristics(EasyStar.Heuristics.manhattan, EasyStar.Heuristics.octile);
    }

    public drop(ball: Ball, radius: number, startTileIndex: number, startRowIndex: number, launchPower: number = 0) {
        if (!this._finishPositions.length) {
            throw new Error('Finish positions are not set. Use addFinishPositions method to set them.');
        }

        if (!this._startPositions.length) {
            throw new Error('Start positions are not set. Use addStartPositions method to set them.');
        }

        this._droppedItems.push(ball);

        ball.setData('radius', radius);

        this._updateAutoPlayBalance();

        this._startPathFinding(startTileIndex, startRowIndex, ball, launchPower);
    }

    private async _pickGoal(launchPower: number, ball: Ball) {
        let response:TargetSlotResponse;
        if (this.balance.isBonusRound) {
            response = await api().launchBonusBall(this.balance.currentBonusRoundId, launchPower);
        } else {
            response = await api().launchNormalBall(launchPower);
        }

        this.balance.updateServerState(response.balance, false).pushBalanceChange(response.balanceChange, response.bonusBalanceChange);

        console.log('_pickGoal', response);

        ball.setData('balanceChange', response.balanceChange);
        ball.setData('bonusBalanceChange', response.bonusBalanceChange);
        ball.setData('random', seedrandom(response.randomSeed));
        ball.setData('wheelRound', response.wheelRound);
        ball.setData('isSpecialBall', response.isSpecialBall);
        ball.setData('tab', response.tab);
        ball.setData('bumperSide', response.bumperSide);
        ball.setData('railSide', response.railSide);

        if (response.miss) {
            ball.setData('isOutside', true);
            return this._outsideFinishPositions[response.miss === MissSide.left ? 0 : 1];
        } else if (response.targetSlot !== null) {
            ball.setData('isOutside', false);
            ball.setData('finishIndex', response.targetSlot);
            return this._finishPositions[response.targetSlot];
        }
        return this._finishPositions[Math.round(this._finishPositions.length * 0.5)]
    }

    private async _startPathFinding(startTileIndex: number, startRowIndex: number, ball: Ball, launchPower: number) {
        let finalPath: {x: number, y:number}[] = [];

        const finishPosition = await this._pickGoal(launchPower, ball);
        const itemIsSpecial = !!ball.getData('isSpecialBall');
        const isOutside = ball.getData('isOutside');
        const tab = ball.getData('tab');
        const bumperSide = ball.getData('bumperSide');
        const railSide = ball.getData('railSide');

        let specialPositions = this._getSpecialPinPositionSequence(ball, this._specialPinPositions.slice(), itemIsSpecial, startTileIndex);
        let bumpers = this._bumpers.slice();
        this._randomizeCosts(ball, itemIsSpecial, isOutside, specialPositions);

        const findPath = (tileIndex: number, rowIndex: number, finalPath) => {
            let pathEnd;
            if (itemIsSpecial && specialPositions.length) {
                pathEnd = specialPositions.shift();
            } else {
                pathEnd = finishPosition;
            }

            this._lastPathId = this._easyStar.findPath(tileIndex, rowIndex, pathEnd.tileIndex, pathEnd.rowIndex, (path: any) => {
                if (path === null) {
                    console.warn("Path was not found.");
                } else {
                    finalPath = finalPath.concat(path);
                    if (pathEnd === finishPosition) {
                        finalPath = this._filterOutSegments(finalPath);
                        if (bumpers.length && (bumperSide !== false || tab)) {
                            finalPath = this._updateBumperSequence(finalPath, bumpers[bumperSide], tab, railSide);
                            bumpers = [];
                        }
                        if (!itemIsSpecial) this._insertRandomJumps(ball, finalPath);
                        //finalPath = EasyStar.compressPath(finalPath, 2);
                        finalPath[0] = {x: this._launcherPosition.tileIndex, y: this._launcherPosition.rowIndex};
                        console.log('Bumper Debug', finalPath);
                        ball.setData('path', finalPath);
                        this.emit(PlinkoController.EVENTS.PATH_READY, ball, finalPath, this._costFields);
                    } else {
                        finalPath.pop();
                        findPath(pathEnd.tileIndex, pathEnd.rowIndex, finalPath);
                    }
                }
            });
            this._easyStar.calculate();
        }
        findPath(startTileIndex, startRowIndex, finalPath);
    }

    _filterOutSegments(path) {
        return path.filter((item, index) => {

            let keep = true;
            if (this._map[item.y][item.x] === 7) {
                keep = false;
            } else if (index > 0 && index < path.length - 1) {
                const prevItem = path[index - 1];
                const nextItem = path[index + 1];
                if (this._map[prevItem.y][prevItem.x] !== 7 && this._map[nextItem.y][nextItem.x] !== 7 && prevItem.y === nextItem.y) {
                    keep = false;
                }
            }
            return keep;
        });
    }

    _updateBumperSequence(finalPath: {x: number, y: number}[], bumper: PlinkoObjectPosition, tab: {id: number, value: string} | false, railSide: 0 | 1 | false) {
        let path = finalPath.slice();
        console.log('_updateBumperSequence railSide', railSide);
        if (bumper === undefined && tab) {
            bumper = this._bumpers[Math.round(Math.random())];
        }
        const bumperPosition = {x: bumper.tileIndex, y: bumper.rowIndex};
        const bumperIndex = 1;
        path.splice(bumperIndex, 0, bumperPosition);
        const prevPosition = path[0] || {x: this._launcherPosition.tileIndex, y: this._launcherPosition.rowIndex};
        const rad = Phaser.Math.Angle.Between(
            prevPosition.x * this._tileSize.width,
            prevPosition.y * this._tileSize.height,
            bumperPosition.x * this._tileSize.width,
            bumperPosition.y * this._tileSize.height
        );
        console.log('Bumper Debug rad', rad);

        if (tab !== false) {
            path = getTabTarget(path, this._tabs, bumperIndex, bumperPosition, this._availableTiles, this._finishPositions[0]);
        } else {
            if (bumperPosition.x < this._map[0].length * 0.5) {
                console.log('Bumper Debug rad', prevPosition.x, prevPosition.y, rad);
                // left side

                if (railSide !== false) {
                    console.log('_updateBumperSequence left side fly to rails');
                    path = getRailTarget(path, this._rails[railSide], this._availableTiles, 'left');
                } else {
                    const nextTarget = Math.random();
                    if (nextTarget < 0.6) {
                        console.log('_updateBumperSequence left side fly to pins');
                    } else {
                        console.log('_updateBumperSequence left side fly to other bumper');
                        const otherBumper = this._bumpers[1];
                        path.splice(bumperIndex + 1, 0, {x: otherBumper.tileIndex, y: otherBumper.rowIndex});
                    }
                }

            } else {
                // right side
                if (railSide !== false) {
                    console.log('_updateBumperSequence right side fly to rails');
                    path = getRailTarget(path, this._rails[railSide], this._availableTiles, 'right');
                } else {
                    console.log('_updateBumperSequence right side fly to pins');
                }
            }
        }

        return path;

        function getRailTarget (path: {x: number, y: number}[], rail: {startTile: PlinkoPosition, endTile: PlinkoPosition, path: Phaser.Curves.Path}, availableTiles: PlinkoPosition[], side: 'left' | 'right') {
            const firstRailPoint = {x: rail.startTile.tileIndex, y: rail.startTile.rowIndex};
            const lastRailPoint = {x: rail.endTile.tileIndex, y: rail.endTile.rowIndex};
            availableTiles = availableTiles
                .filter((tile) => tile.rowIndex >= lastRailPoint.y && tile.rowIndex <= lastRailPoint.y)
                .sort((a, b) => side === 'right' ?  a.tileIndex - b.tileIndex : b.tileIndex - a.tileIndex);
            const candidateTiles = availableTiles.slice(0, Math.ceil(availableTiles.length * 0.2));
            const firstNextTile = Phaser.Math.RND.pick(candidateTiles);
            path = path.filter((tile, index) => index <= bumperIndex || tile.y > lastRailPoint.y);
            path.splice(bumperIndex + 1, 0, firstRailPoint, lastRailPoint, {x: firstNextTile.tileIndex, y: firstNextTile.rowIndex});
            return path
        }

        function getTabTarget (path: {x: number, y: number}[], tabs: {tileIndex: number, rowIndex: number, view: VisualObject}[], bumperIndex: number, bumperPosition: {x: number, y: number}, availableTiles: PlinkoPosition[], finishPosition: PlinkoPosition) {
            const tab = tabs[0];
            path.splice(bumperIndex + 1, 0, {x: tab.tileIndex, y: tab.rowIndex});
            return path;
        }
    }

    _getSpecialPinPositionSequence(ball: Ball, specialPositions: any[], itemIsSpecial: boolean, startTileIndex: number) {
        if (specialPositions.length && !itemIsSpecial) {
            specialPositions = specialPositions.sort(() => (this._random(ball) > .5) ? 1 : -1).filter((position, index) => index < specialPositions.length - 1);
            return specialPositions;
        }
        return specialPositions;
    }

    /*private _validatePath(path: { x: number, y: number }[]) {
        let isValid = true;
       /!* for (let i = 0; i < path.length-1; i++){
            const ey = path[i+1].y;
            if (ey < path[i].y) {
                isValid = false;
                break;
            }
        }*!/
        for (let i = 0; i < path.length-1; i++){
            for (let j = 0; j < path.length-1; j++){
                if (path[i].x === path[j].x && path[i].y === path[j].y && i !== j) {
                    isValid = false;
                    break;
                }
            }
            if (!isValid) break;
        }
        //console.log('isValid', isValid);
        return isValid;
    }*/

    public moveItem (ball: Ball, path: { x: number, y: number }[], baseDuration: number = 300, acceleration: number = 0.96) {
        let duration = baseDuration;
        let enterDuration = baseDuration;
        let accelerationQ = acceleration;
        // Sets up a list of tweens, one for each tile to walk, that will be chained by the timeline
        const tweens: {targets: Phaser.GameObjects.GameObject[], x: number | object, y: number | object, onComplete: Function }[] = [];
        for (let i = 0; i < path.length-1; i++){
            const sx = path[i].x;
            const sy = path[i].y;
            const ex = path[i+1].x;
            const ey = path[i+1].y;

            duration = this._updateDuration(ball, duration, accelerationQ, i, ex, ey, path);
            const currentDuration = this._getDuration(enterDuration, duration, i, ex, ey, path);

            const offset = this._getOffsetXY(i, ex, ey, path, ball);
            const easeFunctions = this._selectEaseFunction(i, ex, ey, path);

            if (this._map[sy][sx] === BOARD_INDEX.railStart) {
                const railObj = {t: 0, vec: new Phaser.Math.Vector2()};
                const rail = sx < this._map[0].length * 0.5 ? this._rails[0] : this._rails[1];
                const arrowGlowTween = rail.view.getData('arrowGlowTween') as TweenChain;

                tweens.push(
                    {
                        // @ts-ignore
                        targets: [railObj],
                        duration: 2500,
                        t: 1,
                        ease: 'Linear',
                        onUpdate: () => {
                            rail.path.getPoint(railObj.t, railObj.vec);
                            ball.x = railObj.vec.x;
                            ball.y = railObj.vec.y;

                            const rings = rail.view.getData('ringGlows') as Phaser.GameObjects.Image[];
                            if (rings && rings.length > 0){
                                const ring = rings.find((ring) => {
                                    return Math.abs(ring.getData('t') - railObj.t) < 0.01 && ring.getData('glowTween') && !ring.getData('glowTween').isPlaying();
                                });
                                if (ring) {
                                    let glowTween = ring.getData('glowTween') as Tween;
                                    if (glowTween.isDestroyed()) {
                                        glowTween = createRingGlowTween(this._scene, ring);
                                    }
                                    glowTween.play();
                                }
                            }
                        },
                        onStart: () => {
                            if (arrowGlowTween) {
                                arrowGlowTween.play();
                            }
                            this.emit(PlinkoController.EVENTS.ENTER_RAIL, ball, rail);
                        },
                        onComplete: () => {
                            if (arrowGlowTween) {
                                arrowGlowTween.restart();
                                arrowGlowTween.pause();
                            }
                            this.emit(PlinkoController.EVENTS.EXIT_RAIL, ball, rail);
                        }
                    }
                );
            } else {
                tweens.push({
                        targets: [ball],
                        x: {value: ex * this._tileSize.width + offset.x, duration: currentDuration, ease: easeFunctions[0]},
                        y: {value: ey * this._tileSize.height + offset.y, duration:  currentDuration, ease: easeFunctions[1]},
                        onComplete: () => {
                            if (i < path.length - 2) {
                                const target: {tileIndex: number, rowIndex:number, view: VisualObject} | undefined =
                                    this._pinPositions.find((position) => position.tileIndex === ex && position.rowIndex === ey + 1) ||
                                    this._specialPinPositions.find((position) => position.tileIndex === ex && position.rowIndex === ey) ||
                                    this._bumpers.find((position) => position.tileIndex === ex && position.rowIndex === ey) ||
                                    this._tabs.find((position) => position.tileIndex === ex && position.rowIndex === ey);
                                this.emit(PlinkoController.EVENTS.PIN_HIT, {ball: ball, position: path[i+1], target: target && target.view});
                            }
                        }
                    }
                );
            }


        }

        // @ts-ignore
        this._scene.tweens.chain({
            tweens: tweens,
            onUpdate: () => {
                this.emit(PlinkoController.EVENTS.MOVE_UPDATE, this._droppedItems);
            },
            onComplete: async () => {
                this.emit(PlinkoController.EVENTS.MOVE_COMPLETE, ball);
            }
        });
    };

    public removeItem(ball: Ball) {
        const index = this._droppedItems.indexOf(ball);
        if (index > -1) {
            this._droppedItems.splice(index, 1);
        }
    }

    _insertRandomJumps(ball: Ball, path: {x: number, y:number}[]) {
        const randomJumps = Math.round(this._random(ball) * 2);
        for (let i = 0; i < randomJumps; i++) {
            const randomIndex = Phaser.Math.Between(3, path.length - 4);
            if (this._map[path[randomIndex].y][path[randomIndex].x] !== 0) continue;

            const randomJumpX = (this._random(ball) > 0.5 ? -1 : 1) * Math.round(this._random(ball) * 4) + 1;
            const randomJumpY = randomJumpX > 1 ?  Math.ceil(this._random(ball)) : Math.round(this._random(ball));
            const newX = path[randomIndex].x + randomJumpX;
            const newY = path[randomIndex].y - randomJumpY;
            if (this._map[newY][newX] === 0 && this._map[newY + 1][newX] === 1) {
                Phaser.Utils.Array.AddAt(path, {x: newX, y: newY}, randomIndex + 1);
                if (newX - path[randomIndex + 2].x > 2) {
                    path[randomIndex + 2].x += 2;
                } else if (newX - path[randomIndex + 2].x < -2) {
                    path[randomIndex + 2].x -= 2;
                }
            }
        }
    }

    private _getOffsetXY (i: number, ex: number, ey:number, path: { x: number, y: number }[], ball: Ball) {
        let offsetX = 0;
        let offsetY = 0;
        const radius = ball.getData('radius');

        let bounceObject = this._pinPositions.find((pin) => pin.tileIndex === ex && pin.rowIndex === ey + 1);

        if (this._map[ey][ex] === BOARD_INDEX.rail || this._map[ey][ex] === BOARD_INDEX.railStart || this._map[ey][ex] === BOARD_INDEX.railEnd) {
            offsetX = 0;
            offsetY = 0;
        } else if (this._map[ey][ex] === BOARD_INDEX.tabs) {
            const tabsData = this._tabs.find((position) => position.tileIndex === ex && position.rowIndex === ey);
            if (tabsData) {
                const tabs = tabsData.view as Tabs;
                const tabToHit = tabs.getTabToHit(ball.getData('tab'));
                offsetX = tabToHit ? tabToHit.x : 0;
                offsetY = tabToHit ? tabToHit.y : 0;
            } else {
                offsetX = 0;
                offsetY = 0;
            }
        } else if (this._map[ey][ex] === BOARD_INDEX.bumper) {
            const bumper = this._bumpers.find((bumper) => bumper.tileIndex === ex && bumper.rowIndex === ey);
            if (bumper) {
                const rad = Phaser.Math.Angle.Between(path[i].x * this._tileSize.width, path[i].y * this._tileSize.height , ex * this._tileSize.width, ey * this._tileSize.height) + Math.PI;
                const circle = new Phaser.Geom.Circle(0, 0, bumper.radius);
                const offsetPoint = Phaser.Geom.Circle.CircumferencePoint(circle, rad);
                offsetX = offsetPoint.x;
                offsetY = offsetPoint.y;
            }
        } else if (i < path.length - 2) {
            if (ex > path[i + 2].x) {
                    offsetX = -radius * 0.5 - (bounceObject ? bounceObject.radius * 0.8 : 0);
                } else {
                    offsetX = radius * 0.5 + (bounceObject ? bounceObject.radius * 0.8 : 0);
                }

            if (i > 0 && ey < path[i].y && ey < path[i + 2].y) {
                offsetY = this._tileSize.height + radius * 0.5 + (bounceObject ? bounceObject.radius * 1.5 : 0);
            } else {
                if (ex > path[i + 2].x) {
                    offsetX = -radius * 0.5 - (bounceObject ? bounceObject.radius * 0.8 : 0);
                } else {
                    offsetX = radius * 0.5 + (bounceObject ? bounceObject.radius * 0.8 : 0);
                }
                offsetY = this._tileSize.height - radius * 0.5 - (bounceObject ? bounceObject.radius * 0.8 : 0);
            }
        }
        offsetX = offsetX - this._random(ball) * offsetX * 0.1;
        return {x: offsetX, y: offsetY};
    }

    private _getDuration(enterDuration, duration, i: number, ex: number, ey:number, path: { x: number, y: number }[]) {
        let diff = 1;
        if (Math.abs(ex - path[i].x) > 1 || Math.abs(ey - path[i].y) > 1) {
            diff = Math.sqrt(Math.pow(ex - path[i].x, 2) + Math.pow(ey - path[i].y, 2)) * 0.4;
        }

        if (this._map[ey][ex] === BOARD_INDEX.bumper) {
            duration = duration * 0.6;
        } else if (this._map[ey][ex] === BOARD_INDEX.rail || this._map[ey][ex] === BOARD_INDEX.railStart || this._map[ey][ex] === BOARD_INDEX.railEnd) {
            duration = duration * 0.5;
        } else if (this._map[path[i].y][path[i].x] === BOARD_INDEX.bumper) {
            duration = duration * 0.4;
        }

        return (i === 0 || (i === 1 && path[i].x === ex)) ? enterDuration : (duration * Math.sqrt(diff * 0.9))
    }

    private _updateDuration(ball: Ball, duration, accelerationQ, i: number, ex: number, ey:number, path: { x: number, y: number }[]) {
        const acceleration = accelerationQ + (this._random(ball) * accelerationQ * 0.1 - accelerationQ * 0.07);
        return duration * acceleration;
    }

    private _selectEaseFunction(i: number, ex: number, ey:number, path: { x: number, y: number }[]) {
        const fromTile = this._map[path[i].y][path[i].x];
        const toTile = this._map[ey][ex];
        if (i === 0) {
            return ['Linear', 'Linear'];
        } else if (fromTile === BOARD_INDEX.bumper || toTile === BOARD_INDEX.bumper) {
            if (ey >= path[i].y) {
                return ['Linear', 'Back.easeIn'];
            } else {
                return ['Linear', 'Linear'];
            }
        } else if (fromTile === BOARD_INDEX.tabs) {
            return ['Linear', 'Linear'];
        } else if (fromTile === BOARD_INDEX.rail || toTile === BOARD_INDEX.rail || fromTile === BOARD_INDEX.railStart || toTile === BOARD_INDEX.railStart || fromTile === BOARD_INDEX.railEnd || toTile === BOARD_INDEX.railEnd) {
            return ['Linear', 'Linear'];
        } else if (i > 1 && ey < path[i].y) {
            return ['Linear', 'Quad.easeOut'];
        } else {
            /*return function (v, overshoot) {
                if (overshoot === undefined) { overshoot = Phaser.Math.FloatBetween(1.5, 2); }
                return v * v * ((overshoot + 1) * v - overshoot);
            }*/
            return ['Linear', 'Back.easeIn'];
        }
    }

    private _randomizeCosts(ball: Ball, itemIsSpecial: boolean, isOutside: boolean, specialPositions: PlinkoPosition[]) {
        this._costFields = [];
        this._map.forEach((row: any, rowIndex: number) => {
            row.forEach((tile: any, tileIndex: number) => {
                if (rowIndex > this._topStartPosition.rowIndex + 2 &&
                    rowIndex < this._map.length - Math.round(this._map.length * 0.3) &&
                    this._map[rowIndex - 1][tileIndex] !== BOARD_INDEX.fixedHigherCostTile &&
                    this._map[rowIndex + 1][tileIndex] === BOARD_INDEX.pin &&
                    tile === 0 && this._random(ball) > Phaser.Math.Clamp(1 - this._movementRandomness, 0, 1)) {
                    this._easyStar.setAdditionalPointCost(tileIndex, rowIndex, 3);
                    this._costFields.push({tileIndex, rowIndex});
                } else if (this._map[rowIndex][tileIndex] === BOARD_INDEX.fixedHigherCostTile) {
                    this._easyStar.setAdditionalPointCost(tileIndex, rowIndex, 3);
                    this._costFields.push({tileIndex, rowIndex});
                } else if (this._map[rowIndex][tileIndex] === BOARD_INDEX.skippedTile) {
                    this._easyStar.setAdditionalPointCost(tileIndex, rowIndex, 2);
                    this._costFields.push({tileIndex, rowIndex});
                } else if (rowIndex === this._map.length - 3) {
                    this._easyStar.setAdditionalPointCost(tileIndex, rowIndex, 5);
                    this._costFields.push({tileIndex, rowIndex});
                } else if (tile === 0) {
                    this._easyStar.setAdditionalPointCost(tileIndex, rowIndex, 1);
                }
            });
        });

        if (!itemIsSpecial) {
            specialPositions.forEach(({tileIndex, rowIndex}) => {
                this._easyStar.setAdditionalPointCost(tileIndex, rowIndex, 5);
                this._costFields.push({tileIndex, rowIndex});
            });
        }
    }

    _updateAutoPlayBalance() {
        if (this._scene.registry.get('autoplay')) {
            let autoplayBalance = this._scene.registry.get('autoplayBalance') - 1;
            this._scene.registry.set('autoplayBalance', autoplayBalance);
        }
    }

    destroy() {
        this._easyStar.cancelPath(this._lastPathId);
        this.shutdown();
    }
}
