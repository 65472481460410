import {Skin, SkinItem} from "../../types/skin";
import {Scene} from "phaser";

export function createBumper (scene: Scene, x: number, y: number, side: 'left' | 'right', skin:Skin) {
    const bumperUnderGlowSkin = (side === 'left' ? skin.bumperLeftUnderGlow : skin.bumperRightUnderGlow) as SkinItem;
    const bumperUnderGlow = scene.add.image(x + bumperUnderGlowSkin.position.x, y + bumperUnderGlowSkin.position.y, bumperUnderGlowSkin.key);
    bumperUnderGlow.setOrigin(bumperUnderGlowSkin.origin.x, bumperUnderGlowSkin.origin.y);

    const bumperShadowSkin = (side === 'left' ? skin.bumperLeftShadow : skin.bumperRightShadow) as SkinItem;
    const bumperShadow = scene.add.image(x + bumperShadowSkin.position.x, y + bumperShadowSkin.position.y, bumperShadowSkin.key);
    bumperShadow.setOrigin(bumperShadowSkin.origin.x, bumperShadowSkin.origin.y);

    const bumperSkin = (side === 'left' ? skin.bumperLeft : skin.bumperRight) as SkinItem;
    const bumper = scene.add.image(x + bumperSkin.position.x, y + bumperSkin.position.y, bumperSkin.key);
    bumper.setOrigin(bumperSkin.origin.x, bumperSkin.origin.y);

    const bumperGlowSkin = (side === 'left' ? skin.bumperLeftGlow : skin.bumperRightGlow) as SkinItem;
    const bumperGlow = scene.add.image(bumper.x + bumperGlowSkin.position.x, bumper.y + bumperGlowSkin.position.y, bumperGlowSkin.key);
    bumperGlow.setOrigin(bumperGlowSkin.origin.x, bumperGlowSkin.origin.y);
    bumperGlow.alpha = 0;
    const radius = bumper.displayWidth * 0.4;

    bumper.setData('isBumper', true);
    bumper.setData('glow', bumperGlow);

    return {bumper, bumperUnderGlow, bumperShadow, bumperGlow, radius};
}
