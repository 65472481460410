export const DEBUG = false;
export const BASE_MOVEMENT_DURATION = 300;
export const TILE_SIZE_W = 24;
export const TILE_SIZE_H = 53;

export const BOARD_INDEX = {
    movable: 0,
    pin: 1,
    dropStart: 2,

    finish: 3,
    outsideFinish: 31,
    specialFinishTile: 32,

    specialPin: 4,

    wall: 5,
    fixedHigherCostTile: 6,
    skippedTile: 7,

    rail: 8,
    railStart: 81,
    railEnd: 82,

    tabs: 9,

    bumper: 10,
    launcher: 11,
    progressBar: 12,
}

export const BONUS_WIN_TYPES = [
    {
        range: [0, 20],
        type: 'total_win',
    },
    {
        range: [21, 100],
        type: 'big_win',
    },
    {
        range: [101, 1000],
        type: 'super_win',
    },
    {
        range: [1001, 49999],
        type: 'mega_win',
    },
    {
        range: [50000, 100000],
        type: 'ultimate_win',
    },
]
