import currentMap from "../data/rounds/defaultMap";
import {SPECIAL_TYPES} from "../types/api/responseTypes";

console.log('Warning: Mock api bundled in');

let initializeToolbar = true;
let totalBalls = 0;

const bonusRoundTypes = Object.keys(SPECIAL_TYPES);

export const delay = (timeout: number = 100) => new Promise(resolve => setTimeout(resolve, timeout));

export const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams: URLSearchParams, prop: string) => searchParams.get(prop),
  set: (searchParams: URLSearchParams, prop: string | symbol, value: any, receiver: any): boolean => {
    searchParams.set(prop as string, value);
    const newUrl = window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newUrl);
    return true;
  },
});

export const getRandomSeed = (): string => {
    const seed = params['seed'] || Date.now();
    const e = document.getElementById('randomSeed')!;
    e.innerText = seed;
    document.getElementById('randomSeedLocked')!.style.visibility = e.innerText === params['seed'] ? 'visible' : 'hidden';
    return seed;
};

export const initToolbar = () => {
    if (!initializeToolbar) return;
    initializeToolbar = false;
    var toolbar = document.createElement('div');
    toolbar.className = 'tooltip';
    toolbar.id = 'toolbar';
    toolbar.style.cssText = 'background: rgba(255,255,255,0.6); position: fixed; z-index: 999; min-height: 200px; width: 300px; top:0; left: 0;';
    toolbar.innerHTML = `
    <a id="closeDebug" style="position: static; margin: 5px; display: block; color: black; text-decoration: none;" onclick="parentNode.style.display='none';" href="#">X</a>
    <form id="toolbarForm">
        <table style="border: 0; width: 100%;">
            <tr><th>Hit wheel</th><td><select id="hitWheel"><option value="">Random</option><option value="false">No</option><option value="true">Yes</option></select></td></tr>
            <tr><th>Bumper</th><td><select id="bumper"><option value="">Random</option><option value="false">No</option><option value="0">Left</option><option value="1">Right</option></select></select></td></tr>
            <tr><th>Rail</th><td><select id="rail"><option value="">Random</option><option value="false">No</option><option value="0">Left</option><option value="1">Right</option></select></td></tr>
            <tr><th>Target slot</th><td><select id="targetSlot"><option value="">Random</option>${currentMap.balances.map((v, i) => '<option value="' + i + '">' + (v === -Infinity ? 'star' : String(v)) + '</option>')}</select></td></tr>
            <tr><th>Bonus round</th><td><select id="bonusRound"><option value="">Random</option><option value="false">No</option>${bonusRoundTypes.map((v) => '<option value="' + v + '">' + v + '</option>')}</select></td></tr>
            <tr><th>Random seed</th><td><a id="randomSeed"></a><span id="randomSeedLocked" style="margin-left: 5px; color: red; visibility: hidden;">Locked</span></td></tr>
            <tr><th>Balls launched</th><td id="totalBalls">0</td></tr>
        </table>
    </form>`;

    document.body.appendChild(toolbar);
    getRandomSeed();
    document.getElementById("randomSeed")?.addEventListener("click", () => {
        const e = document.getElementById('randomSeed')!;
        params['seed'] = e.innerText;
        document.getElementById('randomSeedLocked')!.style.visibility = e.innerText === params['seed'] ? 'visible' : 'hidden';
    });
    (document.querySelectorAll("#toolbarForm select") as NodeListOf<HTMLSelectElement>).forEach(select => {
        select.addEventListener("change", () => {
            params[select.id] = select.value;
        });
    });

    initInputValue('hitWheel');
    initInputValue('bumper');
    initInputValue('rail');
    initInputValue('targetSlot');
    initInputValue('bonusRound');
};

export const initInputValue = (id: string) => {
    const value = params[id];
    const e = document.getElementById(id)! as HTMLSelectElement;
    for (var i = 0; i < e.options.length; i++) {
        if (e.options[i].value == value) {
            e.options[i].selected = true;
        }
    }
}

export const inputValue = (id: string, defaultValue: any, falseValue: any = null): any => {
    const e = document.getElementById(id)! as HTMLSelectElement;
    if (e.value && e.value !== '') {
        if (falseValue !== null && e.value === 'false') {
            return falseValue;
        }
        return e.value;
    }
    return defaultValue;
};

export const launchBall = () => {
    totalBalls++;
    const e = document.getElementById('totalBalls')!;
    e.innerText = String(totalBalls);
}
