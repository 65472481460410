import {Skin} from "../types/skin";

export default <Skin> {
    backgroundLayer1: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'background_layer_1',
        path: 'assets/img/rounds/default/background_layer_1.png',
    },
    backgroundLayer2: {
        type: 'image',
        position: {x: 13, y: -125},
        origin: {x: 0.5, y: 0.5},
        key: 'background_layer_2',
        path: 'assets/img/rounds/default/background_layer_2.png',
    },
    pinsBackground: {
        type: 'image',
        position: {x: 5, y: 281},
        origin: {x: 0.5, y: 0.5},
        key: 'pins_background',
        path: 'assets/img/rounds/default/pins_background.png',
    },
    backgroundLayer4: {
        type: 'image',
        position: {x: 0, y: -149},
        origin: {x: 0.5, y: 0.5},
        key: 'background_layer_4',
        path: 'assets/img/rounds/default/background_layer_4.png',
    },
    backgroundParticles: {
        type: 'atlas',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bg_particles',
        path: 'assets/img/rounds/default/bg_particles.png',
        json: {key: '', path: 'assets/img/rounds/default/bg_particles.json'},
        data: {
            frames: ['particle1.png', 'particle2.png', 'particle3.png'],
        }
    },
    boardLights: [
        {
            type: 'image',
            position: {x: -696, y: -78},
            origin: {x: 0.5, y: 0.5},
            key: 'board_glow_left_2',
            path: 'assets/img/rounds/default/board_glow_l_2.png',
        }, {
            type: 'image',
            position: {x: -616, y: -26},
            origin: {x: 0.5, y: 0.5},
            key: 'board_glow_left_1',
            path: 'assets/img/rounds/default/board_glow_l_1.png',
        }, {
            type: 'image',
            position: {x: 608, y: -26},
            origin: {x: 0.5, y: 0.5},
            key: 'board_glow_right_1',
            path: 'assets/img/rounds/default/board_glow_r_1.png',
        }, {
            type: 'image',
            position: {x: 696, y: -60},
            origin: {x: 0.5, y: 0.5},
            key: 'board_glow_right_2',
            path: 'assets/img/rounds/default/board_glow_r_2.png',
        }
    ],
    boardGlow: {
        type: 'image',
        position: {x: 0, y: 47},
        origin: {x: 0.5, y: 0.5},
        key: 'board_glow',
        path: 'assets/img/rounds/default/board_glow.png',
        data: {
            glowColor: 0xF2F57E,
        }
    },
    gameArea: {
        type: 'container',
        position: {x: -323, y: -436},
        origin: {x: 0.5, y: 0.5},
        key:'',
        path: '',
    },
    ball: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'ball',
        path: 'assets/img/rounds/default/ball.png',
        data: {
            trail: {
                color: 0xffffff,
                glow: 0xff4444,
            },
        }
    },
    pin: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'pin',
        path: 'assets/img/rounds/default/pin.png',
    },
    pinShadow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.2},
        key: 'pin_shadow',
        path: 'assets/img/rounds/default/pin_shadow.png',
    },
    specialPin: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'special_pin',
        path: 'assets/img/special_pin.png',
    },
    specialPinGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'special_pin_glow',
        path: 'assets/img/special_pin_glow.png',
    },
    launcherTop: {
        type: 'image',
        position: {x: 0, y: 10},
        origin: {x: 0.5, y: 0},
        key: 'launcher_top',
        path: 'assets/img/rounds/default/launcher_top.png',
    },
    launcherTopGlow: {
        type: 'image',
        position: {x: 0, y: 10},
        origin: {x: 0.5, y: 0},
        key: 'launcher_top_glow',
        path: 'assets/img/rounds/default/launcher_top_glow.png',
    },
    launcherBottom: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0},
        key: 'launcher_bottom',
        path: 'assets/img/rounds/default/launcher_bottom.png',
    },
    bumperLeft: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bumper_left',
        path: 'assets/img/rounds/default/bumper_l.png',
    },
    bumperLeftGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.49, y: 0.54},
        key: 'bumper_left_glow',
        path: 'assets/img/rounds/default/bumper_l_glow.png',
    },
    bumperLeftUnderGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.3},
        key: 'bumper_left_under_glow',
        path: 'assets/img/rounds/default/bumper_l_under_glow.png',
    },
    bumperLeftShadow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.45, y: 0.2},
        key: 'bumper_left_shadow',
        path: 'assets/img/rounds/default/bumper_l_shadow.png',
    },
    bumperRight: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bumper_right',
        path: 'assets/img/rounds/default/bumper_r.png',
    },
    bumperRightGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.54},
        key: 'bumper_right_glow',
        path: 'assets/img/rounds/default/bumper_r_glow.png',
    },
    bumperRightUnderGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.3},
        key: 'bumper_right_under_glow',
        path: 'assets/img/rounds/default/bumper_r_under_glow.png',
    },
    bumperRightShadow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.55, y: 0.2},
        key: 'bumper_right_shadow',
        path: 'assets/img/rounds/default/bumper_r_shadow.png',
    },
    winFieldsBackground: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0},
        key: 'win_fields_bg',
        path: 'assets/img/rounds/default/win_fields_bg.png',
    },
    winFieldGlows: [
        {
            type: 'image',
            position: {x: -500, y: 28},
            origin: {x: 0.5, y: 0},
            key: '500_left_glow',
            path: 'assets/img/rounds/default/500_left.png',
            data: {
                endPosition: { x: -15, y:1772 },
                color: 'blue',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -405, y: 35}, rotationZ: 0.13, angle: 35
                },
            }
        },
        {
            type: 'image',
            position: {x: -452, y: 28},
            origin: {x: 0.5, y: 0},
            key: '100_left_glow',
            path: 'assets/img/rounds/default/100_left.png',
            data: {
                endPosition: { x: 58, y:1772 },
                color: 'blue',
                text: {
                    color: '#ffffff',
                    fontSize: 50,
                    fontFamily: 'passionone_bold',
                    position: {x: -350, y: 35}, rotationZ: 0.12, angle: 32
                },
            }
        },
        {
            type: 'image',
            position: {x: -388, y: 28},
            origin: {x: 0.5, y: 0},
            key: '50_left_glow',
            path: 'assets/img/rounds/default/50_left.png',
            data: {
                endPosition: { x: 142, y:1772 },
                color: 'blue',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -301, y: 35}, rotationZ: 0.12, angle: 28
                },
            }
        },
        {
            type: 'image',
            position: {x: -322, y: 28},
            origin: {x: 0.5, y: 0},
            key: '10_left_glow',
            path: 'assets/img/rounds/default/10_left.png',
            data: {
                endPosition: { x: 224, y:1772 },
                color: 'green',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -251, y: 35}, rotationZ: 0.08, angle: 22
                },
            }
        },
        {
            type: 'image',
            position: {x: -257, y: 28},
            origin: {x: 0.5, y: 0},
            key: '5_left_glow',
            path: 'assets/img/rounds/default/5_left.png',
            data: {
                endPosition: { x: 306, y:1772 },
                color: 'green',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -201, y: 35}, rotationZ: 0.06, angle: 18
                },
            }
        },
        {
            type: 'image',
            position: {x: -190, y: 28},
            origin: {x: 0.5, y: 0},
            key: '3_left_glow',
            path: 'assets/img/rounds/default/3_left.png',
            data: {
                endPosition: { x: 388, y:1772 },
                color: 'green',
                text:  {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -149, y: 35}, rotationZ: 0.05, angle: 13
                },
            }
        },
        {
            type: 'image',
            position: {x: -124, y: 28},
            origin: {x: 0.5, y: 0},
            key: '0_left_2_glow',
            path: 'assets/img/rounds/default/0_left_2.png',
            data: {
                endPosition: { x: 468, y:1772 },
                color: 'yellow',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -103, y: 35}, rotationZ: 0.03, angle: 6
                },
            }
        },
        {
            type: 'image',
            position: {x: -59, y: 28},
            origin: {x: 0.5, y: 0},
            key: '0_left_1_glow',
            path: 'assets/img/rounds/default/0_left_1.png',
            data: {
                endPosition: { x: 542, y:1772 },
                color: 'yellow',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position:  {x: -53, y: 35}, rotationZ: 0.01, angle: 2
                },
            }
        },
        {
            type: 'image',
            position: {x: 0, y: 28},
            origin: {x: 0.5, y: 0},
            key: 'star_light_glow',
            path: 'assets/img/rounds/default/star_light_glow.png',
            data: {
                endPosition: { x: 600, y:1772 },
                color: 'red',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: -1, y: 53}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 55, y: 28},
            origin: {x: 0.5, y: 0},
            key: '0_right_1_glow',
            path: 'assets/img/rounds/default/0_right_1.png',
            data: {
                endPosition: { x: 653, y:1772 },
                color: 'yellow',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 50, y: 35}, rotationZ: -0.01, angle: -2
                },
            }
        },
        {
            type: 'image',
            position: {x: 120, y: 28},
            origin: {x: 0.5, y: 0},
            key: '0_right_2_glow',
            path: 'assets/img/rounds/default/0_right_2.png',
            data: {
                endPosition: { x: 729, y:1772 },
                color: 'yellow',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 98, y: 35}, rotationZ: -0.03, angle: -6
                },
            }
        },
        {
            type: 'image',
            position: {x: 186, y: 28},
            origin: {x: 0.5, y: 0},
            key: '3_right_glow',
            path: 'assets/img/rounds/default/3_right.png',
            data: {
                endPosition: { x: 808, y:1772 },
                color: 'green',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 147, y: 35}, rotationZ: -0.05, angle: -13
                },
            }
        },
        {
            type: 'image',
            position: {x: 252, y: 28},
            origin: {x: 0.5, y: 0},
            key: '5_right_glow',
            path: 'assets/img/rounds/default/5_right.png',
            data: {
                endPosition: { x: 889, y:1772 },
                color: 'green',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 198, y: 35}, rotationZ: -0.06, angle: -18
                },
            }
        },
        {
            type: 'image',
            position: {x: 319, y: 28},
            origin: {x: 0.5, y: 0},
            key: '10_right_glow',
            path: 'assets/img/rounds/default/10_right.png',
            data: {
                endPosition: { x: 971, y:1772 },
                color: 'green',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 252, y: 35}, rotationZ: -0.08, angle: -22
                },
            }
        },
        {
            type: 'image',
            position: {x: 388, y: 28},
            origin: {x: 0.5, y: 0},
            key: '50_right_glow',
            path: 'assets/img/rounds/default/50_right.png',
            data: {
                endPosition: { x: 1056, y:1772 },
                color: 'blue',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 301, y: 35}, rotationZ: -0.12, angle: -28
                },
            }
        },
        {
            type: 'image',
            position: {x: 453, y: 28},
            origin: {x: 0.5, y: 0},
            key: '100_right_glow',
            path: 'assets/img/rounds/default/100_right.png',
            data: {
                endPosition: { x: 1135, y:1772 },
                color: 'blue',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 353, y: 35}, rotationZ: -0.12, angle: -32
                },
            }
        },
        {
            type: 'image',
            position: {x: 500, y: 28},
            origin: {x: 0.5, y: 0},
            key: '500_right_glow',
            path: 'assets/img/rounds/default/500_right.png',
            data: {
                endPosition: { x: 1213, y:1772 },
                color: 'blue',
                text: {
                    fontFamily: 'passionone_bold',
                    color: '#ffffff',
                    fontSize: 50,
                    position: {x: 407, y: 35}, rotationZ: -0.13, angle: -35
                },
            }
        },
    ],
    star: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'special_pin',
        path: 'assets/img/special_pin.png',
    },
    starLight: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'star_light',
        path: 'assets/img/special_pin_glow_red.png',
    },
    pipeLeft: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.93, y: 0.12},
        key: 'pipe_left',
        path: 'assets/img/rounds/default/pipe_l.png',
        data: {
            path: [
                {x: 0.02, y: 0},
                {x: -0.45, y: -0.08},
                {x: -0.78, y: 0.07},
                {x: -0.22, y: 0.35},
                {x: -0.79, y: 0.68},
                {x: -0.61, y: 0.86},
            ]
        }
    },
    pipeLeftTop: {
        type: 'image',
        position: {x: -345, y: 23},
        origin: {x: 0, y: 0},
        key: 'pipe_left_top',
        path: 'assets/img/rounds/default/pipe_l_top.png',
    },
    pipeRight: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0, y: 0.05},
        key: 'pipe_right',
        path: 'assets/img/rounds/default/pipe_r.png',
        data: {
            path: [
                {x: 0, y: 0},
                {x: 0.5, y: 0.02},
                {x: 0.78, y: 0.18},
                {x: 0.35, y: 0.47},
                {x: 0.8, y: 0.75},
                {x: 0.68, y: 0.91},
            ]
        }
    },
    pipeRightTop: {
        type: 'image',
        position: {x: 38, y: -44},
        origin: {x: 0, y: 0},
        key: 'pipe_right_top',
        path: 'assets/img/rounds/default/pipe_r_top.png',
    },
    pipeRingGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'pipe_ring_glow',
        path: 'assets/img/rounds/default/pipe_ring_glow.png',
        data: {
            leftPipeRings: [
                {t: 0.340, position: {x: 0, y: 0}, angle: 0},
                {t: 0.433, position: {x: 0, y: 0}, angle: 0.05},
                {t: 0.526, position: {x: 0, y: 0}, angle: 0.1},
                {t: 0.623, position: {x: 10, y: 0}, angle: -0.},
                {t: 0.705, position: {x: 0, y: 0}, angle: -0.1},
                {t: 0.789, position: {x: 0, y: 0}, angle: -0.1},
                {t: 0.883, position: {x: -5, y: 0}, angle: -0.1},
            ],
            rightPipeRings: [
                {t: 0.116, position: {x: 0, y: 10}, angle: 3.02},
                {t: 0.246, position: {x: -12, y: 0}, angle: -0.1},
                {t: 0.400, position: {x: 10, y: 0}, angle: -0.09},
                {t: 0.510, position: {x: 5, y: 0}, angle: -0.05},
                {t: 0.630, position: {x: 0, y: 0}, angle: 0.05},
                {t: 0.768, position: {x: -5, y: 0}, angle: 0.1},
                {t: 0.889, position: {x: 2, y: 0}, angle: 0.35},
            ],
        }
    },
    arrowLeft: {
        type: 'image',
        position: {x: 66, y: 40},
        origin: {x: 0.5, y: 0.5},
        key: 'arrow_left',
        path: 'assets/img/rounds/default/arrow_l.png',
    },
    arrowLeftGlow1: {
        type: 'image',
        position: {x: 14, y: 24},
        origin: {x: 0.5, y: 0.5},
        key: 'arrow_left_glow_1',
        path: 'assets/img/rounds/default/arrow_l_glow_1.png',
    },
    arrowLeftGlow2: {
        type: 'image',
        position: {x: -35, y: -28},
        origin: {x: 0.5, y: 0.5},
        key: 'arrow_left_glow_2',
        path: 'assets/img/rounds/default/arrow_l_glow_2.png',
    },
    arrowRight: {
        type: 'image',
        position: {x: -75, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'arrow_right',
        path: 'assets/img/rounds/default/arrow_r.png',
    },
    arrowRightGlow1: {
        type: 'image',
        position: {x: -40, y: -2},
        origin: {x: 0.5, y: 0.5},
        key: 'arrow_right_glow_1',
        path: 'assets/img/rounds/default/arrow_r_glow_1.png',
    },
    arrowRightGlow2: {
        type: 'image',
        position: {x: 25, y: -2},
        origin: {x: 0.5, y: 0.5},
        key: 'arrow_right_glow_2',
        path: 'assets/img/rounds/default/arrow_r_glow_2.png',
    },
    wheel: {
        type: 'spine',
        position: {x: -245, y: 425},
        origin: {x: 0.5, y: 0.5},
        key: 'wheel',
        atlas: {key: 'wheel_atlas', path: 'assets/img/rounds/default/LINKO-Game-BG.atlas'},
        json: {key: 'wheel_data', path: 'assets/img/rounds/default/LINKO-Game-BG.json'},
    },
    wheelArrows: {
        type: 'image',
        position: {x: -60, y: 72},
        origin: {x: 0.5, y: 0.5},
        key: 'wheel_arrows',
        path: 'assets/img/rounds/default/wheel_arrows.png',
    },
    wheelArrowGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'wheel_arrow_glow',
        path: 'assets/img/rounds/default/wheel_arrow_glow.png',
        data: {
            positions: [
                {x: -121, y: 8},
                {x: -91, y: 37},
                {x: -59, y: 67},
                {x: -26, y: 98},
                {x: 7, y: 131},
            ]
        }
    },
    wheelTabs: [
        {
            type: 'image',
            position: {x: -87, y: -17},
            origin: {x: 0.5, y: 0.8},
            key: 'tab_1',
            path: 'assets/img/rounds/default/tab_1.png',
        },
        {
            type: 'image',
            position: {x: -51, y: 11},
            origin: {x: 0.5, y: 0.8},
            key: 'tab_2',
            path: 'assets/img/rounds/default/tab_2.png',
        },
        {
            type: 'image',
            position: {x: -15, y: 43},
            origin: {x: 0.5, y: 0.8},
            key: 'tab_3',
            path: 'assets/img/rounds/default/tab_3.png',
        },
        {
            type: 'image',
            position: {x: 20, y: 75},
            origin: {x: 0.5, y: 0.8},
            key: 'tab_4',
            path: 'assets/img/rounds/default/tab_4.png',
        },
        {
            type: 'image',
            position: {x: 57, y: 110},
            origin: {x: 0.5, y: 0.8},
            key: 'tab_5',
            path: 'assets/img/rounds/default/tab_5.png',
        },
    ],
    wheelPressedTabs: [
        {
            type: 'image',
            position: {x: -87, y: -17},
            origin: {x: 0.8, y: 0.65},
            key: 'tab_1_pressed',
            path: 'assets/img/rounds/default/tab_1_pressed.png',
        },
        {
            type: 'image',
            position: {x: -51, y: 11},
            origin: {x: 0.8, y: 0.65},
            key: 'tab_2_pressed',
            path: 'assets/img/rounds/default/tab_2_pressed.png',
        },
        {
            type: 'image',
            position: {x: -15, y: 43},
            origin: {x: 0.8, y: 0.65},
            key: 'tab_3_pressed',
            path: 'assets/img/rounds/default/tab_3_pressed.png',
        },
        {
            type: 'image',
            position: {x: 20, y: 75},
            origin: {x: 0.8, y: 0.65},
            key: 'tab_4_pressed',
            path: 'assets/img/rounds/default/tab_4_pressed.png',
        },
        {
            type: 'image',
            position: {x: 57, y: 110},
            origin: {x: 0.8, y: 0.65},
            key: 'tab_5_pressed',
            path: 'assets/img/rounds/default/tab_5_pressed.png',
        },
    ],
}
