import {Skin} from "../types/skin";

const HIGHLIGHT_COLORS = {
    default:[0xF39BEF, 0xF39BEF],
    x0: [0xFF5224, 0x2ACCFF],
    x2: [0xFF7E18, 0x2ACCFF],
    x5: [0xFFCD00, 0x2ACCFF],
    x10: [0x00FF00, 0x2ACCFF],
    x50: [0x00D4FF, 0x2ACCFF],
    x100: [0x009CFF, 0x2ACCFF],
}

export default <Skin> {
    backgroundLayer1: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_background_layer_1',
        path: 'assets/img/rounds/bonus/background_layer_1.png',
        data: {
            highlights: HIGHLIGHT_COLORS
        }
    },
    backgroundLayer2: {
        type: 'image',
        position: {x: 0, y: -380},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_background_layer_2',
        path: 'assets/img/rounds/bonus/background_layer_2.png',
    },
    pinsBackground: {
        type: 'image',
        position: {x: 5, y: 63},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_pins_background',
        path: 'assets/img/rounds/bonus/pins_background.png',
    },
    backgroundLayer4: {
        type: 'image',
        position: {x: 0, y: -135},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_background_layer_4',
        path: 'assets/img/rounds/bonus/background_layer_4.png',
    },
    backgroundLayer5: {
        type: 'image',
        position: {x: 0, y: -135},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_background_layer_5',
        path: 'assets/img/rounds/bonus/background_layer_5.png',
    },
    backgroundParticles: {
        type: 'atlas',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_bg_particles',
        path: 'assets/img/rounds/bonus/bg_particles.png',
        json: {key: '', path: 'assets/img/rounds/bonus/bg_particles.json'},
        data: {
            frames: ['bg_particle_001.png', 'bg_particle_002.png', 'bg_particle_line.png'],
        }
    },
    boardLights: [
        {
            type: 'image',
            position: {x: -702, y: -63},
            origin: {x: 0.5, y: 0.5},
            key: 'bonus_board_glow_left_2',
            path: 'assets/img/rounds/bonus/board_glow_l_2.png',
        }, {
            type: 'image',
            position: {x: -614, y: -31},
            origin: {x: 0.5, y: 0.5},
            key: 'bonus_board_glow_left_1',
            path: 'assets/img/rounds/bonus/board_glow_l_1.png',
        }, {
            type: 'image',
            position: {x: 610, y: -36},
            origin: {x: 0.5, y: 0.5},
            key: 'bonus_board_glow_right_1',
            path: 'assets/img/rounds/bonus/board_glow_r_1.png',
        }, {
            type: 'image',
            position: {x: 694, y: -63},
            origin: {x: 0.5, y: 0.5},
            key: 'bonus_board_glow_right_2',
            path: 'assets/img/rounds/bonus/board_glow_r_2.png',
        }
    ],
    boardGlow: {
        type: 'image',
        position: {x: 0, y: -220},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_board_glow',
        path: 'assets/img/rounds/bonus/board_glow.png',
        data: {
            glowColor: 0xFFFFFF,
        }
    },
    gameArea: {
        type: 'container',
        position: {x: -321, y: -431},
        origin: {x: 0.5, y: 0.5},
        key:'',
        path: '',
        data: {
            highlight: HIGHLIGHT_COLORS
        }
    },
    ball: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_ball',
        path: 'assets/img/rounds/bonus/ball.png',
        data: {
            trail: {
                color: 0xffffff,
                glow: HIGHLIGHT_COLORS
            },
        }
    },
    pin: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.25},
        key: 'bonus_pin',
        path: 'assets/img/rounds/bonus/pin.png',
        data: {
            highlights: HIGHLIGHT_COLORS
        }
    },
    specialPin: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'special_pin',
        path: 'assets/img/special_pin.png',
    },
    specialPinGlow: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'bonus_special_pin_glow',
        path: 'assets/img/rounds/bonus/special_pin_glow.png',
        data: {
            highlights: HIGHLIGHT_COLORS
        }
    },
    launcherTop: {
        type: 'image',
        position: {x: 0, y: -10},
        origin: {x: 0.5, y: 0.1},
        key: 'bonus_launcher_top',
        path: 'assets/img/rounds/bonus/launcher_top.png',
        data: {
            highlights: HIGHLIGHT_COLORS
        }
    },
    launcherTopGlow: {
        type: 'image',
        position: {x: 0, y: -15},
        origin: {x: 0.5, y: 0},
        key: 'bonus_launcher_top_glow',
        path: 'assets/img/rounds/bonus/launcher_top_glow.png',
        data: {
            highlights: HIGHLIGHT_COLORS
        }
    },
    launcherBottom: {
        type: 'image',
        position: {x: 0, y: -10},
        origin: {x: 0.5, y: 0.1},
        key: 'bonus_launcher_bottom',
        path: 'assets/img/rounds/bonus/launcher_bottom.png',
        data: {
            highlights: HIGHLIGHT_COLORS
        }
    },
    winFieldGlows: [
        {
            type: 'image',
            position: {x: -388, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 212, y: 1592},
                color: 'blue',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -388, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -340, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 260, y: 1592},
                color: 'blue',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -340, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -291, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 309, y: 1592},
                color: 'blue',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -291, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -243, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 357, y: 1592},
                color: 'green',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -243, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -195, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 405, y: 1592},
                color: 'green',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -195, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -146, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 454, y: 1592},
                color: 'green',
                text:  {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -146, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -97, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 503, y: 1592},
                color: 'yellow',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: -97, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: -49, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 551, y: 1592},
                color: 'yellow',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0xf9314d, strength: 4},
                    position: {x: -49, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 0, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 600, y: 1592},
                color: 'red',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 0, y: 25}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 50, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 650, y: 1592},
                color: 'yellow',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0xf9314d, strength: 4},
                    position: {x: 50, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 98, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 698, y: 1592},
                color: 'yellow',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 98, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 146, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 746, y: 1592},
                color: 'green',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 146, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 195, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 795, y: 1592},
                color: 'green',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 195, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 243, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 843, y: 1592},
                color: 'green',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 243, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 291, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 891, y: 1592},
                color: 'blue',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 291, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 339, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 939, y: 1592},
                color: 'blue',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 339, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
        {
            type: 'image',
            position: {x: 389, y: 0},
            origin: {x: 0.5, y: 0},
            key: '',
            path: '',
            data: {
                endPosition: {x: 989, y: 1592},
                color: 'blue',
                text: {
                    fontFamily: 'joystix_monospace',
                    color: '#ffffff',
                    fontSize: 36,
                    glow: {color: 0x2289db, strength: 4},
                    position: {x: 389, y: 0}, rotationZ: 0, angle: 0
                },
            }
        },
    ],
    star: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'special_pin_bonus',
        path: 'assets/img/special_pin.png',
    },
    starLight: {
        type: 'image',
        position: {x: 0, y: 0},
        origin: {x: 0.5, y: 0.5},
        key: 'star_light_bonus',
        path: 'assets/img/rounds/bonus/special_pin_glow.png',
        data: {
            colors: HIGHLIGHT_COLORS
        }
    },
}
