import Phaser from 'phaser';
import {VisualObject} from "../types/visualObject";
export default class Pool extends Phaser.GameObjects.Group {
    private readonly _factoryMethod: () => VisualObject
    constructor(scene: Phaser.Scene, config: Phaser.Types.GameObjects.Group.GroupConfig = {}, factoryMethod) {
        super(scene, config);
        this._factoryMethod = factoryMethod;
    }

    initializeWithSize(size: number) {
        if (this.getLength() > 0 || size <= 0) {
            return
        }

        for (let i = 0; i < size; i++) {
            this.create();
        }
    }

    create() {
        const item = this._factoryMethod();
        item.setVisible(false)
        item.setActive(false)
        this.add(item);
    }

    spawn(x = 0, y = 0) {
        let item: VisualObject = this.getFirstDead(false);
        if (!item) {
            item = this._factoryMethod();
        }

        item.x = x;
        item.y = y;

        item.setVisible(true)
        item.setActive(true)

        return item
    }

    despawn(item: VisualObject) {
        this.killAndHide(item);
        item.setVisible(false);
        item.setAlpha(1);
        item.setScale(1);
    }
}
