import {Scene} from "phaser";
import Launcher from "../launcher";
import {Skin, SkinItem} from "../../types/skin";

export function createLauncher(scene: Scene, x: number, y: number, skin:Skin, bonusType?: string) {
    const launcherItems: {
        launcher: Launcher | null,
        launcherTop: Phaser.GameObjects.Image | null,
        launcherTopGlow: Phaser.GameObjects.Image | null,
    } = {
        launcher: null,
        launcherTop: null,
        launcherTopGlow: null,
    }

    const launcher = new Launcher(scene, x, y, 100, skin, 100, bonusType);
    launcherItems.launcher = launcher;

    const launcherTopSkin = skin.launcherTop as SkinItem;
    if (launcherTopSkin !== undefined) {
        const launcherTop = scene.add.image(x + launcherTopSkin.position.x, y + launcherTopSkin.position.y, launcherTopSkin.key);
        launcherTop.setOrigin(launcherTopSkin.origin.x, launcherTopSkin.origin.y);
        if (bonusType && launcherTopSkin.data && launcherTopSkin.data.highlights) {
            launcherTop.setTint(launcherTopSkin.data.highlights[bonusType][0]);
        }
        launcherItems.launcherTop = launcherTop;
    }

    const launcherTopGlowSkin = skin.launcherTopGlow as SkinItem;
    if (launcherTopGlowSkin !== undefined) {
        const launcherTopGlow = scene.add.image(x + launcherTopGlowSkin.position.x, y + launcherTopGlowSkin.position.y, launcherTopGlowSkin.key);
        launcherTopGlow.setOrigin(launcherTopGlowSkin.origin.x, launcherTopGlowSkin.origin.y);
        if (bonusType && launcherTopGlowSkin.data && launcherTopGlowSkin.data.highlights) {
            launcherTopGlow.setTint(launcherTopGlowSkin.data.highlights[bonusType][0]);
        }
        launcherTopGlow.setAlpha(0);
        launcherItems.launcherTopGlow = launcherTopGlow;

        const glowAnim = scene.tweens.add({
            targets: launcherTopGlow,
            alpha: 1,
            duration: 50,
            ease: 'Sine.easeInOut',
            yoyo: true,
            paused: true,
            onComplete: () => {
                glowAnim.restart();
                glowAnim.pause();
            }
        });
        launcher.setData('glowAnim', glowAnim);
    }

    return launcherItems;
}
