let mute = 0;

export const openSettings = async (): Promise<boolean> => {
    mute++;
    global.game.toggleMusic((mute & 1) === 1);
    global.game.toggleSFX((mute & 2) === 2);
    return true;
}

export const openAutoplay = async (): Promise<boolean> => {
    global.game.startAutoplay({ power: 1, autoplayBalance: 50 });
    return true;
}
