import GameArea from "../objects/gameArea";
import api from "../api";
import {EVENTS} from "../utils/events";
import Balance from "../utils/balance";
import TopBoard from "../objects/topBoard";
import NormalBackground from "../objects/rounds/normal/normalBackground";
import BonusBackground from "../objects/rounds/bonus/bonusBackground";
import TransitionPopup from "../objects/transitionPopup";
import {BonusRoundConfig, WheelRoundConfig} from "../types/api/responseTypes";
import HUD from "../objects/ui/hud";
import Phaser from "phaser";
import defaultSkin from "../skins/default";
import bonusSkin from "../skins/bonus";
import PlinkoController from "../controllers/plinkoController";
import {TILE_SIZE_H, TILE_SIZE_W} from "../data/constants";

export default class MainScene extends Phaser.Scene {
    private _currentIsBonus: boolean = false;
    get topBoard(): TopBoard {
        return this._topBoard;
    }
    private _controller: PlinkoController;
    private _gameArea: GameArea | null;
    private _bg: NormalBackground | BonusBackground | null;
    private _topBoard: TopBoard;
    private _transitionPopup: TransitionPopup;
    private _overlay: Phaser.GameObjects.Graphics;
    private _hud: HUD;

    constructor() {
        super({key: 'MainScene'})
    }

    create() {
        this._controller = new PlinkoController(this, {
            tileSize: {width: TILE_SIZE_W, height: TILE_SIZE_H},
            movementRandomness: 0.2,
        })

        this._createTopBoard();
        this._createTransitionPopup();
        this._createHUD();

        this.handleResize();

        this._loadScene();

        this.events.on(EVENTS.START_WHEEL, this._startWheel, this);

        this.game.events.on(EVENTS.SHOW_MESSAGE, this._onShowMessage, this);

        this.game.registry.events.on(EVENTS.CHANGED_DATA_AUTOPLAY, this._toggleAutoplay, this);
        this.game.registry.events.on(EVENTS.CHANGED_DATA_AUTOPLAY_BALANCE, this._onAutoplayBalanceChange, this);
        this.game.registry.events.on(Phaser.Data.Events.SET_DATA, this._onSetData, this);

        this._controller.on(EVENTS.SHOW_BONUS_PROMPT, this._onShowBonusPrompt, this);
        this._controller.on(EVENTS.BALANCE_CHANGE, this._onBalanceChange, this);

        this.events.on('shutdown', this._onShutdown, this)

        this.scale.on(Phaser.Scale.Events.RESIZE, this.handleResize, this);
    }

    private _createBackground(bonusRoundConfig?: BonusRoundConfig): NormalBackground | BonusBackground {
        const bg = !!bonusRoundConfig ? new BonusBackground(this, bonusRoundConfig) : new NormalBackground(this);
        bg.setVisible(false);
        this.add.existing(bg);
        return bg;
    }

    async _loadScene(bonusConfig?: BonusRoundConfig) {
        console.log('load scene', bonusConfig);
        await this._hideScene();
        const boardConfig = await api().getBoardConfig(bonusConfig);
        this._showScene(boardConfig, bonusConfig);
    }

    _hideScene() {
        return new Promise((resolve) => {
            if (this._gameArea && this._bg) {
                this.tweens.add({
                    targets: [this._gameArea, this._bg],
                    ease: 'Cubic.easeOut',
                    alpha: 0,
                    duration: 400,
                    onComplete: () => {
                        if (this._bg) {
                            this._bg.destroy();
                            this._bg = null;
                        }
                        if (this._gameArea) {
                            this._gameArea.off(EVENTS.NO_BALLS_LEFT, this._onNoBallsLeft, this);
                            this._gameArea.destroy();
                            this._gameArea = null;
                            resolve(true);
                        }
                    }
                });
            } else {
                resolve(true);
            }
        })
    }

    _showScene(boardConfig: any, bonusRoundConfig: BonusRoundConfig | undefined = undefined) {
        this._currentIsBonus = !!bonusRoundConfig;

        this._bg = this._createBackground(bonusRoundConfig);

        this._gameArea = new GameArea(this, 0, 0, this._controller, boardConfig, bonusRoundConfig ? bonusSkin : defaultSkin, bonusRoundConfig);
        this.add.existing(this._gameArea);

        this._gameArea.on(EVENTS.NO_BALLS_LEFT, this._onNoBallsLeft, this);

        global.game.musicController.playNormalRoundMusic(this);
        global.game.sfxController.playAmbientSound(this);

        this.handleResize();

        this._topBoard.showMessage('TAP TO\nPLAY');

        //this._gameArea.setVisible(false);
        this._gameArea.setAlpha(0);
        this._topBoard.setAlpha(0);
        this._bg.setAlpha(0);
        this._hud.setAlpha(0);

        this.tweens.add({
            targets: [this._bg, this._gameArea, this._topBoard, this._hud],
            ease: 'Cubic.easeOut',
            alpha: 1,
            duration: 400,
            onComplete: () => {
                this._gameArea!.init();
            }
        });
        this._bg.setVisible(true);
        this._topBoard.setVisible(true);
    }

    _onSetData(parent: any, key: string, data: any) {
        if (key === 'autoplay') this._toggleAutoplay(parent, data);
    }

    _toggleAutoplay(parent: any, data: boolean) {
        const autoplayBalance = this.game.registry.get('autoplayBalance');
        const autoplayPower = this.game.registry.get('autoplayPower');

        if (this._gameArea) {
            this._gameArea.setAutoplay(data, autoplayPower);
        }

        this._hud.setAutoplay(data, autoplayBalance);
    }

    _onAutoplayBalanceChange (parent: any, autoplayBalance: number) {
        this._hud.setAutoplayBalance(autoplayBalance);
        if (autoplayBalance <= 0) {
            global.game.stopAutoplay();
        }
    }

    _createTopBoard() {
        this._topBoard = new TopBoard(this);
        this._topBoard.setVisible(false);
        this.add.existing(this._topBoard);
    }

    _createTransitionPopup() {
        this._overlay = this._createBgOverlay();
        this._overlay.setVisible(false);
        this._transitionPopup = new TransitionPopup(this);
        this._transitionPopup.setVisible(false);
        this.add.existing(this._transitionPopup);
    }

    private _createBgOverlay() {
        const gameSize = this.scale.gameSize;
        const bgOverlay = this.add.graphics();
        bgOverlay.fillStyle(0x000000, 0.8);
        bgOverlay.fillRect(0, 0, gameSize.width, gameSize.height);
        this.add.existing(bgOverlay);
        return bgOverlay;
    }

    _onShowMessage(message: string) {
        this._topBoard.showMessage(message);
    }

    _onNoBallsLeft() {
        global.game.stopAutoplay();
        this.game.events.emit(EVENTS.SHOW_MESSAGE, 'BUY MORE\nBALLS');
    }

    _onBalanceChange(balance: Balance, balanceChange: number, bonusBalanceChange: number) {
        this.topBoard.showBalanceChange(balance, balanceChange, bonusBalanceChange);
    }

    async _onBonusRoundComplete(totalBonusEarnings: number) {
        this._controller.off(EVENTS.BONUS_ROUND_COMPLETE, this._onBonusRoundComplete, this);
        console.log('onBonusRoundComplete', totalBonusEarnings);

        this.tweens.add({
            targets: [this._hud],
            alpha: 0,
            duration: 300,
            ease: 'Cubic.easeIn',
        });

        const winType = await api().getWinType(totalBonusEarnings);
        this.showTransitionPopup('win', undefined, winType, totalBonusEarnings);
        this.events.once(EVENTS.BONUS_PROMPT_HIDDEN, () => {
            this.tweens.add({
                targets: [this._overlay],
                ease: 'Cubic.easeIn',
                alpha: 0,
                duration: 200,
                onComplete: () => {
                    this._overlay.setVisible(false);
                }
            })

            this._gameArea?.endBonusRound();
            this._bg?.stopBonusLightAnimation();

            this._loadScene();

            this.tweens.add({
                targets: [this._hud],
                alpha: 1,
                duration: 300,
                ease: 'Cubic.easeOut',
            });

            this._hud.toggleAutoplayButtonState(true);
            this._topBoard.showMessage('TAP TO\nPLAY');
            global.game.musicController.stopSpecialRoundMusic(this).then(() => {
                global.game.musicController.playNormalRoundMusic(this);
            });
        }, this);
    }

    async _onShowBonusPrompt(bonusRoundId: string) {
        console.log('onShowBonusPrompt', bonusRoundId);
        this._hud.toggleAutoplayButtonState(false);

        this.tweens.add({
           targets: [this._gameArea, this._topBoard, this._bg, this._hud],
           alpha: 0,
           duration: 300,
           ease: 'Cubic.easeIn',
        });

        const bonusRound = await api().getBonusRound(bonusRoundId);
        this.showTransitionPopup('reel', bonusRound);
        this.events.once(EVENTS.BONUS_PROMPT_HIDDEN, () => {
            this.tweens.add({
                targets: [this._overlay],
                ease: 'Cubic.easeIn',
                alpha: 0,
                duration: 200,
                onComplete: async () => {
                    if (!this._currentIsBonus) {
                        await this._loadScene(bonusRound);
                    } else {
                        this.tweens.add({
                            targets: [this._gameArea, this._topBoard, this._bg, this._hud],
                            alpha: 1,
                            duration: 300,
                            ease: 'Cubic.easeOut',
                            onComplete: () => {
                                this._gameArea!.init();
                            }
                        });
                    }
                    this._gameArea?.playBonusRound(bonusRound);
                    this._controller.once(EVENTS.BONUS_ROUND_COMPLETE, this._onBonusRoundComplete, this);
                    this._overlay.setVisible(false);
                }
            });

            global.game.musicController.stopNormalRoundMusic(this).then(() => {
                global.game.musicController.playSpecialRoundMusic(this);
            });

            this._bg?.playBonusLightAnimation();
        }, this);
    }

    showTransitionPopup(type: 'reel' | 'win' = 'reel', bonusRound?: BonusRoundConfig, winType?: string, winAmount?: number) {
        this._overlay.alpha = 0;
        this.tweens.add({
            targets: [this._overlay],
            ease: 'Cubic.easeOut',
            alpha: 1,
            duration: 400,
        });
        this._overlay.setVisible(true);

        this._transitionPopup.show(type, bonusRound, winType, winAmount);
    }

    _startWheel(wheelRound: WheelRoundConfig) {
        this._onShutdown();
        this.scene.start('WheelScene', { wheelRound });
        this.scene.remove('MainScene');
    }

    update(time: number, delta: number) {
        if (this._gameArea) this._gameArea.update(time, delta);
        if (this._topBoard) this._topBoard.update(time, delta);
        if (this._transitionPopup) this._transitionPopup.update(time, delta);
    }

    private _createHUD() {
        this._hud = new HUD(this);
        this.add.existing(this._hud);
    }

    _onShutdown() {
        console.log('MainScene shutdown');
        if (this._gameArea) {
            this._gameArea.removeAllListeners(EVENTS.NO_BALLS_LEFT);
        }

        this.events.off(EVENTS.START_WHEEL, this._startWheel, this);

        this.game.registry.events.off(EVENTS.CHANGED_DATA_AUTOPLAY, this._toggleAutoplay, this);
        this.game.registry.events.off(EVENTS.CHANGED_DATA_AUTOPLAY_BALANCE, this._onAutoplayBalanceChange, this);

        this.game.registry.events.off(Phaser.Data.Events.SET_DATA, this._onSetData, this);
        this.events.off('shutdown', this._onShutdown, this)

        this.game.events.off(EVENTS.SHOW_MESSAGE, this._onShowMessage, this);

        //global.game.updateSize();
        this.scale.off(Phaser.Scale.Events.RESIZE, this.handleResize, this);
    }

    handleResize() {
        const gameSize = this.scale.gameSize;

        const aspectRatio = this.scale.parentSize.width / this.scale.parentSize.height;

        if (this._bg) {
            this._bg.resize(aspectRatio, gameSize);

            if (this._gameArea) {
                this._gameArea.resize({x: this._bg.x, y: this._bg.y}, this._bg.scale);
            }

            if (this._topBoard) {
                this.children.bringToTop(this._topBoard);
                this._topBoard.setScale(this.registry.get('scaleFactor'));
                this._topBoard.x = this._bg.x
                this._topBoard.y = this._bg.y - this._bg.bgHeight * 0.575;
            }

            if (this._overlay) {
                this.children.bringToTop(this._overlay);
                this._overlay.clear();
                this._overlay.fillStyle(0x000000, 0.7);
                this._overlay.fillRect(0, 0, gameSize.width, gameSize.height);
            }

            if (this._transitionPopup) {
                this.children.bringToTop(this._transitionPopup);
                this._transitionPopup.resize(gameSize);
            }
        }

        if (this._hud) {
            this._hud.resize(this.scale);
            this.children.bringToTop(this._hud);
        }
    }
}
