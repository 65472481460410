import {BONUS_WIN_TYPES} from "../data/constants";

export const getContainerSize = (scene: Phaser.Scene, con: Phaser.GameObjects.Container): {w: number, h: number} => {
    //set the top position to the bottom of the game
    let top = Number.MAX_SAFE_INTEGER;
    let bottom = -Number.MAX_SAFE_INTEGER;

    //set the left to the right of the game
    let left = Number.MAX_SAFE_INTEGER;
    let right = -Number.MAX_SAFE_INTEGER;

    //loop through the children
    con.iterate(function(child) {
        //calculate the child position
        //based on the origin
        const childTop = child.getTopLeft().y;
        const childBottom = child.getBottomRight().y;
        const childLeft = child.getTopLeft().x;
        const childRight = child.getBottomRight().x;

        //test the positions against
        //top, bottom, left and right
        if (childBottom > bottom) {
            bottom = childBottom;
        }
        if (childTop < top) {
            top = childTop;
        }
        if (childLeft < left) {
            left = childLeft;
        }
        if (childRight > right) {
            right = childRight;
        }
    }.bind(this));

    //calculate the square
    const h = Math.abs(top - bottom);
    const w = Math.abs(right - left);
    //set the container size
    con.setSize(w, h);
    return {w, h};
};

export const getTilesWithID = (tileMap, id) => {
    return tileMap.reduce((acc:  { tileIndex: number, rowIndex: number }[], row, rowIndex) => {
        row.forEach((tile, tileIndex) => {
            if (tile === id) {
                acc.push({tileIndex, rowIndex});
            }
        });
        return acc;
    }, []);
}


export const LP = (a: any, b: any) => {
    return window.innerWidth > window.innerHeight ? a : b;
}

export const getCharacterWidth = (scene: Phaser.Scene, fontSize: number, fontFamily: string) => {
    const textFile = new Phaser.GameObjects.Text(scene, 0, 0, 'O', {fontSize, fontFamily});
    return textFile.width;
}

export const setTextGradientT1 = (text: Phaser.GameObjects.Text, ): CanvasGradient => {
    const lineHeight = parseInt(String(text.style.fontSize));
    const gradient = text.context.createLinearGradient(0, 0, 0, text.height);
    const numberOfLines = Math.round(text.displayHeight / lineHeight);
    const lineStep = 0.9 / numberOfLines;
    const gradientStep = lineStep / 6;
    for (let i = 0; i < numberOfLines; i++) {
        const stop = i / numberOfLines + (i === 0 ? 0.1 : 0);
        gradient.addColorStop(stop, '#0d436d');
        gradient.addColorStop(stop + gradientStep, '#1b5852');
        gradient.addColorStop(stop + gradientStep * 2, '#148e52');
        gradient.addColorStop(stop + gradientStep * 3, '#c8d868');
        gradient.addColorStop(stop + gradientStep * 4, '#ff7122');
    }
    text.setFill(gradient);
    return gradient;
}