import Button from "../ui/button";

export function createButtonSpinAnimation(button: Button, values = [5, -5, 7, -5, 0]) {
    return {
        repeat: -1,
        tweens: [
            {
                delay: 1000,
                targets: button,
                angle: values[0],
                ease: 'Quad.easeIn',
                duration: 200,
            }, {
                targets: button,
                angle: values[1],
                ease: 'Quad.easeOut',
                duration: 200,
            }, {
                targets: button,
                angle: values[2],
                ease: 'Quad.easeIn',
                duration: 100,
            }, {
                targets: button,
                angle: values[3],
                ease: 'Quad.easeOut',
                duration: 100,
            }, {
                targets: button,
                angle: values[4],
                ease: 'Quad.easeOut',
                duration: 200,
            }
        ]
    }
}
