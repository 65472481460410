import Button from "./ui/button";
import {EVENTS} from "../utils/events";
import {BonusRoundConfig} from "../types/api/responseTypes";
import BallRandomizer from "./ballRandomizer";
import Size = Phaser.Structs.Size;
import { SpineGameObject } from "@esotericsoftware/spine-phaser/dist/SpineGameObject";
import {setTextGradientT1} from "../utils";
import Container = Phaser.GameObjects.Container;
import Text = Phaser.GameObjects.Text;
import GameObject = Phaser.GameObjects.GameObject;

export default class TransitionPopup extends Container {
    private _button?: Button;
    private _ballRandomizer?: BallRandomizer;
    private _winMessage?: SpineGameObject;
    private _winAmountTxt?: Text;
    private _winValues?: { value: number; end: number };
    private _winBalls?: Text;
    constructor(scene: Phaser.Scene) {
        super(scene);
    }

    private _createButton(): Button {
        const button = new Button(this.scene, 0, 0, {
            width: 1100,
            height: 275,
            labelText: 'GET BALLS',
            labelFontSize: 100,
        });
        button.setVisible(false);
        this.add(button);
        return button;
    }

    private _createBallRandomizer(): BallRandomizer {
        const ballRandomizer = new BallRandomizer(this.scene);
        ballRandomizer.setVisible(false);
        this.add(ballRandomizer);
        return ballRandomizer;
    }

    async show(type: 'reel' | 'win' = 'reel', bonusRound?: BonusRoundConfig, winType?: string, winAmount?: number) {
        if (this.visible) return;

        if (type === 'reel' && bonusRound) {
            this._showBallRandomizer(bonusRound);
        } else if (winType !== undefined && winAmount !== undefined) {
            this._showWinMessage(winType, winAmount);
        }

        this.setVisible(true);
    }

    _showBallRandomizer(bonusRound: BonusRoundConfig) {
        this._ballRandomizer = this._createBallRandomizer();
        this._button = this._createButton();

        this.resize(this.scene.scale.gameSize);

        this.scene.tweens.add({
            targets: [this._ballRandomizer],
            y: {start: this._ballRandomizer.y + 100, to: this._ballRandomizer.y},
            alpha: {start: 0, to: 1},
            duration: 600,
            ease: 'Cubic.easeOut',
        });

        this._ballRandomizer.setVisible(true);

        this._button.setLabel('GET BALLS');
        this.scene.tweens.add({
            targets: [this._button],
            ease: 'Cubic.easeOut',
            y: {start: this._button.y + 100, to: this._button.y},
            alpha: {start: 0, to: 1},
            duration: 600,
            delay: 600,
        });
        this._button.setVisible(true);
        this._button.showGlow();

        this._button.once(EVENTS.BUTTON_TAPPED, async () => {
            this.scene.tweens.add({
                targets: [this._button],
                alpha: 0,
                duration: 300,
                ease: 'Cubic.easeIn',
                onComplete: () => {
                    this._button!.setVisible(false);
                }
            });

            this._ballRandomizer!.spin(bonusRound);
            this._ballRandomizer!.events.once(EVENTS.RANDOMIZER_COMPLETE, () => {
                this._showButton('PLAY', 200);
            });
        });
    }

    _showButton(label: string, delay: number = 0) {
        this._button!.setLabel(label);

        this.scene.tweens.add({
            targets: [this._button],
            ease: 'Cubic.easeOut',
            y: {start: this._button!.y + 100, to: this._button!.y},
            alpha: {start: 0, to: 1},
            duration: 400,
            delay: delay,
        });
        this._button!.showGlow();
        this._button!.setVisible(true);

        this._button!.once(EVENTS.BUTTON_TAPPED, () => {
            this.hide();
        });
    }

    _createWinMessage(winType) {
        const gameSize = this.scene.scale.gameSize;
        const winMessage = this._winMessage = this.scene.make.spine({
            y: -gameSize.height * 0.5, x: 0,
            dataKey: `${winType}_data`, atlasKey: `${winType}_atlas`
        });
        this.add(winMessage);
        winMessage.setScale(0.5);
        winMessage.animationState.setAnimation(0, 'animation2', true);
        winMessage.setVisible(false);
    }

    _createWinAmountTxt(winAmount: number) {
        const gameSize = this.scene.scale.gameSize;
        const winAmountTxt = this._winAmountTxt = this.scene.add.text(0, this._winMessage ? this._winMessage.y + 300 : -gameSize.height * 0.5, `${winAmount}`, {
            fontFamily: 'arcade_classic',
            fontSize: 150,
            color: '#fff',
            align: 'center',
        });
        setTextGradientT1(winAmountTxt);
        winAmountTxt.setOrigin(0.5, 0.5);
        winAmountTxt.setShadow(5, 5, "#29B7B3", 0, false, true);
        this.add(winAmountTxt);
    }

    _createWinBalls() {
        const balls = this._winBalls = this.scene.add.text(0, this._winAmountTxt!.y + 100, `BALLS`, {
            fontFamily: 'arcade_classic',
            fontSize: 100,
            color: '#fff',
            align: 'center',
        });
        setTextGradientT1(balls);
        balls.setOrigin(0.5, 0.5);
        balls.setShadow(5, 5, "#29B7B3", 0, false, true);
        this.add(this._winBalls);
    }

    _showWinMessage(winType: string, winAmount: number = 0) {
        this._createWinMessage(winType);
        this._winValues = {
            value: 0,
            end: winAmount,
        }

        this._createWinAmountTxt(0);
        this._createWinBalls();

        this.resize(this.scene.scale.gameSize);

        this.scene.tweens.add({
            targets: [this._winAmountTxt],
            scale: {start: 2, to: 1, ease: 'Back.easeOut'},
            alpha: {start: 0, to: 1, ease: 'Quad.easeOut'},
            duration: 500,
            delay: 300,
        });

        this.scene.tweens.add({
            targets: [this._winValues],
            value: this._winValues?.end,
            duration: 1500,
            delay: 600,
            ease: 'Quad.easeOut',
            onUpdate: () => {
                this._winAmountTxt!.setText(`${Math.floor(this._winValues!.value)}`);
            },
            onComplete: () => {
                if (this._winMessage) {
                    this.scene.tweens.add({
                        targets: [this._winMessage],
                        scale: {start: 2, to: 0.5, ease: 'Back.easeOut'},
                        alpha: {start: 0, to: 1, ease: 'Quad.easeOut'},
                        duration: 600,
                    });
                    this._winMessage.setVisible(true);
                }
            }
        });

        this.scene.tweens.add({
            targets: [this._winBalls],
            scale: {start: 0, to: 1, ease: 'Back.easeOut'},
            duration: 500,
            delay: 900 ,
        });

        this._showButton('CONTINUE', 1200);
    }

    hide () {
        if (this._button) {
            this.scene.tweens.add({
                targets: [this._button],
                ease: 'Cubic.easeIn',
                alpha: {from: 1, to: 0},
                duration: 300,
                onComplete: () => {
                    this._button?.setVisible(false);
                }
            });
        }


        if (this._ballRandomizer && this._ballRandomizer.visible) {
            this.scene.tweens.add({
                targets: [this._ballRandomizer],
                y: {start: this._ballRandomizer.y, to: this._ballRandomizer.y + 100},
                alpha: {start: 1, to: 0},
                duration: 300,
                delay: 100,
                ease: 'Cubic.easeIn',
                onComplete: () => {
                    this._ballRandomizer!.reset();
                    this._ballRandomizer!.destroy();
                    this._ballRandomizer = undefined;
                }
            });
        }


        if (this._winMessage && this._winAmountTxt) {
            this.scene.tweens.add({
                targets: [this._winMessage, this._winAmountTxt, this._winBalls],
                alpha: {start: 1, to: 0},
                ease: 'Quad.easeIn',
                duration: 400,
                onComplete: () => {
                    this._winMessage?.destroy();
                    this._winMessage = undefined;
                    this._winAmountTxt?.destroy();
                    this._winAmountTxt = undefined;
                    this._winBalls?.destroy();
                    this._winBalls = undefined;
                }
            });
        }

        this.scene.time.delayedCall(500, () => {
            this.setVisible(false);
            this.scene.events.emit(EVENTS.BONUS_PROMPT_HIDDEN);
        });
    }

    update(time: number, delta: number) {
        //this._reel.update(time, delta);
    }

    resize(gameSize: Size) {
        this.x = gameSize.width * 0.5;
        this.y = gameSize.height;

        if (this._ballRandomizer) {
            this._ballRandomizer.setScale(1);
            this._ballRandomizer.setScale(Math.min(gameSize.width / this._ballRandomizer.width, gameSize.height / this._ballRandomizer.height));
            this._ballRandomizer.x = -0.545 * this._ballRandomizer.scale * this._ballRandomizer.width;
            this._ballRandomizer.y = -this._ballRandomizer.scale * this._ballRandomizer.height;

            if (this._button) {
                this._button.setScale(this._ballRandomizer.scale * 2);
                this._button.x = 0;
                this._button.y = -this._button.buttonHeight * 0.65;
            }
        }

        if (this._winMessage) {
            this._winMessage.setScale(0.5);
            this._winMessage.x = 0;
            this._winMessage.y = -gameSize.height * 0.7;
        }

        if (this._winAmountTxt) {
            this._winAmountTxt.x = 0;
            this._winAmountTxt.y = this._winMessage ? this._winMessage.y + 300 : -gameSize.height * 0.5;

            if (this._winBalls) {
                this._winBalls.x = 0;
                this._winBalls.y = this._winAmountTxt.y + 80;
            }
        }
    }
}
