import {WHEEL_VALUES} from "../data/wheelData";
import {WheelResponse} from "../types/api/responseTypes";
import {updateBalance, useWheelRound} from "./plinkoApi.mock";
import { getRandomSeed, delay } from "./mock-tools";
import seedrandom from "seedrandom";

export const spinWheel = async (id:string): Promise<WheelResponse> => {
    useWheelRound(id);
    const randomSeed = getRandomSeed();
    const random = seedrandom(randomSeed);

    const value = WHEEL_VALUES[Math.floor(random() * WHEEL_VALUES.length)];
    const valueIndex = WHEEL_VALUES.indexOf(value);
    const rotation = (WHEEL_VALUES.length - valueIndex) * (Math.PI / (WHEEL_VALUES.length * 0.5)) - Math.PI * 0.5;
    await delay();
    updateBalance(value);
    const result: WheelResponse = {
        value,
        rotation,
    };
    console.log('spinWheel', result)
    return result;
}
