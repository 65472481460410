import {EVENTS} from "../../utils/events";
import {createButtonFxAnimation} from "../tweens/buttonFxAnimation";
import {createButtonSpinAnimation} from "../tweens/buttonSpinAnimation";
import Image = Phaser.GameObjects.Image;
import TweenChain = Phaser.Tweens.TweenChain;
import Text = Phaser.GameObjects.Text;
import Zone = Phaser.GameObjects.Zone;
import Bloom = Phaser.FX.Bloom;
import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import NineSlice = Phaser.GameObjects.NineSlice;
import NineSliceConfig = Phaser.Types.GameObjects.NineSlice.NineSliceConfig;

export type ButtonConfig = {
    width: number;
    height: number;
    labelText: string;
    labelFontSize?: number;
}


export default class Button extends Container {
    private _initScale: number = 1;
    private _glowPostFx: Bloom;
    private _glowPostFxTween: TweenChain;
    private _spinAnimation: TweenChain;
    private _buttonConfig: ButtonConfig;
    get label() {
        return this._label;
    }

    private _background: NineSlice;
    private _backgroundOver: NineSlice;
    private _label: Text;
    private _interactiveArea: Zone;
    private _isEnabled: boolean;
    constructor(scene: Scene, x: number, y: number, buttonConfig: ButtonConfig) {
        super(scene);
        this.x = x;
        this.y = y;
        this._buttonConfig = buttonConfig;

        const background = this._background = scene.make.nineslice({
            x: 0,
            y: 0,
            key: 'button_bg',
            width: buttonConfig.width,
            height: buttonConfig.height,
            leftWidth: 110,
            topHeight: 110,
            rightWidth: 110,
            bottomHeight: 110,
        });
        this._glowPostFx = background.postFX.addBloom(0xFFFFFF, 1, 1, 1, 1);
        this.add(background);

        const backgroundOver = this._backgroundOver = scene.make.nineslice({
            x: 0,
            y: 0,
            key: 'button_lit',
            width: buttonConfig.width,
            height: buttonConfig.height,
            leftWidth: 110,
            topHeight: 110,
            rightWidth: 110,
            bottomHeight: 110,
        });
        backgroundOver.setAlpha(0);
        this.add(backgroundOver);

        const label = this._label = scene.add.text(0, -12, buttonConfig.labelText, {
            fontFamily: 'joystix_monospace',
            fontSize: buttonConfig.labelFontSize || 82,
            align: 'center',
            color: '#ffffff',
            wordWrap: { width: background.displayWidth * 0.8, useAdvancedWrap: true },
        });
        label.setShadow(10, 10, "#29B7B3", 0, false, true);
        label.setOrigin(0.5, 0.5);
        this.add(label);

        const interactiveArea = this._interactiveArea = scene.add.zone(0, 0, background.displayWidth, background.displayHeight);
        interactiveArea.setOrigin(0.5, 0.5);
        this._interactiveArea.on(EVENTS.POINTER_DOWN, () => {
            this.emit(EVENTS.BUTTON_TAPPED, this);
        });
        this._interactiveArea.on(EVENTS.POINTER_OVER, () => {
            this._backgroundOver.setAlpha(1);
            scene.tweens.add({
                targets: this,
                scale: this._initScale * 1.05,
                duration: 100,
                ease: 'Quad.easeOut',
            });
        });
        this._interactiveArea.on(EVENTS.POINTER_OUT, () => {
            this._backgroundOver.setAlpha(0);
            scene.tweens.add({
                targets: this,
                scale: this._initScale,
                duration: 50,
                ease: 'Quad.easeIn',
            });
        });
        this.add(interactiveArea);

        this.setEnabled(true);
    }

    showGlow() {
        this._glowPostFxTween = this.scene.tweens.chain(createButtonFxAnimation(this._glowPostFx));
    }

    showSpinIndicator() {
        this._spinAnimation = this.scene.tweens.chain(createButtonSpinAnimation(this));
    }

    stopAnimations() {
        if (this._glowPostFxTween) this._glowPostFxTween.complete();
        if (this._spinAnimation) {
            this._spinAnimation.complete();
            this.angle = 0;
        }
    }

    setScale(x?: number, y?: number): this {
        this._initScale = x || y || 1;
        return super.setScale(x, y);
    }
    setVisible(value: boolean): this {
        if (!value) this.stopAnimations();
        return super.setVisible(value);
    }

    get buttonWidth() {
        return this._background.displayWidth * this.scaleX;
    }

    get buttonHeight() {
        return this._background.displayHeight * this.scaleY;
    }

    setEnabled (isEnabled) {
        this._isEnabled = isEnabled;

        if (isEnabled) {
            this._interactiveArea.setInteractive();
        } else {
            this.stopAnimations();
            this._interactiveArea.disableInteractive();
        }
    }

    setLabel (label: string) {
        this._label.setText(label);
    }
}
