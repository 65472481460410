import {Scene} from "phaser";

/*export function createBonusRollAnimation (scene: Scene, winFieldTweens: any[], bonusAnimCb: Function) {
    const rollChain = scene.tweens.chain({
        tweens: winFieldTweens,
        onComplete: () => {
            const lastWave = scene.tweens.chain({
                tweens: winFieldTweens,
                timeScale: 0.75,
                onComplete: () => {
                    bonusAnimCb();
                }
            });
            lastWave.setTimeScale(0.25);
        }
    });
    return rollChain;
}*/

export function createBackgroundFxBonusAnimation(postFx: Phaser.FX.Bloom, values = [1.6, 1]) {
    return {
        repeat: -1,
        tweens:[
            {
                targets: postFx,
                blurStrength: values[0],
                ease: 'Quad.easeIn',
                duration: 400,
            },{
                targets: postFx,
                blurStrength: values[1],
                ease: 'Quad.easeOut',
                duration: 200,
            }
        ]
    }
}
