import Chooser from "random-seed-weighted-chooser";
import seedrandom from "seedrandom";

import {
    BalanceResponse,
    BoardConfigResponse,
    MissSide, SPECIAL_TYPES,
    BonusRoundConfig,
    WheelRoundConfig,
    TargetSlotResponse
} from "../types/api/responseTypes";
import { getRandomSeed, inputValue, initToolbar, delay, launchBall } from "./mock-tools";

import defaultMap from "../data/rounds/defaultMap";
import bonusMap from "../data/rounds/bonusMap";
import {BONUS_WIN_TYPES} from "../data/constants";

let currentMap = defaultMap;
let currentBalances = currentMap.balances.slice();
let currentOutsideWeights = currentMap.outsideWeights.slice();
let currentWeights = currentMap.weights;

const wheelRounds: WheelRoundConfig[] = [];
const bonusRounds: BonusRoundConfig[] = [];

/*{
    "id": "xxxxxx-12312312-0.4709480343362349"
}*/
/*{
    "type": "multiplier",
    "id": "xxxxxx-12312312-1701119751446",
    "balls": 8
}*/

let currentTabs: {id: number, value: string}[] = currentMap.tabs.slice();
let currentBalance = 2000;
let totalBalls = 0;

const bonusRoundTypes = Object.keys(SPECIAL_TYPES);

const triggerBonusRound = () => {
    const bonusValue = inputValue('bonusRound', bonusRoundTypes[Math.floor(Math.random() * bonusRoundTypes.length)]);
    const round = {
        type: inputValue('bonusRound', bonusRoundTypes[Math.floor(Math.random() * bonusRoundTypes.length)]),
        id: 'xxxxxx-12312312-' + Date.now(),
        balls: 5,
        balanceOrder: getBonusBalanceOrder(bonusValue),
    };
    bonusRounds.push(round);
    return round;
};

const triggerWheelSpin = () => {
    const spin = { id: 'xxxxxx-12312312-' + Math.random()};
    wheelRounds.push(spin);
    return spin;
};

const returnBalance = (): BalanceResponse => ({
    balance: currentBalance,
    wheelRounds,
    bonusRounds,
});

export const updateBalance = async (balanceChange: number): Promise<boolean> => {
    await delay();
    currentBalance += balanceChange;
    return true;
}

export const getBoardConfig = async (bonusRound: BonusRoundConfig): Promise<BoardConfigResponse> => {
    initToolbar();
    await delay();

    currentMap = bonusRound ? bonusMap : defaultMap;

    currentBalances = bonusRound ? bonusRound.balanceOrder.slice() : currentMap.balances.slice();

    currentOutsideWeights = currentMap.outsideWeights.slice();
    currentWeights = currentMap.weights;
    console.log('currentBalances', currentBalances);
    return {
        map: currentMap.map,
        balance: returnBalance(),
        tabs: currentMap.tabs.slice(),
        winFieldsValues: currentBalances.map(x => x === -Infinity ? 'star' : String(x)),
    };
}

export const launchNormalBall = async (launchPower: number): Promise<TargetSlotResponse> => {
    launchBall();
    const randomSeed = getRandomSeed();
    const random = seedrandom(randomSeed);

    let idx: number | null = null;
    let miss: string | null = null;
    let balanceChange = 0;
    let isSpecialBall = !!inputValue('bonusRound', random() >= (1-1/2500), false);
    let bonusRoundId: string | null = null;
    let railSide: 0 | 1 | false = false;

    const selectedWeights = Math.abs(launchPower) > 0.2 + random() ? 1 : 0;
    let weights = currentWeights[selectedWeights].slice();
    if (isSpecialBall) {
        idx = currentBalances.indexOf(-Infinity);
        balanceChange = 0;
        triggerBonusRound();

    } else if (random() >= currentOutsideWeights[selectedWeights]) {
        miss = random() > 0.5 ? MissSide.left : MissSide.right;
    } else {
        idx = Number(inputValue('targetSlot', Chooser.chooseWeightedIndex(weights, randomSeed)));
        balanceChange = currentBalances[idx];
        balanceChange = balanceChange === -Infinity ? 0 : balanceChange;
    }

    const {tab, wheelRound} = getWheelTargets(random);

    let bumperSide: Number | String | false = isSpecialBall? false : inputValue('bumper', (random() > 0.9) ? (random() >= 0.5 ? 0 : 1) : false, false);
    if (bumperSide !== false) {
        bumperSide = Number(bumperSide);
        railSide = inputValue('rail', random() <= 0.3 ? (bumperSide === 0 ? 1 : 0) : false);
        console.log('bumperSide', bumperSide, 'railSide', railSide);
    }

    currentBalance += balanceChange - 1;

    const result: TargetSlotResponse = {
        randomSeed,
        targetSlot: idx,
        miss,
        balanceChange,
        balance: returnBalance(),
        isSpecialBall,
        wheelRound,
        tab,
        railSide,
        bumperSide,
    };
    console.log('launchNormalBall', result);
    await delay();
    return result;
}

export const launchBonusBall = async (id: string, launchPower: number): Promise<TargetSlotResponse> => {
    const randomSeed = getRandomSeed();
    const random = seedrandom(randomSeed);

    let idx: number | null = null;
    let miss: string | null = null;
    let isSpecialBall = !!inputValue('bonusRound', random() > 0.98, false);
    let balanceChange = 0;
    let bonusBalanceChange = 0;
    const currentBonusRound = bonusRounds.find(x => x.id === id)!;
    const bonusBalances = currentBonusRound.balanceOrder;
    console.log('bonusBalances', bonusBalances, 'currentBonusRound', currentBonusRound );


    const selectedWeights = Math.abs(launchPower) > 0.2 + random() ? 1 : 0;
    let weights = currentWeights[selectedWeights].slice();
    if (isSpecialBall) {
        idx = bonusBalances.indexOf(-Infinity);
        balanceChange = 50;
    } else if (random() >= currentOutsideWeights[selectedWeights]) {
        miss = random() > 0.5 ? MissSide.left : MissSide.right;
    }  else {
        idx = Chooser.chooseWeightedIndex(weights);

        console.log('bonusBalances[idx]', idx, bonusBalances[idx]);

        if (bonusBalances[idx] === -Infinity) {
            currentBalance += 0;
        } else {
            bonusBalanceChange = bonusBalances[idx];
            currentBalance += bonusBalanceChange;
        }
    }

    const {tab, wheelRound} = getWheelTargets(random);

    currentBonusRound.balls += balanceChange - 1;
    if (currentBonusRound.balls === 0) {
        bonusRounds.splice(bonusRounds.indexOf(currentBonusRound), 1);
    }

    const result: TargetSlotResponse = {
        randomSeed,
        targetSlot: idx,
        miss: miss,
        balanceChange,
        bonusBalanceChange,
        balance: returnBalance(),
        tab,
        wheelRound,
        bumperSide: false,
        railSide: false,
        isSpecialBall,
    };
    console.log('launchBonusBall', result);
    await delay();
    return result;
}

const getWheelTargets = (random): {tab: {id: number, value: string} | false, wheelRound: WheelRoundConfig | null} => {
    let tab: {id: number, value: string} | false = false;
    let wheelRound: WheelRoundConfig | null = null;
    if (inputValue('hitWheel', random() > 0.999, false)) {
        tab = currentTabs[Math.floor(random() * currentTabs.length)];
        currentTabs = currentTabs.filter((t) => t !== tab);
        console.log('Hit wheel', currentTabs);
        if (currentTabs.length == 0) {
            wheelRound = triggerWheelSpin();
            currentTabs = currentMap.tabs.slice();
        }
    }
    return {tab, wheelRound};
}

/*const getBonusRoundTargets = (bonusRound: BonusRoundConfig) => {
    const targets: BonusRoundTargets = [];

    const filteredBalanceInd = currentBalances.reduce((acc: number[], balance: number, i: number) => {
        if (balance > 0) acc.push(i);
        return acc;
    }, []);

    if (bonusRound.type === SPECIAL_TYPES.multiplier) {
        let targetBonusSlot = Phaser.Utils.Array.GetRandom(filteredBalanceInd);
        if (targetBonusSlot) targets.push({targetSlot: targetBonusSlot, value: currentBalances[targetBonusSlot] * bonusMultiplier});
    } else if (bonusRound.type === SPECIAL_TYPES.shuffle) {
        const filteredZeroInd = currentBalances.reduce((acc: number[], balance: number, i: number) => {
            if (balance === 0) acc.push(i);
            return acc;
        }, []);
        let targetBonusSlot = Phaser.Utils.Array.GetRandom(filteredBalanceInd);
        let secondaryBonusSlot = Phaser.Utils.Array.GetRandom(filteredZeroInd);
        if (targetBonusSlot) targets.push({targetSlot: targetBonusSlot, value: currentBalances[secondaryBonusSlot]});
        if (secondaryBonusSlot) targets.push({targetSlot: secondaryBonusSlot, value: currentBalances[targetBonusSlot]});
    } else if (bonusRound.type === SPECIAL_TYPES.combo) {
        let targetBonusSlot = Phaser.Utils.Array.GetRandom(filteredBalanceInd);
        let secondaryBonusSlot = targetBonusSlot > currentBalances.length * 0.5 ? targetBonusSlot - 1 : targetBonusSlot + 1;
        if (targetBonusSlot) {
            targets.push({targetSlot: targetBonusSlot, value: currentBalances[targetBonusSlot]});
            targets.push({targetSlot: secondaryBonusSlot, value: currentBalances[targetBonusSlot]});
        }
    }

    return targets;
}*/

const getBonusBalanceOrder = (bonusValue:string) => {
    return currentBalances.map(x => {
        const value = bonusValue.slice(1, bonusValue.length);
        if (value === '0') return x === 0 ? 1 : x;
        return x * parseInt(value);
    });
}

const fixedShuffleIndex = (a: Array<any>, f: Array<boolean>) => {
    const list = a.reduce((acc, e, i) => {
        if(!f[i]) {
            acc.pos.push(i);
            acc.unfixed.push(e);
        }
        return acc;
    }, { pos: [], unfixed: [] });

    list.pos = Phaser.Utils.Array.Shuffle(list.pos);

    return a.map((e, i) => {
        return f[i] ? e : list.unfixed[list.pos.indexOf(i)]
    })
}

export const getBonusRound = async (id: string): Promise<BonusRoundConfig> => {
    await delay(100);
    global.game.showMessage(`TAP TO PLAY\nTHE BONUS\nROUND!`);
    return bonusRounds.find(x => x.id === id)!;
};

export const getWheelRound = async (id: string): Promise<WheelRoundConfig> => {
    await delay(100);
    return wheelRounds.find(x => x.id === id)!;
};

export const useWheelRound = (id: string) => {
    const wheelRound = wheelRounds.find(x => x.id === id)!;
    wheelRounds.splice(wheelRounds.indexOf(wheelRound), 1);
}

export const getWinType = async (value: number): Promise<string> => {
    await delay(100);
    const win  = BONUS_WIN_TYPES.find((win) => {
        return win.range[0] <= value && win.range[1] >= value;
    });
    return win ? win.type : 'ultimate_win';
}

