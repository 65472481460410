import {EVENTS} from "../utils/events";
import Button from "./ui/button";
import EventEmitter = Phaser.Events.EventEmitter;
import Text = Phaser.GameObjects.Text;
import {Scene} from "phaser";
import { SpineGameObject } from "@esotericsoftware/spine-phaser";
import Color = Phaser.Display.Color;
import HIDDEN = Phaser.Core.Events.HIDDEN;

export default class WheelResultContainer extends Phaser.GameObjects.Container {
    private _totalWin: SpineGameObject;
    private _result: Text;
    private _balls: Text;
    private _button: Button;
    constructor(scene: Scene, value: number) {
        super(scene);

        this._create(value);
    }

    _create(value: number) {
        const totalWin = this._totalWin = this.scene.make.spine({
            y: -300, x: 20,
            dataKey: `wheel_win_data`, atlasKey: `wheel_win_atlas`
        });
        totalWin.setScale(1.8);
        totalWin.animationState.setAnimation(0, 'animation', true);
        this.add(totalWin);

        const result = this._result = this.scene.add.text(0, 0, `${value}`, {
            fontFamily: 'arcade_classic',
            fontSize: 270,
            color: '#ffffff',
            align: 'center',
        });
        result.setShadow(5, 5, '#F13179', 0, true, true);
        result.x = 0;
        result.y = 100;
        result.setOrigin(0.5, 0);
        this.add(result);

        const balls = this._balls = this.scene.add.text(0, 0, `BALLS`, {
            fontFamily: 'arcade_classic',
            fontSize: 120,
            color: '#ffffff',
            align: 'center',
        });
        balls.setShadow(5, 5, '#F13179', 0, true, true);
        balls.x = 0;
        balls.y = result.y + result.height * 0.85;
        balls.setOrigin(0.5, 0);
        this.add(balls);

        const button = this._button = new Button(this.scene, 0, 0, {
            width: 781,
            height: 275,
            labelText: 'CONTINUE'
        });
        button.x = 0;
        button.y = balls.y + balls.height + button.buttonHeight * 0.5 + 230;
        button.setScale(1);
        button.once(EVENTS.BUTTON_TAPPED, () => {
            this.hide();
        });
        this.add(button);

        this.setVisible(false);
    }

    show() {
        global.game.sfxController.playWheelTotalWinSfx();
        this.scene.tweens.add({
            targets: [this._totalWin],
            alpha: {start: 0, to: 1, ease: 'Quad.easeOut'},
            scale: {start: 2, to: this._totalWin.scale, ease: 'Back.easeOut'},
            duration: 400,
        });
        this.scene.tweens.add({
            targets: [this._result],
            alpha: {start: 0, to: 1, ease: 'Quad.easeOut'},
            x: {start: this._result.x + 100, to: this._result.x, ease: 'Back.easeOut'},
            ease: 'Back.easeOut',
            duration: 500,
            delay: 400,
        });
        this.scene.tweens.add({
            targets: [this._balls],
            alpha: {start: 0, to: 1, ease: 'Quad.easeOut'},
            x: {start: this._balls.x + 100, to: this._balls.x, ease: 'Back.easeOut'},
            ease: 'Back.easeOut',
            duration: 500,
            delay: 600,
        });
        this.scene.tweens.add({
            targets: [this._button],
            alpha: {start: 0, to: 1, ease: 'Quad.easeOut'},
            y: {start: this._button.y + 100, to: this._button.y, ease: 'Back.easeOut'},
            duration: 1000,
            delay: 1200,
        });
        this._button.showGlow();

        this.setVisible(true);
    }

    hide () {
        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            y: this.y + 100,
            ease: 'Back.easeIn',
            duration: 400,
            onComplete: () => {
                this._button.stopAnimations();
                this.setVisible(false);
                this.emit(HIDDEN);
            }
        });
    }
}
