import Phaser, {Scene} from 'phaser';
import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import Plane = Phaser.GameObjects.Plane;
import {fadeInOut} from "../tweens";
import {Skin, SkinItem, SkinItems, SkinTextItem} from "../../types/skin";
import Text = Phaser.GameObjects.Text;
import Color = Phaser.Display.Color;
import {getCharacterWidth} from "../../utils";
import {DEBUG} from "../../data/constants";
import bonus from "../../skins/bonus";

export function createFinishArea(scene: Scene, x: number, y: number, values: string[], skin:Skin, bonusType?: string) {
  let star: Image | null  = null;

  console.log('createFinishArea', values);

  const finishAreaItems: {
    winArea: Container | null,
    winFields: (Image | null)[] | null,
    winFieldsTexts: (Plane | Image | Text)[] | null,
    star: Image | null,
  } = {
    winArea: null,
    winFields: null,
    winFieldsTexts: null,
    star: null,
  }

  const winArea = scene.add.container(
      x, y,
  );
  finishAreaItems.winArea = winArea;
  const winFieldsBackgroundSkin = skin.winFieldsBackground as SkinItem;
  if (winFieldsBackgroundSkin !== undefined) {
    const winBg = scene.add.image(winFieldsBackgroundSkin.position.x, winFieldsBackgroundSkin.position.y, winFieldsBackgroundSkin.key);
    winBg.setOrigin(winFieldsBackgroundSkin.origin.x, winFieldsBackgroundSkin.origin.y);
    winArea.add(winBg);
  }

  const winFields = (skin.winFieldGlows as SkinItems).map((item:SkinItem, index) => {
    const winField = scene.add.image(item.position.x, item.position.y, item.key);
    winField.setOrigin(item.origin.x, item.origin.y);
    winField.setAlpha(0);
    winField.setVisible(!!item.key);
    winField.setData('value', values[index]);
    if (item.data) {
      if (item.data.endPosition) {
        winField.setData('endPosition', item.data.endPosition);
      }
      if (item.data.color) winField.setData('color', item.data.color);
    }
    winArea.add(winField);
    return winField;
  });
  finishAreaItems.winFields = winFields;

  const winFieldTextOptions: SkinTextItem[] = (skin.winFieldGlows as SkinItems).map((item:SkinItem, index) => {
    if (item.data && item.data.text) return item.data.text;
  });

  console.log('winFieldTextOptions', winFieldTextOptions);

  const winFieldsTexts = winFieldTextOptions.map((item, index) => {
    const fieldValue = values[index];
    if (fieldValue === 'star') {
      star = createWinStar(scene, winArea, item.position.x, item.position.y, skin.star as SkinItem, skin.starLight as SkinItem, bonusType);
      finishAreaItems.star = star;
      return star;
    } else {
      return createWinText(scene, winArea, item, fieldValue);
    }
  });
  finishAreaItems.winFieldsTexts = winFieldsTexts;
  winArea.setScale(0.99);

  return finishAreaItems;
}

export function createWinStar (scene: Scene, parent: Container, x: number, y: number, starSkin: SkinItem, starLightSkin: SkinItem, bonusType?: string) {
  const star = scene.add.image(x, y, starSkin.key);
  star.setOrigin(starSkin.origin.x, starSkin.origin.y);

  const starLight = scene.add.image(star.x, star.y, starLightSkin.key);
  if (bonusType && starLightSkin.data && starLightSkin.data.colors) {
    starLight.setTint(starLightSkin.data.colors[bonusType][0]);
  }
  starLight.setOrigin(starLightSkin.origin.x, starLightSkin.origin.y);
  starLight.setAlpha(0);
  const blinkTween = scene.tweens.add({
    targets: starLight,
    alpha: 1,
    ease: 'Quad.easeInOut',
    duration: 160,
    yoyo: true,
    loop: -1,
    paused: true
  });
  star.setData('blinkTween', blinkTween);

  parent.add(star);
  parent.add(starLight);
  return star;
}

export function createWinText(scene: Scene, parent: Container, item: SkinTextItem, value: string) {
  const characterWidth = getCharacterWidth(scene, item.fontSize, item.fontFamily);

  const winFieldText = scene.add.text(0, 0, value, {
    fontFamily: item.fontFamily,
    fontSize: item.fontSize,
    color: item.color,
    align: 'center',
    wordWrap: {width: characterWidth + 1, useAdvancedWrap: true}
  });

  if (item.rotationZ) {
    winFieldText.visible = false;

    if (!scene.textures.exists(value + '_text')) {
      let renderTexture = scene.add.renderTexture(0, 0, winFieldText.width, winFieldText.height);
      renderTexture.draw(winFieldText);
      renderTexture.saveTexture(value + '_text');
      renderTexture.destroy();
    }

    const textFieldHeight = (winFieldText.height);
    let offsetX = -Math.sin(Phaser.Math.DegToRad(item.angle)) * textFieldHeight * 0.5 * 1.1;
    let offsetY = Math.abs(Math.cos(Phaser.Math.DegToRad(item.angle))) * textFieldHeight * 0.5;

    const winFieldPlane = scene.add.plane(item.position.x + offsetX, item.position.y + offsetY, value + '_text');
    winFieldPlane.modelRotation.z = item.rotationZ;
    winFieldPlane.angle = item.angle;
    if (item.glow) winFieldPlane.postFX.addGlow(item.glow.color, item.glow.strength, 0, false, 0.1, 16);
    parent.add(winFieldPlane);

    winFieldText.destroy();
    return winFieldPlane;
  } else {
    if (item.glow) winFieldText.setStroke(Color.IntegerToColor(item.glow.color).rgba, item.glow.strength * 2);
    winFieldText.setPosition(item.position.x, item.position.y);
    winFieldText.setRotation(item.angle);
    winFieldText.setOrigin(0.5, 0);
    parent.add(winFieldText);
    return winFieldText;
  }
}
