import {EVENTS} from "../../utils/events";

export default class ToggleButton extends Phaser.GameObjects.Sprite {
    private _normalTexture: string;
    private _toggleTexture: string;
    get isToggled(): boolean {
        return this._isToggled;
    }

    private _isToggled: boolean;
    constructor(scene: Phaser.Scene, x: number, y: number, texture: string, toggleTexture: string, actionOnClick = (toggled: boolean) => {}) {
        super(scene, x, y, texture)
        scene.add.existing(this);

        this._normalTexture = texture;
        this._toggleTexture = toggleTexture;

        this._isToggled = false;

        this.setInteractive();
        this.on(EVENTS.POINTER_DOWN, () => {
            this.setScale(1);
            this.scene.tweens.add({
                targets: this,
                scale: 0.9,
                ease: 'Quad.easeOut',
                duration: 100,
                yoyo: true,
            });
            global.game.sfxController.playGeneralButtonSfx();
            this._isToggled = !this._isToggled;
                actionOnClick(this._isToggled);
                this.setTexture(this._isToggled ? toggleTexture : texture);
            });
    }

   setEnabledState(enabled: boolean){
        this.setAlpha(enabled ? 1 : 0.5);
        enabled ? this.setInteractive() : this.disableInteractive();
    }

    setToggled(value: boolean): this {
        if (this._isToggled !== value) {
            this._isToggled = value;
            this.setTexture(this._isToggled ? this._toggleTexture : this._normalTexture);
        }
        return this;
    }
}
