import {EVENTS} from "../utils/events";
import {blink, fadeInOut} from "./tweens";
import Balance from "../utils/balance";
import {setTextGradientT1} from "../utils";
import bonus from "../skins/bonus";

const LIGHT_POSITIONS = [
    {x: -0.46, y: -0.25},
    {x: -0.422, y: -0.27},
    {x: -0.382, y: -0.3},
    {x: -0.342, y: -0.33},
    {x: -0.303, y: -0.36},

    {x: -0.230, y: -0.385},
    {x: -0.191, y: -0.385},
    {x: -0.151, y: -0.385},
    {x: -0.11, y: -0.385},
    {x: -0.069, y: -0.385},
    {x: -0.028, y: -0.385},
    {x: 0.013, y: -0.385},
    {x: 0.055, y: -0.385},
    {x: 0.095, y: -0.385},
    {x: 0.136, y: -0.385},
    {x: 0.178, y: -0.385},
    {x: 0.22, y: -0.385},

    {x: 0.3, y: -0.36},
    {x: 0.338, y: -0.33},
    {x: 0.379, y: -0.3},
    {x: 0.419, y: -0.27},
    {x: 0.457, y: -0.25},

];

export default class TopBoard extends Phaser.GameObjects.Container {
    private _bg: Phaser.GameObjects.Image;
    private _explanationTxt: Phaser.GameObjects.Text;
    private _balanceChanges: Phaser.GameObjects.Text[];
    private _balanceTxt: Phaser.GameObjects.Text;
    private _lights: Phaser.GameObjects.Image[];
    private _lightTw: Phaser.Tweens.TweenChain;
    private _lightBonusTw: Phaser.Tweens.Tween;
    private _scaleInOutTw: Phaser.Tweens.Tween;
    private _explFadeInTw: Phaser.Tweens.Tween;
    private _bonusAmountContainer: Phaser.GameObjects.Container;
    private _bonusAmountTxt: Phaser.GameObjects.Text;

    constructor(scene) {
        super(scene);

        this._balanceChanges = [];
        this._lights = [];

        this._create();

        this.scene.events.on(EVENTS.BONUS_ANIMATION_STARTED, this._onShowBonusAnimations, this);
        this.scene.events.on(EVENTS.BONUS_ANIMATION_STOPPED, this._onStopBonusAnimations, this);
    }

    private _create() {
        this._createBg();
        this._createCenterText();
        this._createBalanceText();
        this._createBonuseAmountText();
    }

    private _createBg() {
        const bg = this._bg = this.scene.add.image(0, 0, 'top_board');
        const bgGlow = this.scene.add.image(0, 0, 'top_board_glow');
        this.add(bg);
        this.add(bgGlow);
        bgGlow.setAlpha(0);

        this.scene.tweens.add(fadeInOut(this.scene, bgGlow, 600, 0, 0, 0, -1, true));

        LIGHT_POSITIONS.map((pos, i) => {
            const light = this.scene.add.image(bg.width * pos.x, bg.height * pos.y, 'yellow_light');
            light.setAlpha(0);
            this._lights.push(light);
            this.add(light);
        });

        this._playLightAnimation();
    }

    private _playLightAnimation() {
        const bg = this._bg;
        let lightTimeline = this._lights.map((light, i) => {
            light.setAlpha(0);
            return fadeInOut(this.scene, light, 100, 100, 0, 1000);
        });

        this._lightTw = this.scene.tweens.chain({
            tweens: lightTimeline,
            loop: -1,
        });
    }

    private _createCenterText() {
        const explanation = this._explanationTxt = this.scene.add.text(0, 0, 'TAP TO\nPLAY!', {
            fontFamily: 'arcade_classic',
            fontSize: 100,
            color: '#fff',
            align: 'center',
        });

        explanation.setOrigin(0.5, 0.5);
        explanation.setLineSpacing(-30);

        setTextGradientT1(explanation);
        explanation.setShadow(5, 5, "#29B7B3", 0, false, true);
        explanation.setVisible(false);
        this.add(explanation);

        console.log('explanation', explanation.style.fontSize, explanation.displayHeight / (parseInt(String(explanation.style.fontSize)) * 1.33));
    }

    _createBonuseAmountText() {
        const bonusAmountContainer = this._bonusAmountContainer = this.scene.add.container(0, 0);
        const totalWinTxt = this.scene.add.text(0, -20, 'TOTAL WIN', {
            fontFamily: 'joystix_monospace',
            fontSize: 50,
            color: '#F5CF32',
            align: 'center',
        });
        totalWinTxt.setOrigin(0.5, 1);
        totalWinTxt.setShadow(5, 5, "#29B7B3", 0, false, true);
        setTextGradientT1(totalWinTxt);
        bonusAmountContainer.add(totalWinTxt);

        const bonusAmountTxt = this._bonusAmountTxt = this.scene.add.text(0, -40, '000', {
            fontFamily: 'joystix_monospace',
            fontSize: 100,
            color: '#F5CF32',
            align: 'center',
        });
        bonusAmountTxt.setOrigin(0.5, 0);
        bonusAmountTxt.setShadow(5, 5, "#29B7B3", 0, false, true);
        setTextGradientT1(bonusAmountTxt);
        bonusAmountContainer.add(bonusAmountTxt);
        bonusAmountContainer.setVisible(false);
        this.add(bonusAmountContainer);
    }

    _createBalanceText() {
        const balance = this._balanceTxt = this.scene.add.text(this._bg.displayWidth * 0.334, this._bg.displayHeight * 0.1, '000', {
            fontFamily: 'joystix_monospace',
            fontSize: 60,
            color: '#F5CF32',
            align: 'center',
        });
        balance.setOrigin(0.5, 0.5);
        balance.setRotation(Math.PI * 0.1);
        balance.setShadow(5, 5, "#29B7B3", 0, false, true);
        this.add(balance);
    }

    public showBalanceChange(balance: Balance, balanceChange: number, bonusBalanceChange: number) {
        console.log('balance change', balance);
        if (bonusBalanceChange > 0) balanceChange = bonusBalanceChange;
        if (balance.isBonusRound) {
            console.log('bonus round balance change', balance.displayBalance);

            if (bonusBalanceChange > 0) {
                if (this._explFadeInTw) {
                    this._explFadeInTw.stop();
                }
                if (this._scaleInOutTw) {
                    this._scaleInOutTw.stop();
                }

                this._explanationTxt.setVisible(false);

                this._bonusAmountTxt.setText(balance.localBalance.toString());
                this._bonusAmountContainer.setVisible(true)
            }
        }
        if (balanceChange && balanceChange > 0) {
            const balanceChangeVisual = this.scene.add.text(-this._bg.displayWidth * 0.32, this._bg.displayHeight * 0.4, `+${balanceChange.toString()}`, {
                fontFamily: 'joystix_monospace',
                fontSize: 44,
                align: 'center',
            });
            const gradient = balanceChangeVisual.context.createLinearGradient(0, 0, 0, balanceChangeVisual.height);
            gradient.addColorStop(0.1, '#104767');
            gradient.addColorStop(0.9, '#BDD266');
            balanceChangeVisual.setShadow(5, 5, "#29B7B3", 0, false, true);
            balanceChangeVisual.setFill(gradient);
            balanceChangeVisual.setOrigin(0.5, 0.5);
            balanceChangeVisual.setRotation(-Math.PI * 0.1);
            this._balanceChanges.unshift(balanceChangeVisual);
            this.add(balanceChangeVisual);

            balanceChangeVisual.alpha = 0;

            this._balanceChangeAnimationSequence(balanceChangeVisual);

            this._balanceChangesHideSurplus();

            this._balanceChangesMoveUp();
        }

        this._balanceTxt.setText(balance.displayBalance.toString());
        this._balanceTxt.setScale(1);
        this._balanceTxt.setScale(Math.min(1, this._bg.displayWidth * 0.14 / this._balanceTxt.displayWidth));
    }

    _balanceChangesMoveUp() {
        this._balanceChanges.forEach((balanceChangeVisual, index) => {
            this.scene.tweens.add({
                targets: [balanceChangeVisual],
                x: -this._bg.displayWidth * 0.33 - index * 10,
                y: this._bg.displayHeight * 0.25 - index * 40,
                ease: 'Cubic.easeInOut',
                duration: 200
            });
        });
    }

    _balanceChangesHideSurplus() {
        this._balanceChanges = this._balanceChanges.filter((balanceChangeVisual, index) => {
            if (index > 3) {
                this.scene.tweens.add({
                    targets: [balanceChangeVisual],
                    ease: 'Cubic.easeInOut',
                    alpha: 0,
                    duration: 100,
                    onComplete: () => {
                        balanceChangeVisual.destroy();
                    }
                });
                return false;
            }
            return true;
        });
    }

    _balanceChangeAnimationSequence(balanceChangeVisual: Phaser.GameObjects.Text) {
        this.scene.tweens.add({
            targets: [balanceChangeVisual],
            alpha: 1,
            ease: 'Cubic.easeInOut',
            duration: 200,
            onComplete: () => {
                if (!this.scene) return;
                this.scene.tweens.add({
                    targets: [balanceChangeVisual],
                    ease: 'Cubic.easeInOut',
                    alpha: 0,
                    duration: 400,
                    delay: 1000,
                    onComplete: () => {
                        if (this._balanceChanges.indexOf(balanceChangeVisual) === -1) return;
                        balanceChangeVisual.destroy();
                        this._balanceChanges.splice(this._balanceChanges.indexOf(balanceChangeVisual), 1);
                    }
                });
            }
        });
    }

    showMessage(message: string) {
        this._bonusAmountContainer.setVisible(false);
        if (this._explFadeInTw) {
            this._explFadeInTw.stop();
        }
        if (this._scaleInOutTw) {
            this._scaleInOutTw.stop();
        }

        this._explanationTxt.setFontSize(100);
        this._explanationTxt.setText(message);
        this._explanationTxt.setScale(1);
        setTextGradientT1(this._explanationTxt);
        const scale = Math.min(1, this._bg.displayWidth * 0.4 / this._explanationTxt.displayWidth);
        this._explanationTxt.setScale(0);
        this._explFadeInTw = this.scene.add.tween({
            targets: this._explanationTxt,
            scale: scale,
            ease: 'Back.easeOut',
            duration: 400,
            onComplete: () => {
                this._explanationTxt.setAlpha(1);
                this._scaleInOutTw = this.scene.tweens.add(blink([this._explanationTxt], scale * 0.95, 300));
            }
        });
        this._explanationTxt.setVisible(true);
    }

    hideMessage() {
        this.scene.add.tween({
            targets: this._explanationTxt,
            scale: 0,
            ease: 'Back.easeIn',
            duration: 400,
            onComplete: () => {
                this._explanationTxt.setVisible(false);
            }
        });
    }

    _onShowBonusAnimations() {
        this._lightTw.stop();
        this._lightBonusTw = this.scene.tweens.add(fadeInOut(this.scene, this._lights, 100, 0, 0, 0, -1, true));
    }

    _onStopBonusAnimations() {
        if (this._lightBonusTw) {
            this._lightBonusTw.stop();
        }

        this._playLightAnimation();
    }

    update(time, delta) {
        super.update(time, delta);

        this._balanceChanges.forEach((change) => {
            change.update(time, delta);
        });
    }
}
