import {Scene} from "phaser";
import {SkinItem} from "../../types/skin";
import Image = Phaser.GameObjects.Image;

export function createPin(scene: Scene, x: number, y: number, pinSkin: SkinItem, pinShadowSkin: SkinItem) {
  const pinItems: {pin: null | Image, pinShadow: null | Image, pinLight: null | Image} = {
    pin: null, pinShadow: null, pinLight: null
  }
  if (pinShadowSkin !== undefined) {
    const pinShadow = scene.add.image(x + pinShadowSkin.position.x, y + pinShadowSkin.position.y, pinShadowSkin.key);
    pinShadow.setOrigin(pinShadowSkin.origin.x, pinShadowSkin.origin.y);
    pinItems.pinShadow = pinShadow;
  }

  const pin = scene.add.image(x + pinSkin.position.x, y + pinSkin.position.y,  pinSkin.key);
  pin.setOrigin(pinSkin.origin.x, pinSkin.origin.y);
  pinItems.pin = pin;

  const pinLight = scene.add.image(x, y, 'yellow_light');
  pinLight.setAlpha(0);
  pinItems.pinLight = pinLight;

  pin.setData('isPin', true);
  pin.setData('light', pinLight);

  return pinItems;
}

export function createSpecialPin(scene: Scene, x: number, y: number, pinSkin: SkinItem, pinShadowSkin: SkinItem, specialPinGlowSkin: SkinItem, bonusType?: string) {
  const pinItems: {pin: null | Image, pinShadow: null | Image, specialPinLight: null | Image} = {
    pin: null, pinShadow: null, specialPinLight: null
  }

  if (pinShadowSkin !== undefined) {
    const pinShadow = scene.add.image(x + pinShadowSkin.position.x, y + pinShadowSkin.position.y, pinShadowSkin.key);
    pinShadow.setScale(2.6, 1.5);
    pinShadow.setOrigin(pinShadowSkin.origin.x, pinShadowSkin.origin.y);
    pinItems.pinShadow = pinShadow;
  }

  console.log('createSpecialPin', pinSkin);
  const pin = scene.add.image(x + pinSkin.position.x, y + pinSkin.position.y, pinSkin.key);
  pin.setOrigin(pinSkin.origin.x, pinSkin.origin.y);
  pin.setData('isSpecialPin', true);
  pinItems.pin = pin;

  const specialPinLight = scene.add.image(x + specialPinGlowSkin.position.x, y + specialPinGlowSkin.position.y, specialPinGlowSkin.key);
  specialPinLight.setOrigin(specialPinGlowSkin.origin.x, specialPinGlowSkin.origin.y);
  specialPinLight.setAlpha(0);
  if (bonusType && specialPinGlowSkin.data && specialPinGlowSkin.data.highlights) {
    specialPinLight.setTint(specialPinGlowSkin.data.highlights[bonusType][0]);
  }
  pinItems.specialPinLight = specialPinLight;

  const blinkTween = scene.tweens.add({
    targets: specialPinLight,
    alpha: 1,
    duration: 150,
    ease: 'Sine.easeInOut',
    yoyo: true,
    repeat: -1,
    paused: true,
  });
  pin.setData('blinkTween', blinkTween);

  return pinItems;
}
