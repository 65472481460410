import PlinkoController from "../controllers/plinkoController";
import {VisualObject} from "../types/visualObject";
import Pool from "./pool";
import Launcher from "./launcher";
import {BASE_MOVEMENT_DURATION, DEBUG, TILE_SIZE_H, TILE_SIZE_W} from "../data/constants";
import {EVENTS} from "../utils/events";
import {getTilesWithID} from "../utils";
import Balance from "../utils/balance";
import { BOARD_INDEX } from '../data/constants';
import Tabs from "./tabs";
import api from "../api";
import {BoardConfigResponse, BonusRoundConfig, BonusRoundTargets, WheelRoundConfig} from "../types/api/responseTypes";
import Phaser from "phaser";
import {createPin, createSpecialPin} from "./factories/pinFactory";
import {createFinishArea, createWinText} from "./factories/finishAreaFactory";
import {createLauncher} from "./factories/launcherFactory";
import {createBumper} from "./factories/bumperFactory";
import {createRail} from "./factories/railFactory";
import {createTabs} from "./factories/tabsFactory";
import {fadeInOut, scaleInOut} from "./tweens";
import {createPinsWaveAnimation} from "./tweens/pinsWaveAnimation";
import MainScene from "../scenes/mainScene";
import Tween = Phaser.Tweens.Tween;
import {Skin, SkinItem, SkinItems, SkinTextItem} from "../types/skin";

import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import Graphics = Phaser.GameObjects.Graphics;
import Sprite = Phaser.GameObjects.Sprite;
import Text = Phaser.GameObjects.Text;
import Plane = Phaser.GameObjects.Plane;
import Ball from "./ball";
import Trail from "./trail";

export default class GameArea extends Container {
    private _underBallLayer: Container;
    private _ballLayer: Container;
    private _aboveBallLayer: Container;
    private _tileHeight: number;
    private _arrowBlinkTween: Phaser.Tweens.TweenChain;
    private _winArea: Container;
    private _winFields: (Image | null)[];
    private _tileWidth: number;
    private _mapData: number[][];
    private _ball: Ball | null;
    private _pathDrawn: Graphics;
    private _costMarks: Graphics;
    private _controller: PlinkoController;
    private _pinFeedbackPool: Pool;
    private _canLaunch: boolean = false;
    private _launcher: Launcher;
    private _balanceTiles: Phaser.Math.Vector2[];
    private _launcherTw: Phaser.Tweens.Tween;
    private _isBlocked: boolean = false;
    private _tabs: { id: number, value:string }[];
    private _star: Image | null;
    private _bonusAnimationInProgress: boolean;
    private _winFieldsTexts: (Image | Plane | Text)[];
    private _bonusTexts: (Image | Plane | Text)[];
    private _winFieldsValues: string[];
    private _launchEnabled: boolean;
    private _activeSkin: Skin;
    private _bonusRoundConfig: BonusRoundConfig | null;
    private _config: BoardConfigResponse;
    constructor(scene: MainScene, x: number, y: number, controller: PlinkoController, config : BoardConfigResponse, skin: Skin, bonusRoundConfig: BonusRoundConfig | null = null) {
        super(scene, x, y);

        this._config = config;
        this._activeSkin = skin;
        this._mapData = config.map;
        this._tabs = config.tabs;
        this._winFieldsValues = config.winFieldsValues;
        this._controller = controller;
        this._bonusRoundConfig = bonusRoundConfig;

        this._controller.changeMap(this._mapData);

        console.log('tabs', this._tabs);

        this._tileWidth = TILE_SIZE_W;
        this._tileHeight = TILE_SIZE_H;

        this._balanceTiles = [];
        this._bonusTexts = [];
        this._winFieldsTexts = [];

        this._createLayers();

        this._createMap();

        this._createPinFeedbackPool();
    }

    public init() {
        this._controller.on(PlinkoController.EVENTS.MOVE_COMPLETE, this._moveComplete, this);

        this._controller.on(PlinkoController.EVENTS.PATH_READY, this._onPathReady, this);

        this._controller.on(PlinkoController.EVENTS.PIN_HIT, this._pinHitFeedback, this);

        this._launcher.on(Launcher.EVENTS.POWER_RELEASED, this._onReleased, this);

        this._controller.on(EVENTS.BALANCE_CHANGE, this._onBalanceChange, this);

        this._controller.on(EVENTS.BALANCE_CHANGE_VALIDATED, this._onBalanceValidated, this);

        this._controller.on(PlinkoController.EVENTS.MOVE_UPDATE, this._onBallMoveUpdate, this);

        this._controller.on(PlinkoController.EVENTS.ENTER_RAIL, this._onEnterRail, this);
        this._controller.on(PlinkoController.EVENTS.EXIT_RAIL, this._onExitRail, this);

        this.scene.events.on(EVENTS.LAUNCH_DOWN, this._onLaunchDown, this);
        this.scene.events.on(EVENTS.LAUNCH_UP, this._onLaunchUp, this);

        this._controller.balance.updateServerState(this._config.balance, true);

        this._dropBall();

        this._initInput();
    }

    _createLayers() {
        this._underBallLayer = this.scene.add.container();
        this._ballLayer = this.scene.add.container();
        this._aboveBallLayer = this.scene.add.container();

        this.add(this._underBallLayer);
        this.add(this._ballLayer);
        this.add(this._aboveBallLayer);
    }

    private _createMap() {
        this._mapData.forEach((row: any, rowIndex: number) => {
            row.forEach((tile: any, tileIndex: number) => {
                if (tile === BOARD_INDEX.pin) {
                    this._createPin(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.dropStart) {
                    this._createStartArea(tileIndex, rowIndex)
                } else if (tile === BOARD_INDEX.finish || tile === BOARD_INDEX.specialFinishTile) {
                    this._createFinishArea(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.outsideFinish) {
                    this._createOutsideDrops(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.specialPin) {
                    this._createSpecialPin(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.bumper) {
                    this._createBumper(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.launcher) {
                    this._createLauncher(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.railStart) {
                    this._createRail(tileIndex, rowIndex);
                } else if (tile === BOARD_INDEX.tabs && this._tabs && this._tabs.length) {
                    this._createTabs(tileIndex, rowIndex);
                }
            });
        });

        this._createWinFields();
    }

    private _createPin(tileIndex: number, rowIndex: number) {
        const pinSkin = this._activeSkin.pin as SkinItem;
        const pinShadowSkin = this._activeSkin.pinShadow as SkinItem;

        if (!pinSkin) return;
        const {
            pin,
            pinLight,
            pinShadow
        } = createPin(this.scene, tileIndex * this._tileWidth, rowIndex * this._tileHeight, pinSkin, pinShadowSkin);
        const radius = this._tileWidth * 0.3;

        if (pin) {
            pin.setData('tilePosition', {tileIndex, rowIndex});
            this._controller.addPinPositions({tileIndex, rowIndex, radius, view: pin});
        }


        if (pinShadow)this._underBallLayer.add(pinShadow);
        if (pin) this._aboveBallLayer.add(pin);
        if (pinLight)this._aboveBallLayer.add(pinLight);
    }

    private _createPinFeedbackPool() {
        this._pinFeedbackPool = new Pool(this.scene, undefined, this._createPinFeedback);
        this._pinFeedbackPool.initializeWithSize(10);
    }

    private _createPinFeedback() {
        const pinFeedback = this.scene.add.image(0, 0, 'pin');
        pinFeedback.setOrigin(0.5, 0.5);
        return pinFeedback;
    }

    private _createSpecialPin(tileIndex: number, rowIndex: number) {
        const specialPinSkin = this._activeSkin.specialPin as SkinItem;
        const pinShadowSkin = this._activeSkin.pinShadow as SkinItem;
        const specialPinGlowSkin = this._activeSkin.specialPinGlow as SkinItem;

        if (!specialPinSkin) return;
        const {
            pin,
            specialPinLight,
            pinShadow
        } = createSpecialPin(this.scene, tileIndex * this._tileWidth, rowIndex * this._tileHeight, specialPinSkin, pinShadowSkin, specialPinGlowSkin, this._bonusRoundConfig?.type);

        if (pin) {
            pin.setData('tilePosition', {tileIndex, rowIndex});
            this._controller.addSpecialPinPositions({tileIndex, rowIndex: rowIndex - 1, radius: this._tileWidth * 0.3, view: pin});
        }


        if (pinShadow) this._underBallLayer.add(pinShadow);
        if (pin) this._aboveBallLayer.add(pin);
        if (specialPinLight) this._aboveBallLayer.add(specialPinLight);
    }

    private _createStartArea(tileIndex: number, rowIndex: number) {
        this._controller.addStartPositions({tileIndex, rowIndex});
    }

    private _createFinishArea(tileIndex: number, rowIndex: number) {
        const balanceTile = new Phaser.Math.Vector2(tileIndex, rowIndex);
        this._balanceTiles.push(balanceTile);
        this._controller.addFinishPositions({tileIndex, rowIndex});
    }

    private _createWinFields() {
        if (this._balanceTiles.length > 0) {
            const {winArea, winFields, winFieldsTexts, star} = createFinishArea(
                this.scene,
                this._balanceTiles[Math.floor(this._balanceTiles.length * 0.5)].x * this._tileWidth,
                this._balanceTiles[0].y * this._tileHeight - this._tileHeight * 0.25,
                this._winFieldsValues,
                this._activeSkin,
                this._bonusRoundConfig?.type
            );

            if (winArea) this._winArea = winArea;
            if (winFields) this._winFields = winFields;
            if (winFieldsTexts) this._winFieldsTexts = winFieldsTexts;
            if (star)  this._star = star;

            this._underBallLayer.add(this._winArea);
        }
    }

    private _createLauncher(tileIndex: number, rowIndex: number) {
        const {
            launcher,
            launcherTop,
            launcherTopGlow
        } = createLauncher(this.scene, tileIndex * this._tileWidth, rowIndex * this._tileHeight, this._activeSkin, this._bonusRoundConfig?.type);

        if (launcher) {
            launcher.setData('boardPosition', {tileIndex, rowIndex});
            this._launcher = launcher;
            this._controller.addLauncherPosition({tileIndex, rowIndex});
            this._underBallLayer.add(launcher);
        }

        if (launcherTop) this._aboveBallLayer.add(launcherTop);
        if (launcherTopGlow) this._aboveBallLayer.add(launcherTopGlow);
    }

    private _createOutsideDrops(tileIndex: number, rowIndex: number) {
        this._controller.addOutsideFinishPositions({tileIndex, rowIndex});
    }

    private _createBumper(tileIndex: number, rowIndex: number) {
        const side = tileIndex < this._mapData[0].length / 2 ? 'left' : 'right';
        const bumperSkin = side === 'left' ? this._activeSkin.bumperLeft : this._activeSkin.bumperRight;
        if (!bumperSkin) return;
        const {
            bumper,
            bumperGlow,
            bumperUnderGlow,
            bumperShadow,
            radius
        } = createBumper(this.scene, tileIndex * this._tileWidth, rowIndex * this._tileHeight, side, this._activeSkin);
        this._underBallLayer.add(bumperUnderGlow);
        this._underBallLayer.add(bumperShadow);
        this._aboveBallLayer.add(bumper);
        this._aboveBallLayer.add(bumperGlow);
        this._controller.addBumpers({tileIndex, rowIndex, radius, view: bumper});
    }

    _createRail(tileIndex: number, rowIndex: number) {
        const side = tileIndex < this._mapData[0].length / 2 ? 'left' : 'right';
        const railEndTiles = getTilesWithID(this._mapData, BOARD_INDEX.railEnd);
        const railEndTile = side === 'left' ? railEndTiles[0] : railEndTiles[1];
        const pipeSkin = (side === 'left' ? this._activeSkin.pipeLeft : this._activeSkin.pipeRight) as SkinItem;

        if (!pipeSkin) return;

        const {
            railView,
            arrowGlow1,
            arrowGlow2,
            arrow,
            railViewTop,
            pathDrawn,
            ringGlows,
            path
        } = createRail(this.scene, tileIndex * this._tileWidth, rowIndex * this._tileHeight, side, this._activeSkin);

        if (arrow && arrowGlow1 && arrowGlow2) {
            this._underBallLayer.add(arrow);
            this._underBallLayer.add(arrowGlow1);
            this._underBallLayer.add(arrowGlow2);
        }

        this._underBallLayer.add(railView);
        this._aboveBallLayer.add(railViewTop);
        this._aboveBallLayer.add(pathDrawn);
        this._aboveBallLayer.add(ringGlows);

        this._controller.addRail({startTile: {tileIndex, rowIndex}, endTile: railEndTile, path, view: railView});
    }

    _createTabs(tileIndex: number, rowIndex: number) {
        const tabs = createTabs(this.scene, tileIndex * this._tileWidth, rowIndex * this._tileHeight, this._activeSkin, this._tabs);
        this._controller.addTabs({tileIndex, rowIndex, view: tabs});
        this._underBallLayer.add(tabs);
    }

    private _dropBall() {
        const ballShadow = this.scene.add.image(0, 0, 'ball_shadow');

        const ball = this._ball = new Ball(this.scene, 0, 0, this._activeSkin.ball as SkinItem, this._bonusRoundConfig?.type);
        ball.setShadow(ballShadow);
        ball.visible = false;
        ballShadow.visible = false;


        if (this._launcher) {
            ball.x = this._launcher.x;
            ball.y = this._launcher.y;
            ballShadow.x = ball.x;
            ballShadow.y = ball.y + 20;
        }

        const ballTrail = new Trail(this.scene, {
            thickness: {
                start: ball.displayWidth * 0.2,
                end: ball.displayWidth * 0.2
            },
            pointLifetime: 150,
            maxPoints: 180,
            opacity: 0.3,
        }, {x: 0, y: 0});
        const ballTail = new Trail(this.scene, {
            thickness: {
                start: ball.displayWidth * 0.6,
                end: 0
            },
            pointLifetime: 14,
            maxPoints: 30,
            opacity: 0.9,
        }, {x: 0, y: 0});

        ball.setTrail(ballTrail);
        ball.setTail(ballTail);

        this._ballLayer.add(ballShadow);
        this._ballLayer.add(ballTrail);
        this._ballLayer.add(ballTail);
        this._ballLayer.add(ball);
    }

    private _pinHitFeedback({ball, position, target} : {ball: Ball, position: {x: number, y: number}, target: VisualObject | undefined})
    {
        if (!target) return;

        if (target.getData('isBumper')) {
            if (window.navigator.vibrate) window.navigator.vibrate([200]);
            this.scene.tweens.add(scaleInOut(this.scene, target, 1.06, 60, 150, 0, 0));

            const glow = target.getData('glow');
            glow.setAlpha(0);
            if (glow) this.scene.tweens.add(fadeInOut(this.scene, glow, 50, 200, 0, 0));

        } else if (target.getData('isTab')) {
            if (window.navigator.vibrate) window.navigator.vibrate([200]);
            (target as Tabs).hitTab(ball.getData('tab'));
            console.log('hit tab wheelRound', ball.getData('wheelRound'));
            if (ball.getData('wheelRound') && !this._controller.balance.isBonusRound) {
                this.pause();
            }
        } else if (target.getData('isPin') || target.getData('isSpecialPin')) {
            const pinFeedback = this._pinFeedbackPool.spawn( target.x, target.y);
            this._aboveBallLayer.add(pinFeedback);
            this.scene.tweens.add({
                targets: [pinFeedback],
                scale: 4,
                alpha: 0,
                duration: 400,
                onComplete: (tween) => {
                    this._pinFeedbackPool.despawn(pinFeedback);
                }
            });

            if (target.getData('isSpecialPin')) {
                if (window.navigator.vibrate) window.navigator.vibrate([200]);
                const specialPinIndex = this._controller.specialPinPositions.findIndex((specialPinPosition) => target === specialPinPosition.view);

                if (specialPinIndex === 0) {
                    ball.setFirstSpecialPinHit(true);
                }
                ball.addSpecialPinHit();

                const blinkTw = target.getData('blinkTween') as Tween;
                if (blinkTw) {
                    blinkTw['lastBall'] = ball;
                    if (ball.getData('blinkTweens')) {
                        ball.setData('blinkTweens', [...ball.getData('blinkTweens'), blinkTw]);
                        blinkTw.play();
                    } else {
                        ball.setData('blinkTweens', [blinkTw]);
                        if (specialPinIndex == 0) {
                            blinkTw.play();
                        }
                    }
                }

            }
        }
        this._playPinHitSound(target, ball);
    }

    private _playPinHitSound(target: VisualObject, ball: Ball) {
        const scene = this.scene as MainScene;
        if (target.getData('isBumper')) {
            global.game.sfxController.playBumperSfx();
        } else if (target.getData('isTab')) {
            global.game.sfxController.playTabSfx();
        } else if (target.getData('isSpecialPin')) {
            global.game.sfxController.playSpecialPinSfx(ball.specialPinsHit);
        } else {
            global.game.sfxController.playDingSfx();
        }
    }

    private async _moveComplete (ball: Ball) {
        if (!ball.getData('path')) return;

        ball.stopTrail();

        if (!ball.getData('isOutside')) {
            this._onTargetBallSequence(ball);
        } else {
            this._outsideBallSequence(ball);
        }

        const isBonusRound = this._controller.balance.isBonusRound;

        const isSpecialBall = ball.getData('isSpecialBall');
        if (isSpecialBall) {
            if (!this._controller.balance.isBonusRound) {
                this.pause();
                global.game.stopAutoplay();
            }
            await this._bonusLightingAnimation(isBonusRound, ball.getData('balanceChange'));
        } else {
            const blinkTweens = ball.getData('blinkTweens') as Tween[];
            if (blinkTweens) {
                blinkTweens.forEach((blinkTween) => {
                    if (blinkTween['lastBall'] === ball) {
                        blinkTween.seek(0);
                        blinkTween.pause();
                    }
                });
            }
        }

        if (!(isBonusRound && isSpecialBall)) {
            this._controller.balance.validateBalanceChange(ball.getData('balanceChange'),  ball.getData('bonusBalanceChange'));
        }
    }

    _outsideBallSequence(ball: Ball) {
        global.game.sfxController.playEndSlotEmptySfx();

        const smoke = this.scene.add.image(0, 0, 'smoke');
        smoke.setOrigin(0.5, 0.5);
        smoke.x = ball.x;
        smoke.y = ball.y;
        this.add(smoke);
        this.scene.add.tween({
            targets: [smoke],
            scale: {from: 0, to: 1},
            alpha: {from: 1, to: 0},
            y: {from: smoke.y, to: smoke.y - 200},
            duration: 1000,
            ease: 'Quad.easeOut',
            onComplete: () => {
                smoke.destroy();
            }
        });

        this._killBall(ball);
    }

    _onTargetBallSequence(ball: Ball) {
        const finishIndex = ball.getData('finishIndex');

        if (finishIndex !== undefined) {
            console.log('finish index', finishIndex);
            const field = this._winFields[finishIndex];
            const winBg = this._winArea.list[0] as Image;
            if (field) {
                const endPosition = field.getData('endPosition');
                global.game.sfxController.playEndSlotSfx(field.getData('color'));
                field.setAlpha(0);
                this.scene.tweens.add({
                    targets: [field],
                    alpha: 0.6,
                    duration: 300,
                    ease: 'Quad.easeInOut',
                    yoyo: true,
                    onComplete: () => {
                        field.setAlpha(0);
                    }
                });

                if (endPosition) {
                    this.scene.tweens.add({
                        targets: [ball],
                        x: endPosition.x,
                        y: endPosition.y,
                        duration: 1000,
                        ease: 'Sine.easeIn',
                        onComplete: () => {
                            this._killBall(ball);
                        }
                    });
                }
            }
        } else {
            this._killBall(ball);
        }
    }

   async _onBalanceValidated() {
        console.log('_onBalanceValidated', this._controller.balance.isBonusRound);
        if (!this._controller.balance.isBonusRound) {
            const pendingBonusRoundId = this._controller.balance.getPendingBonusRound();
            console.log('_onBalanceValidated bonusRound', pendingBonusRoundId);
            if (pendingBonusRoundId) {
                this.pause();
                this._controller.emit(EVENTS.SHOW_BONUS_PROMPT, pendingBonusRoundId);
                return;
            }

            const pendingWheelRoundId = this._controller.balance.getPendingWheelRound();
            console.log('_onBalanceValidated wheelRound', pendingWheelRoundId);
            if (pendingWheelRoundId) {
                const pendingWheelRound = await api().getWheelRound(pendingWheelRoundId);
                if (pendingWheelRound) {
                    this._launchWheel(pendingWheelRound);
                }
            }
        }
    }

    async _bonusLightingAnimation(isBonusRound: boolean, balanceChange: number) {
        console.log('_bonusLightingAnimation', isBonusRound);
        if (!this._bonusAnimationInProgress) {
            let bonusRound: BonusRoundConfig | undefined;
            let pendingBonusRoundId: string | null = null;
            if (!isBonusRound) {
                pendingBonusRoundId = this._controller.balance.getPendingBonusRound();
                if (pendingBonusRoundId) {
                    bonusRound = await api().getBonusRound(pendingBonusRoundId);
                    if (bonusRound) {
                        this._controller.balance.setBonusRound(bonusRound);
                    }
                }
            } else {
                global.game.showMessage('50 FREE\nBALLS');
            }

            global.game.sfxController.playBonusLights();

            this.scene.events.emit(EVENTS.BONUS_ANIMATION_STARTED);

            createPinsWaveAnimation(this.scene, this._controller.pinPositions, this._controller.topStartPosition.rowIndex);

            this._controller.bumpers.forEach((bumper, index) => {
                const bumperView = bumper.view;
                const glow = bumperView.getData('glow');
                glow.setAlpha(0);
                const blink = this.scene.tweens.add(fadeInOut(this.scene, glow, 100, 0, 0, 0, -1, true));
                bumperView.setData('bonusBlinkTween', blink);
            });

            this._controller.rails.forEach((rail, index) => {
                const railView = rail.view;
                const arrowBlinkTween = railView.getData('arrowBlinkTween');
                arrowBlinkTween.setTimeScale(3);
            });

            this._controller.tabs.forEach((tab, index) => {
                const tabView = tab.view as Tabs;
                tabView.bonusAnimation();
            });

            if (this._star && this._star.getData('blinkTween')) {
                this._star.getData('blinkTween').play();
            }

            setTimeout(() => {
                this._stopBonusLightingAnimation();

                if (pendingBonusRoundId && bonusRound) {
                    this._controller.emit(EVENTS.SHOW_BONUS_PROMPT, pendingBonusRoundId);
                    const winFieldTextOptions: SkinTextItem[] = (this._activeSkin.winFieldGlows as SkinItems).map((item:SkinItem, index) => {
                        if (item.data && item.data.text) return item.data.text;
                    });
                    if (winFieldTextOptions.length > 0) {
                        bonusRound.balanceOrder.forEach((balance, index) => {
                            if (balance === -Infinity) return;
                            this._bonusTexts.push(createWinText(this.scene, this._winArea, winFieldTextOptions[index], balance.toString()));
                            this._winFieldsTexts[index].setVisible(false);
                        });
                    }
                }

                console.log('BonusLightingAnimation end', isBonusRound, balanceChange);

                if (isBonusRound) {
                    global.game.showMessage('TAP TO\nPLAY');
                    setTimeout(() => {
                        this._canLaunch = true;
                    }, 100);
                    const balanceChanges = this._controller.balance.serverBalanceChanges.slice();
                    balanceChanges.forEach((balanceChange) => {
                        this._controller.balance.validateBalanceChange(balanceChange);
                    });
                }
            }, 2500);
        }

        this._bonusAnimationInProgress = true;
    }

    /*_playBonusRollAnimation(bonusTargets: BonusRoundTargets) {
        let bonusSlotViews = bonusTargets.map((target) => {
            return this._winFields[target.targetSlot];
        });

        let winFieldTweens = this._winFields.map((winField, index) => {
            return fadeInOut(this.scene, winField, 25, bonusSlotViews.indexOf(winField) > -1 ? 0 : 50, 0, 100)
        });

        bonusTargets.forEach((target, index) => {
            createBonusRollAnimation(this.scene, winFieldTweens, () => {
                this._bonusTexts.push(createWinText(this.scene, this._winArea, this._winFieldsBackground, target.targetSlot, target.value.toString()));
                this._winFieldsTexts[target.targetSlot].setVisible(false);
            });
        });
    }*/

    private _stopBonusLightingAnimation() {
        if (!this._bonusAnimationInProgress) return;
        this._bonusAnimationInProgress = false;

        this._controller.pinPositions.forEach((pinPosition) => {
            const pin = pinPosition.view;
            const pinLight = pin.getData('light');
            const blinkTween = pin.getData('bonusBlinkTween');
            blinkTween.stop();
            pinLight.alpha = 0;
        });

        this._controller.bumpers.forEach((bumper) => {
            const bumperView = bumper.view;
            const glow = bumperView.getData('glow');
            const blink = bumperView.getData('bonusBlinkTween');
            blink.stop();
            glow.alpha = 0;
        });

        this._controller.rails.forEach((rail) => {
            const railView = rail.view;
            const arrowBlinkTween = railView.getData('arrowBlinkTween');
            arrowBlinkTween.setTimeScale(1);
        });

        this._controller.tabs.forEach((tab) => {
            const tabView = tab.view as Tabs;
            tabView.stopBonusAnimation();
        });

        if (this._star && this._star.getData('blinkTween')) {
            this._star.getData('blinkTween').seek(0);
            this._star.getData('blinkTween').pause();
        }

        this._controller.specialPinPositions.forEach((specialPinPosition, index) => {
            const pin = specialPinPosition.view;
            const blinkTween = pin.getData('blinkTween');
            if( blinkTween) {
                blinkTween.seek(0);
                blinkTween.pause();
            }
        });

        this.scene.events.emit(EVENTS.BONUS_ANIMATION_STOPPED);
    }

    async _launchWheel (wheelRound: WheelRoundConfig) {
        console.log('_launchWheel');
        this.pause();

        setTimeout(() => {
            this.scene.events.emit(EVENTS.START_WHEEL, wheelRound);
        }, 1000);

    }

    pause() {
        this._isBlocked = true;
    }

    public async playBonusRound(bonusRound: BonusRoundConfig) {
        console.log('playBonusRound');
        this._controller.balance.setBonusRound(bonusRound);
        this.unpause();
    }

    public async endBonusRound() {
        console.log('endBonusRound');
        this._controller.balance.endBonusRound();

        this._bonusTexts.forEach((bonusText, index) => {
            bonusText.destroy();
        });
        this._bonusTexts = [];
        this._winFieldsTexts.forEach((winFieldText, index) => {
            winFieldText.visible = true;
        });
        this._winFields.forEach((winField, index) => {
            if (winField) winField.setAlpha(0);
        });
    }

    unpause() {
        this._isBlocked = false;
    }

    private _killBall(ball: Ball) {
        setTimeout(() => {
            this._controller.removeItem(ball);
            ball.destroy();
        }, 0);
    }

    private _onPathReady (ball: Ball, path: Array<{x: number, y: number}>, costFields: {tileIndex: number, rowIndex: number}[]) {
        this._drawPath(path);
        this._drawWeights(costFields);

        const launchDirection = ball.getData('launchDirection');
        const r = ball.getData('random')();
        const baseDuration = BASE_MOVEMENT_DURATION - Math.abs(launchDirection * r * 100);

        this._controller.moveItem(ball, path, baseDuration);
    }

    private _drawPath(path: { x: number, y: number }[]) {
        if (!DEBUG) return;

        if (this._pathDrawn) {
            this._pathDrawn.destroy();
        }
        const pathDrawn = this._pathDrawn = this.scene.add.graphics();
        pathDrawn.lineStyle(4, 0xfd5431, 1);
        pathDrawn.beginPath();
        path.forEach((point: { x: number, y: number }, index) => {
            if (index === 0) {
                pathDrawn.moveTo(point.x * this._tileWidth, point.y * this._tileHeight + (this._mapData[point.y][point.x] === 10 ? 0 : this._tileHeight * 0.5));
            } else {
                pathDrawn.lineTo(point.x * this._tileWidth, point.y * this._tileHeight + (this._mapData[point.y][point.x] === 10 ? 0 : this._tileHeight * 0.5));
            }
        });
        this.add(pathDrawn);
        pathDrawn.strokePath();
    }

    private _drawWeights(costFields: {tileIndex: number, rowIndex: number}[]) {
        if (!DEBUG) return;

        if (this._costMarks) { this._costMarks.destroy() }

        const costMarks = this._costMarks = this.scene.add.graphics();
        costFields.forEach((position) => {
            const rect = new Phaser.Geom.Rectangle(position.tileIndex * this._tileWidth - this._tileWidth * 0.5, position.rowIndex * this._tileHeight - this._tileHeight * 0.5, this._tileWidth, this._tileHeight);
            costMarks.fillStyle(0xff00ff, 0.4);
            costMarks.fillRectShape(rect);
        });
        this.add(costMarks);
    }

    private _onBalanceChange(balance: Balance) {
        this._launcher.setBallsCount(balance.displayBalance);
    }

    private _initInput () {
        this._launcher.setInteractive(new Phaser.Geom.Rectangle(-100, -100, 200, 200), Phaser.Geom.Rectangle.Contains);
        this._canLaunch = true;
    }

    private _onReleased() {
        console.log('onReleased _isBlocked', this._isBlocked, this._canLaunch);
        if (!this._canLaunch || !this._ball) return;
        this._canLaunch = false;

        const launchDirection = this._launcher.getPower() * (0.5 + Math.random() * 0.5);
        const dropTile = this._getDropTile(launchDirection);
        this._ball.setData('launchDirection', launchDirection);
        this._ball.setVisible(true);
        this._ball.shadow.setVisible(true);

        const pathDrawn = this.scene.add.graphics();
        this.add(pathDrawn);
        const path = new Phaser.Curves.Path(0, 0);
        path.startPoint = new Phaser.Math.Vector2(this._ball.x, this._ball.y);
        path.lineTo((dropTile.tileIndex) * this._tileWidth, (dropTile.rowIndex - 0.5) * this._tileHeight);
        if (DEBUG) path.draw(pathDrawn, 64);

        global.game.sfxController.playLaunchSfx();
        this._launcher.getData('glowAnim').play();

        this._controller.balance.updateBalance(-1);

        this._controller.drop(this._ball, this._tileWidth * 0.5, dropTile.tileIndex, dropTile.rowIndex, launchDirection);

        this._ball = null;

        this._launcherTw = this.scene.tweens.add({
            targets: [this._launcher],
            scale: {y: 1},
            ease: 'Back.easeOut',
            duration: 100,
            onComplete: () => {
                this._dropBall();
                if (!this._isBlocked && this._controller.balance.hasBalance) {
                    this._canLaunch = true;
                }
            }
        });
    }

    private _getDropTile(launchDirection) {
        const startPositionsLength = this._controller.startPositions.length;
        const directionOffset = Phaser.Math.RND.realInRange(launchDirection * 0.5, launchDirection * 1.3);
        let startPosition: number;

        if (launchDirection < 0) {
            startPosition = Math.floor(startPositionsLength * 0.5 * (1 + directionOffset));
        } else {
            startPosition = Math.floor(startPositionsLength * 0.5) + Math.floor(startPositionsLength * 0.5 * directionOffset)
        }
        startPosition = Phaser.Math.Clamp(startPosition, 0, startPositionsLength - 1);
        return this._controller.startPositions[startPosition];
    }

    private _onBallMoveUpdate(balls: Ball[]) {
        balls.forEach((ball) => {
            const shadow = ball.shadow;
            if (shadow) {
                shadow.x = ball.x;
                shadow.y = ball.y + 30;
            }
        });
    }

    private _onEnterRail () {
        global.game.sfxController.playBallEnterTube();
        global.game.sfxController.playBallInTube();
    }

    private _onExitRail () {
        global.game.sfxController.stopBallInTube();
        global.game.sfxController.playBallExitTube();
    }

    private _onLaunchDown() {
        console.log('launch down _isBlocked', this._isBlocked);
        this._launchEnabled = true;
    }

    private _onLaunchUp() {
        console.log('launch up _isBlocked', this._isBlocked);
        this._launchEnabled = false;
    }

    public setAutoplay(autoplay: boolean, autoplayPower?: number) {
        if (autoplayPower) {
            this._launcher.setPower(autoplay? autoplayPower : 1);
        }

        this._launchEnabled = autoplay;
    }

    update (time: number, delta: number) {
        if (this._canLaunch && this._launchEnabled && !this._isBlocked && this._controller.balance.hasBalance) {
            if (!this._launcher.isRunning) {
                this._launcher.start();
            }
        }

        if (this._controller.droppedItems.length > 0) {
            this._controller.droppedItems.forEach((item) => {
                item.update(time, delta);
            });
        }
    }

    resize (position: {x: number, y: number}, scale: number) {
        const gameAreaSkin = this._activeSkin.gameArea as SkinItem;
        this.setScale(scale);
        this.x = position.x + gameAreaSkin.position.x;
        this.y = position.y + gameAreaSkin.position.y;
    }

    destroy() {
        this._controller.off(PlinkoController.EVENTS.MOVE_COMPLETE, this._moveComplete, this);

        this._controller.off(PlinkoController.EVENTS.PATH_READY, this._onPathReady, this);

        this._controller.off(PlinkoController.EVENTS.PIN_HIT, this._pinHitFeedback, this);

        this._launcher.off(Launcher.EVENTS.POWER_RELEASED, this._onReleased, this);

        this._controller.off(EVENTS.BALANCE_CHANGE, this._onBalanceChange, this);

        this._controller.off(EVENTS.BALANCE_CHANGE_VALIDATED, this._onBalanceValidated, this);

        this._controller.off(PlinkoController.EVENTS.MOVE_UPDATE, this._onBallMoveUpdate, this);

        this._controller.off(PlinkoController.EVENTS.ENTER_RAIL, this._onEnterRail, this);
        this._controller.off(PlinkoController.EVENTS.EXIT_RAIL, this._onExitRail, this);

        this.scene.events.off(EVENTS.LAUNCH_DOWN, this._onLaunchDown, this);
        this.scene.events.off(EVENTS.LAUNCH_UP, this._onLaunchUp, this);

        this._arrowBlinkTween && this._arrowBlinkTween.stop();
        this._launcherTw && this._launcherTw.stop();
        this._launcher && this._launcher.destroy();
        super.destroy(true);
    }
}
