import {EVENTS} from "../utils/events";
import PlaceOnCircle = Phaser.Actions.PlaceOnCircle;
import {WHEEL_VALUES} from "../data/wheelData";
import api from "../api";
import Text = Phaser.GameObjects.Text;
import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import WheelSceneBackground from "../objects/wheelSceneBackground";
import MainScene from "./mainScene";
import ScaleButton from "../objects/ui/scaleButton";
import {WheelRoundConfig} from "../types/api/responseTypes";
import WheelResultContainer from "../objects/wheelResultContainer";
import HIDDEN = Phaser.Core.Events.HIDDEN;
import {setTextGradientT1} from "../utils";

export default class WheelScene extends Phaser.Scene {
    private _wheelContainer: Container;
    private _spinButtonRainbow: Image;
    private _wheelNeon: Image;
    private _innerWheel: Image;
    private _spinning: boolean;
    private _stopSpinTO: Phaser.Time.TimerEvent | null;
    private _resultContainer?: Container;
    private _spinningWheel: Container;
    private _wheelFrame: Image;
    private _spinButton: ScaleButton;
    private _wheelBg: WheelSceneBackground;
    private _wheelRound: WheelRoundConfig;
    private _callToAction: Container;
    private _wheelMessage: Phaser.GameObjects.Text;
    constructor() {
        super({key: 'WheelScene' });

        this._spinning = false;
    }

    create() {
        this._wheelBg = new WheelSceneBackground(this);
        this.add.existing(this._wheelBg);

        this._createWheel();
        this._createSpinButton();
        this._createArrowPointer();
        this._createCallToAction();
        this._createWheelMessage();

        this.handleResize();

        this.show();
    }

    init (data: { wheelRound: WheelRoundConfig }) {
        console.log('WheelScene data', data);
        this._wheelRound = data.wheelRound;
    }

    _createWheel() {
        const wheelContainer = this._wheelContainer = this.add.container(0, this._wheelBg.displayHeight * 0.33);
        const spinningWheel = this._spinningWheel = this.add.container(0, 0);
        const wheel = this.add.image(0, 0, 'wheel');
        wheel.rotation = Math.PI / WHEEL_VALUES.length + Math.PI * 0.5;
        spinningWheel.add(wheel);
        this._createWheelText(spinningWheel, wheel);
        wheelContainer.add(spinningWheel);

        const innerWheel = this._innerWheel = this.add.image(0, 0, 'wheel_inner');
        wheelContainer.add(innerWheel);
        const wheelFrame= this._wheelFrame = this.add.image(0, 0, 'wheel_frame');
        wheelContainer.add(wheelFrame);
        const wheelNeon = this._wheelNeon = this.add.image(0, 0, 'wheel_neon');

        wheelContainer.add(wheelNeon);
    }

    _createWheelText(wheelContainer: Container, wheel: Image) {
        const texts: Text[] = WHEEL_VALUES.map((value, i) => {
            const text = this.add.text(0, 0, `${value}`, {
                fontFamily: 'joystix_monospace',
                fontSize: 90,
                color: '#ffffff',
                align: 'left',
                stroke: '#4BD9D7',
                strokeThickness: 32,
            });
            text.rotation = Math.PI * 2 / WHEEL_VALUES.length * i;
            text.setOrigin(0, 0.5);
            return text;
        });

        wheelContainer.add(texts);
        PlaceOnCircle(texts, new Phaser.Geom.Circle(0, 0, wheel.displayHeight * 0.27), );
    }

    _createArrowPointer() {
        const arrow = this.add.image(0, -this._wheelFrame.displayHeight * 0.41, 'wheel_arrow');
        this._wheelContainer.add(arrow);
    }

    _createSpinButton() {
        const spinButtonContainer= this.add.container(0, this._wheelBg.displayHeight * 0.33);

        const spinButtonShadow = this.add.image(0, 0, 'spin_button_shadow');
        spinButtonContainer.add(spinButtonShadow);

        const spinButtonRainbow = this._spinButtonRainbow = this.add.image(0, 0, 'spin_button_rainbow');
        spinButtonContainer.add(spinButtonRainbow);

        const spinButton = this._spinButton = new ScaleButton(this, {
            x: 0,
            y: 0,
            bgTexture: 'spin_button_bg',
            centerTexture: 'spin_button',
            labelText: 'SPIN',
        });
        spinButton.setInteractive();
        spinButtonContainer.add(spinButton);
        spinButton.on(EVENTS.POINTER_DOWN, () => {
            global.game.sfxController.playSpinButtonSfx();
            spinButton.disableInteractive();
            if (this._spinning) {
                this._stopSpin();
            } else {
                this._spin();
            }

        });

        this._wheelContainer.add(spinButtonContainer);
    }

    _createCallToAction() {
        const spinToWin = this._callToAction = this.add.container();
        const spin = this.add.text(0, 0, 'SPIN', {
            fontFamily: 'arcade_classic',
            fontSize: 240,
            color: '#ffffff',
            align: 'center',
        });
        setTextGradientT1(spin);
        spin.setShadow(5, 5, '#29B7B3', 0, true, true);
        spin.setOrigin(0.5, 0);
        spinToWin.add(spin);

        const to = this.add.text(0, spin.height * 0.85, '-TO-', {
            fontFamily: 'arcade_classic',
            fontSize: 140,
            color: '#ffffff',
            align: 'center',
        });
        to.setShadow(5, 5, '#29B7B3', 0, true, true);
        to.setOrigin(0.5, 0);
        spinToWin.add(to);

        const win = this.add.text(0, to.y + to.height * 0.6, 'WIN', {
            fontFamily: 'arcade_classic',
            fontSize: 240,
            color: '#F5CF32',
            align: 'center',
        });
        win.setShadow(5, 5, '#29B7B3', 0, true, true);
        win.setOrigin(0.5, 0);
        spinToWin.add(win);
        this.add.existing(spinToWin);
    }

    _createWheelMessage() {
        const message = this._wheelMessage = this.add.text(0, 0, 'GOOD\nLUCK', {
            fontFamily: 'arcade_classic',
            fontSize: 240,
            color: '#F5CF32',
            align: 'center',
        });
        message.setLineSpacing(-80);
        setTextGradientT1(message);
        message.setShadow(5, 5, '#29B7B3', 0, true, true);
        message.setOrigin(0.5, 0.5);
        message.setVisible(false);
        this.add.existing(message);
    }

    async _spin() {
        global.game.sfxController.playWheelSpinningSfx();

        this.tweens.add({
            targets: this._callToAction,
            alpha: 0,
            ease: 'Quad.easeOut',
            duration: 400,
            onComplete: () => {
                this._callToAction.setVisible(false);

                this.tweens.add({
                    targets: this._wheelMessage,
                    alpha: {start: 0, to: 1},
                    ease: 'Quad.easeOut',
                    duration: 400,
                });
                this._wheelMessage.setVisible(true);
            }
        });

        this._spinButton.setText('STOP');
        this.tweens.add({
            targets: this._spinningWheel,
            rotation: 0.5 * Math.PI,
            ease: 'Back.easeIn',
            duration: 1000,
            onComplete: () => {
                this._spinning = true;
                this._spinButton.setInteractive();
                this._stopSpinTO = this.time.delayedCall(3500, this._stopSpin, [], this);
            }
        });

        this.tweens.add({
            targets: this._innerWheel,
            rotation: -0.5 * Math.PI,
            ease: 'Back.easeIn',
            duration: 1000,
        });
    }

    async _stopSpin () {
        const spinData = await api().spinWheel(this._wheelRound.id);

        this._spinButton.disableInteractive();

        if (this._stopSpinTO) {
            this._stopSpinTO.remove();
            this._stopSpinTO = null;
        }

        this._spinning = false;

        const randomizeStopRotation = Phaser.Math.FloatBetween(-0.2, 0.2);

        this.tweens.add({
            targets: this._spinningWheel,
            rotation: 2 * Math.PI + spinData.rotation + randomizeStopRotation,
            ease: 'Quart.easeOut',
            duration: 4000,
            onComplete: () => {
                this._spinningWheel.list.forEach((child) => {
                    if (child instanceof Text) {
                        console.log(child.text, spinData.value);
                        if (child.text === `${spinData.value}`) {
                            const color = new Phaser.Display.Color();
                            color.setFromHSV(0, 1, 1);
                            this.tweens.add({
                                targets: color,
                                h: 359,
                                ease: 'Linear.none',
                                duration: 2000,
                                onUpdate: () => {
                                    child.setStroke(color.rgba, 32);
                                }
                            });
                        }
                    }
                })

                global.game.sfxController.playWheelWinSfx();

                this.time.delayedCall(2000, () => {
                    this._showResult(spinData.value);
                });
            }
        });

        this.tweens.add({
            targets: this._innerWheel,
            rotation: -2 * Math.PI - spinData.rotation - randomizeStopRotation,
            ease: 'Quart.easeOut',
            duration: 4000,
        })
    }

    _showResult(value: number) {
        const resultContainer = this._resultContainer = new WheelResultContainer(this, value);
        this.add.existing(resultContainer);
        resultContainer.on(HIDDEN, this.hide, this);

        //this.children.bringToTop(this._wheelContainer);

        this.handleResize();

        global.game.sfxController.playWheelFadeOutSfx();

        this.tweens.add({
            targets: this._wheelMessage,
            alpha: 0,
            ease: 'Quad.easeOut',
            duration: 400,
            onComplete: () => {
                this._wheelMessage.setVisible(false);
            }
        });

        this._wheelBg.hideGlow();

        this.tweens.add({
            targets: this._wheelContainer,
            alpha: 0,
            y: this._wheelBg.y + this._wheelBg.bgHeight,
            ease: 'Back.easeIn',
            duration: 600,
            onComplete: () => {
                resultContainer.show();
            }
        });
    }

    _animate () {
        this._wheelBg.playEffect()

        this.tweens.add({
            targets: this._spinButtonRainbow,
            rotation: Math.PI * 2,
            ease: 'Linear',
            duration: 6000,
            repeat: -1,
        });

        this.tweens.add({
            targets: this._wheelNeon,
            rotation: -Math.PI * 2,
            ease: 'Linear',
            duration: 12000,
            repeat: -1,
        });
    }

    show() {
        this._wheelBg.alpha = 0;
        this.tweens.add({
            targets: this._wheelBg,
            alpha: 1,
            ease: 'Quadratic.easeIn',
            duration: 400,
        });
        this._wheelContainer.alpha = 0;
        this._wheelContainer.y = this._wheelBg.y + this._wheelBg.bgHeight * 0.6;
        this.tweens.add({
            targets: this._wheelContainer,
            y: this._wheelBg.y + this._wheelBg.bgHeight * 0.33,
            alpha: 1,
            ease: 'Back.easeOut',
            duration: 800,
            delay: 200,
        });

        global.game.sfxController.playWheelFadeInSfx();

        this._animate();
    }

    hide() {
        if (this._resultContainer) {
            this._resultContainer.destroy(true);
            this._resultContainer = undefined;
        }

        this.tweens.add({
            targets: this._wheelBg,
            alpha: 0,
            ease: 'Quadratic.easeIn',
            duration: 400,
            delay: 200,
            onComplete: () => {
                this._wheelBg.stop();
                this.scene.stop();

                this.scene.add('MainScene', MainScene);
                this.scene.start('MainScene');
            }
        });
    }

    update(time: number, delta: number) {
        super.update(delta, time);

        if (this._spinning) {
            this._spinningWheel.rotation += 0.008 * delta;
            this._innerWheel.rotation -= 0.008 * delta;
        }
    }
    handleResize() {
        const gameSize = this.scale.gameSize;

        if (this._wheelBg) {
            this._wheelBg.setScale(1);
            this._wheelBg.setScale(gameSize.height / this._wheelBg.bgHeight);
            this._wheelBg.x = gameSize.width * 0.5;
            this._wheelBg.y = gameSize.height * 0.5;
            this.registry.set('wheelSceneScaleFactor', this._wheelBg.scale);
        }

        if (this._wheelContainer) {
            this._wheelContainer.setScale(this.registry.get('wheelSceneScaleFactor'));
            this._wheelContainer.x = this._wheelBg.x;
            this._wheelContainer.y = this._wheelBg.y + this._wheelBg.bgHeight * 0.33;
        }

        if (this._resultContainer) {
            this._resultContainer.setScale(this.registry.get('wheelSceneScaleFactor'));
            this._resultContainer.x = this._wheelBg.x;
            this._resultContainer.y = this._wheelBg.y;
        }

        if (this._callToAction) {
            this._callToAction.setScale(this.registry.get('wheelSceneScaleFactor'));
            this._callToAction.x = this._wheelBg.x;
            this._callToAction.y = this._wheelBg.y - this._wheelBg.bgHeight * 0.48;

            if (this._wheelMessage) {
                this._wheelMessage.setScale(this.registry.get('wheelSceneScaleFactor'));
                this._wheelMessage.x = this._wheelBg.x;
                this._wheelMessage.y = this._wheelBg.y - this._wheelBg.bgHeight * 0.35;
            }
        }
    }
}
