export function createWheelBackgroundFxAnimation (postFx: Phaser.FX.Bloom) {
    return {
        repeat: -1,
        tweens:[
            {
                delay: 1000,
                targets: postFx,
                blurStrength: 1.2,
                ease: 'Quad.easeIn',
                duration: 400,
            },{
                targets: postFx,
                blurStrength: 1,
                ease: 'Quad.easeOut',
                duration: 200,
            },{
                targets: postFx,
                blurStrength: 1.2,
                ease: 'Quad.easeIn',
                duration: 400,
            },{
                targets: postFx,
                blurStrength: 1,
                ease: 'Quad.easeOut',
                duration: 200,
            },{
                targets: postFx,
                blurStrength: 1.5,
                ease: 'Quad.easeIn',
                duration: 800,
            },{
                targets: postFx,
                blurStrength: 1,
                ease: 'Quad.easeOut',
                duration: 800,
            },{
                targets: postFx,
                blurStrength: 0.6,
                ease: 'Quad.easeOut',
                duration: 400,
            },{
                targets: postFx,
                blurStrength: 1,
                ease: 'Quad.easeOut',
                duration: 400,
            },{
                targets: postFx,
                blurStrength: 0.4,
                ease: 'Quad.easeOut',
                duration: 400,
            },{
                targets: postFx,
                blurStrength: 1,
                ease: 'Quad.easeOut',
                duration: 400,
            }
        ]
    }
}


export function createBorderGlowAnimation (borderGlowLeft: Phaser.GameObjects.Image, borderGlowRight: Phaser.GameObjects.Image) {
    return {
        tweens: [
            {
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 0,
                ease: 'Quad.easeInOut',
                duration: 100,
            },{
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 1,
                ease: 'Quad.easeInOut',
                duration: 100,
            },{
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 0,
                ease: 'Quad.easeInOut',
                duration: 100,
            },{
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 1,
                ease: 'Quad.easeInOut',
                duration: 100,
            },{
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 0,
                ease: 'Quad.easeInOut',
                duration: 200,
            },{
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 1,
                ease: 'Quad.easeInOut',
                duration: 200,
            },{
                targets: [borderGlowLeft, borderGlowRight],
                alpha: 0,
                ease: 'Quad.easeInOut',
                duration: 500,
            },
        ],
            loop: -1,
        loopDelay: 1000,
    }
}
