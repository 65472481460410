import {fadeIn, fadeOut} from "./fade";
import {EVENTS} from "../../utils/events";

const MUSIC_VOLUME = 0.3;

export default class MusicController {
    private readonly _normalRoundMusic: Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private readonly _specialRoundMusic: Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private readonly scene: Phaser.Scene;
    constructor(scene: Phaser.Scene) {
        this.scene = scene;
        this._normalRoundMusic = scene.sound.add('music', { loop: true }) as Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
        this._specialRoundMusic = scene.sound.add('music_special', { loop: true }) as Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
        this.scene.registry.events.on(EVENTS.CHANGED_DATA_MUSIC_MUTE, this._onMuteChange, this);
    }

    async playNormalRoundMusic(scene: Phaser.Scene) {
        console.log('playNormalRoundMusic');
        if (this._normalRoundMusic.isPlaying) {
            return Promise.resolve(this._normalRoundMusic);
        }
        this._normalRoundMusic.play();
        this._normalRoundMusic.setVolume(0);
        return fadeIn(scene, this._normalRoundMusic, MUSIC_VOLUME, 400);
    }

    async stopNormalRoundMusic(scene: Phaser.Scene) {
        const promise = fadeOut(scene, this._normalRoundMusic, 400)
        promise.then(
            () => this._normalRoundMusic.stop()
        )
        return promise;
    }

    async playSpecialRoundMusic(scene: Phaser.Scene) {
        console.log('playSpecialRoundMusic');
        if (this._specialRoundMusic.isPlaying) {
            return Promise.resolve(this._specialRoundMusic);
        }
        this._specialRoundMusic.play();
        this._specialRoundMusic.setVolume(0);
        return fadeIn(scene, this._specialRoundMusic, MUSIC_VOLUME, 400);
    }

    async stopSpecialRoundMusic(scene: Phaser.Scene) {
        const promise = fadeOut(scene, this._specialRoundMusic, 400)
        promise.then(
            () => this._specialRoundMusic.stop()
        )
        return promise;
    }

    private _onMuteChange() {
        const isMuted = this.scene.registry.get('musicMute');
        this._normalRoundMusic.setMute(isMuted);
        this._specialRoundMusic.setMute(isMuted);
    }
}
