import {EVENTS} from "../../utils/events";
import Phaser from "phaser";

export default class ScaleButton extends Phaser.GameObjects.Container {
    readonly _centerButton: Phaser.GameObjects.Image;
    readonly _label: Phaser.GameObjects.Text;
    constructor(scene: Phaser.Scene, {x, y, bgTexture, centerTexture, labelText}: {x: number, y: number, bgTexture: string, centerTexture: string, labelText: string}) {
        super(scene);

        if (bgTexture) {
            const buttonBg = this.scene.add.image(0, 0, bgTexture);
            this.add(buttonBg);
        }

        const centerButton = this._centerButton = this.scene.add.image(0, 0, centerTexture);
        centerButton.setInteractive();
        this.add(centerButton);
        centerButton.on(EVENTS.POINTER_DOWN, this._onPointerDown, this);

        if (labelText) {
            const label = this._label = this.scene.add.text(0, 0, labelText, {
                fontFamily: 'joystix_monospace',
                fontSize: 60,
                color: '#ffffff',
                align: 'center',
                stroke: '#4BD9D7',
                strokeThickness: 16,
            });
            label.setOrigin(0.5);
            this.add(label);
        }

        this.x = x;
        this.y = y;
    }

    _onPointerDown() {
        this._centerButton.setScale(1);
        this._centerButton.setScale(1);
        this.scene.tweens.add({
            targets: [this._label, this._centerButton].filter((t) => !!t),
            scale: 0.9,
            ease: 'Quad.easeOut',
            duration: 100,
        });
        global.game.sfxController.playGeneralButtonSfx();
        this.emit(EVENTS.POINTER_DOWN);
        this.scene.input.once(EVENTS.POINTER_UP, this._onPointerUp, this);
    }

    _onPointerUp() {
        this.scene.tweens.add({
            targets: [this._label, this._centerButton].filter((t) => !!t),
            scale: 1,
            ease: 'Quad.easeIn',
            duration: 100,
        });
        this.emit(EVENTS.POINTER_UP);
    }

    setText(text: string) {
        this._label.setText(text);
    }

    setInteractive(hitArea?: any, callback?: Phaser.Types.Input.HitAreaCallback, dropZone?: boolean): this {
        this._centerButton.setInteractive(hitArea, callback, dropZone);
        return super.setInteractive(hitArea, callback, dropZone);
    }

    disableInteractive(): this {
        this._centerButton.disableInteractive();
        return super.disableInteractive();
    }
}
