import {SpineGameObject, TrackEntry} from "@esotericsoftware/spine-phaser";
import Container = Phaser.GameObjects.Container;
import {Scene} from "phaser";
import {BonusRoundConfig} from "../types/api/responseTypes";
import EventEmitter = Phaser.Events.EventEmitter;
import {EVENTS} from "../utils/events";
import {setTextGradientT1} from "../utils";
import Text = Phaser.GameObjects.Text;

const ballAnimations = [
    'win/ball_win-blue',
    'win/ball_win-blue-green',
    'win/ball_win-blue-yellow',
    'win/ball_win-pink',
];

const plateAnimations = [
    'plates/ball_win_plate_x0',
    'plates/ball_win_plate_x2',
    'plates/ball_win_plate_x5',
    'plates/ball_win_plate_x10',
    'plates/ball_win_plate_x50',
    'plates/ball_win_plate_x100',
];

const values = [
    {id: 'x0', line1: 'X0', line2: 'ZERO BOOSTER'},
    {id: 'x2', line1: 'X2', line2: 'POWER UP'},
    {id: 'x5', line1: 'X5', line2: 'TURBO BOOST'},
    {id: 'x10', line1: 'X10', line2: 'TOTAL RUSH'},
    {id: 'x50', line1: 'X50', line2: 'MEGA CHARGE'},
    {id: 'x100', line1: 'X100', line2: 'VOLATILE AF'},
];

export default class BallRandomizer extends Container {
    private _randomizerMachine: SpineGameObject;
    private _winText: Container;
    public events: Phaser.Events.EventEmitter;
    constructor(scene: Scene) {
        super(scene);

        this.events = new EventEmitter();

        this._create();
    }

    private _create() {
        const randomizerMachine = this._randomizerMachine = this.scene.make.spine({
            y: 0, x: 0,
            dataKey: `randomizer_data`, atlasKey: `randomizer_atlas`
        });
        this.add(randomizerMachine);
        this.setSize(randomizerMachine.skeleton.getBoundsRect().width, randomizerMachine.skeleton.getBoundsRect().height);

        randomizerMachine.animationState.data.setMix( 'balls/balls_spin_start', 'balls/balls_spin', 0.3 );
        randomizerMachine.animationState.data.setMix( 'balls/balls_spin', 'balls/balls_spin_stop', 0.3 );

        ballAnimations.forEach((animation) => {
            randomizerMachine.animationState.data.setMix( 'balls/balls_spin', animation, 0.1 );
        });

        /*plateAnimations.forEach((animation) => {
            randomizerMachine.animationState.data.setMix( 'lamps/balls_spin_plates', animation, 0.1 );
        });*/

        const track = this._randomizerMachine.animationState.setAnimation(0, 'balls/balls_spin_start', false);
        track.timeScale = 0;
    }

    private _createWinText() {
        const winText = this._winText = this.scene.add.container(0, 0);

        const line1 = this.scene.add.text(0, 30, 'EXAMPLE', {
            fontFamily: 'passionone_bold',
            fontSize: 300,
            color: '#000000',
            align: 'center',
        });
        line1.setOrigin(0.5, 1);
        const line2 = this.scene.add.text(0, 0, 'TEXT', {
            fontFamily: 'passionone_bold',
            fontSize: 200,
            color: '#000000',
            align: 'center',
        });
        line2.setOrigin(0.5, 0);

        winText.add(line1);
        winText.add(line2);
        winText.setVisible(false);
        this.add(winText);
    }

    public spin(bonusRound: BonusRoundConfig) {
        this._randomizerMachine.animationState.setAnimation(0, 'balls/balls_spin_start', false);
        this._randomizerMachine.animationState.addAnimation( 0, 'balls/balls_spin', true );

        this._randomizerMachine.animationState.setAnimation(1, 'lamps/balls_spin_lamps', true);
        this._randomizerMachine.animationState.setAnimation(2, 'lamps/balls_spin_plates', true);

        global.game.sfxController.playBonusGetBalls();

        this.scene.time.delayedCall(5400, () => {
            this.stop(bonusRound);
        });
    }

    public stop(bonusRound: BonusRoundConfig) {
        const winTrack = this._randomizerMachine.animationState.setAnimation(2, `plates/ball_win_plate_${bonusRound.type}`, true);
        winTrack.timeScale = 0.5;

        this._randomizerMachine.animationState.setAnimation(3, Phaser.Utils.Array.GetRandom(ballAnimations), false);

        this.scene.time.delayedCall(5500, () => {
            this._displayWinMessage(bonusRound);
        });
    }

    private _displayWinMessage(bonusRound: BonusRoundConfig) {
        console.log('display win message');
        this._createWinText();

        this._winText.x = this.width * 0.52;
        this._winText.y = this.height * 0.41;
        this._winText.angle = -11;
        (this._winText.list[0] as Text).setText(values.find((value) => value.id === bonusRound.type)?.line1 || '');
        (this._winText.list[1] as Text).setText(values.find((value) => value.id === bonusRound.type)?.line2 || '');

        this.scene.tweens.add({
            targets: this._winText,
            angle: {start: 180, to: this._winText.angle},
            scale: {start: 3, to: 1},
            alpha: {start: 0, to: 1},
            duration: 400,
            ease: 'Quad.easeIn',
        });

        this._winText.setVisible(true);

        this.scene.time.delayedCall(600, () => {
            this.events.emit(EVENTS.RANDOMIZER_COMPLETE);
        });
    }

    reset() {
        this._winText?.destroy();
        this._randomizerMachine.animationState.clearTracks();
        this._randomizerMachine.skeleton.setToSetupPose();
        const track = this._randomizerMachine.animationState.setAnimation(0, 'balls/balls_spin_start', false);
        track.timeScale = 0;
    }
}
