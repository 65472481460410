import Tabs from "../tabs";
import {Skin} from "../../types/skin";

export function createTabs (scene: Phaser.Scene, x: number, y: number, skin: Skin, configs: { id: number, value: string }[],) {
    const tabs = new Tabs(scene, skin, configs);
    tabs.x = x;
    tabs.y = y;
    tabs.setData('isTab', true);
    return tabs;
}
