import NormalBgParticles from "./normalBgParticles";
import {EVENTS} from "../../../utils/events";
import {createBackgroundFxBonusAnimation} from "../../tweens/bonusAnimations";
import {createBackgroundFxAnimation} from "../../tweens/backgroundFxAnimation";
import {fadeInOut} from "../../tweens";
import defaultSkin from "../../../skins/default";
import {Skin, SkinItem, SkinItems} from "../../../types/skin";

export default class NormalBackground extends Phaser.GameObjects.Container {
    private _bgLayer1: Phaser.GameObjects.Image;
    private _glowPostFx: Phaser.FX.Bloom;
    private _glowPostFxTween: Phaser.Tweens.TweenChain;
    private _bgGlow: Phaser.GameObjects.Image;
    private _lightAnimation: Phaser.Tweens.TweenChain;
    private _bonusPostFxTween: Phaser.Tweens.TweenChain;
    private _lightAnimationChain: {
        duration: number;
        ease: string;
        delay: number;
        yoyo: boolean;
        alpha: number;
        repeat: number;
        onComplete: (() => void) | undefined;
        targets: any
    }[];
    private _skin: Skin;

    constructor(scene: Phaser.Scene) {
        super(scene);

        this._skin = defaultSkin;

        this._create();

        this.scene.events.on(EVENTS.BONUS_ANIMATION_STARTED, this._onShowBonusAnimations, this);
        this.scene.events.on(EVENTS.BONUS_ANIMATION_STOPPED, this._stopBonusAnimations, this);
    }

    private _create() {
        const bgLayer1Skin = this._skin.backgroundLayer1 as SkinItem;
        const bgLayer1 = this._bgLayer1 = this.scene.add.image(bgLayer1Skin.position.x, bgLayer1Skin.position.y, bgLayer1Skin.key);
        bgLayer1.setOrigin(bgLayer1Skin.origin.x, bgLayer1Skin.origin.y);
        this.add(bgLayer1);

        this._createBgEffect();

        const bgParticlesSkin = this._skin.backgroundParticles as SkinItem;
        if (bgParticlesSkin) this._createParticleEffect(bgParticlesSkin);

        const bgLayer2Skin = this._skin.backgroundLayer2 as SkinItem;
        const bgLayer2 = this.scene.add.image(bgLayer2Skin.position.x, bgLayer2Skin.position.y,  bgLayer2Skin.key);
        bgLayer2.setOrigin(bgLayer2Skin.origin.x, bgLayer2Skin.origin.y);
        this.add(bgLayer2);

        const pinsBgSkin = this._skin.pinsBackground as SkinItem;
        const pinsBackground = this.scene.add.image(pinsBgSkin.position.x, pinsBgSkin.position.y, pinsBgSkin.key);
        pinsBackground.setOrigin(pinsBgSkin.origin.x, pinsBgSkin.origin.y);
        this.add(pinsBackground);

        const bgLayer4Skin = this._skin.backgroundLayer4 as SkinItem;
        const bgLayer4 = this.scene.add.image(bgLayer4Skin.position.x,  bgLayer4Skin.position.y, bgLayer4Skin.key);
        bgLayer4.setOrigin(bgLayer4Skin.origin.x, bgLayer4Skin.origin.y);
        this.add(bgLayer4);

        this._createAnimations();
    }

    private _createParticleEffect(bgParticles: SkinItem) {
        new NormalBgParticles(this.scene, this, this._bgLayer1, bgParticles);
    }

    private _createAnimations() {
        this._lightAnimationChain = (this._skin.boardLights as SkinItems).map((item, i) => {
            const light = this.scene.add.image(item.position.x, item.position.y, item.key);
            light.setOrigin(item.origin.x, item.origin.y);
            light.blendMode = Phaser.BlendModes.ADD;
            light.setAlpha(0);
            this.add(light);

            return fadeInOut(this.scene, light, 200, 200, i * 100, 500);
        });

        this._lightAnimation = this._createLightAnimation();
        this._lightAnimation.pause();
    }

    private _createLightAnimation() {
        return this.scene.tweens.chain({
            tweens: this._lightAnimationChain,
            loop: -1,
            loopDelay: 1000,
        })
    }

    public get bgWidth(): number {
        return this._bgLayer1.displayWidth * this.scaleX;
    }

    public get bgHeight(): number {
        return this._bgLayer1.displayHeight * this.scaleY;
    }

    private _createBgEffect() {
        /*const shader= this._shader = this.scene.add.shader('HSL', 0, this._bgLayer1.displayHeight * 0.15, this._bgLayer1.displayWidth * 1.2, this._bgLayer1.displayWidth * 1.2)
        this.add(shader);*/
        const boardGlowSkin = this._skin.boardGlow as SkinItem;
        this._bgGlow = this.scene.add.image(boardGlowSkin.position.x, boardGlowSkin.position.y, boardGlowSkin.key);
        this._bgGlow.setOrigin(boardGlowSkin.origin.x, boardGlowSkin.origin.y);
        this._bgGlow.setScale(0.95);
        this._glowPostFx = this._bgGlow.postFX.addBloom(boardGlowSkin.data!.glowColor, 1, 1, 1, 1);
        this.add(this._bgGlow);

        this._playBgEffect();
    }

    public playBonusLightAnimation() {
        this._lightAnimation.setTimeScale(1);
        this._lightAnimation.play();
    }

    public stopBonusLightAnimation() {
        this._lightAnimation.pause();
    }

    _playBgEffect() {
        this._glowPostFxTween = this.scene.tweens.chain(createBackgroundFxAnimation(this._glowPostFx));
    }

    _onShowBonusAnimations() {
        this._glowPostFxTween.stop();
        this._bonusPostFxTween = this.scene.tweens.chain(createBackgroundFxBonusAnimation(this._glowPostFx));
        this._lightAnimation.setTimeScale(6);
        this._lightAnimation.play();
    }

    _stopBonusAnimations() {
        if (this._lightAnimation) this._lightAnimation.setTimeScale(1);
        if (this._bonusPostFxTween) this._bonusPostFxTween.stop();
        this._playBgEffect();
    }

    public resize(aspectRatio: number, gameSize: {width: number, height: number}) {
        this.setScale(1);
        this.setScale(gameSize.height * (aspectRatio < 0.56 ? 0.76 : 0.83) / this.bgHeight);
        this.x = gameSize.width * 0.5;
        this.y = gameSize.height - this.bgHeight * 0.5;
        this.scene.registry.set('scaleFactor', this.scale);
    }

    public destroy() {
        this.scene.events.off(EVENTS.BONUS_ANIMATION_STARTED, this._onShowBonusAnimations, this);
        this.scene.events.off(EVENTS.BONUS_ANIMATION_STOPPED, this._stopBonusAnimations, this);
        super.destroy();
    }
}
