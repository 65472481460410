import {EVENTS} from "../../utils/events";

const SFX_VOLUME = 0.5;

export default class SFXController {
    private readonly scene: Phaser.Scene;
    private _isMuted: boolean = false;
    private _ballInTubeSound: null | Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private _ballInTubeSoundActivations: number;
    private _wheelSpinSound: null | Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private readonly _ambientSound: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private _smallWheelSpinSound: null | Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;

    constructor(scene: Phaser.Scene) {
        this.scene = scene;
        this._ambientSound = scene.sound.add('ambient', { loop: true });
        this.scene.registry.events.on(EVENTS.CHANGED_DATA_SFX_MUTE, this._onMuteChange, this);
    }

    public playBumperSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`bumper_${1 + Math.floor(Math.random() * 2)}`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playTabSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`tab`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playAmbientSound() {
        if (this._ambientSound) {
            this._ambientSound.play();
            this._ambientSound.setVolume(SFX_VOLUME);
        }
    }


    public playSpecialPinSfx(index: number) {
        if (this._isMuted) return null;
        if (index > 3) {
            console.error('Special pin sound index out of range');
            return;
        }
        const sound = this.scene.sound.add(`bumper_special_${index}`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playDingSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`peg_${1 + Math.floor(Math.random() * 7)}`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playEndSlotEmptySfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`end_slot_empty`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playEndSlotSfx(color: string) {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`end_slot_${color}`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playLaunchSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`launch`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playBallEnterTube () {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`ball_enter_tube`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playBallExitTube () {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`ball_exit_tube`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playBallInTube () {
        if (this._isMuted) return null;
        if (this._ballInTubeSound && this._ballInTubeSound.isPlaying) {
            this._ballInTubeSoundActivations++;
            return this._ballInTubeSound;
        }
        const sound = this._ballInTubeSound = this.scene.sound.add(`ball_in_tube`, {loop: true});
        sound.setVolume(SFX_VOLUME);
        sound.play();
        this._ballInTubeSoundActivations = 1;
        return sound;
    }

    public stopBallInTube () {
        if (this._ballInTubeSound) {
            this._ballInTubeSoundActivations--;

            if (this._ballInTubeSoundActivations <= 0) {
                this._ballInTubeSound.stop();
                this._ballInTubeSound.destroy();
                this._ballInTubeSound = null;
            }
        }
    }

    public playGeneralButtonSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`general_button`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playWheelTotalWinSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`wheel_total_win`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playWheelWinSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`wheel_win`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playWheelFadeInSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`wheel_fade_in`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playWheelFadeOutSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`wheel_fade_out`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playSpinButtonSfx() {
        if (this._isMuted) return null;
        const sound = this.scene.sound.add(`spin_button`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        sound.once('complete', () => sound.destroy());
        return sound;
    }

    public playSmallWheelSpinningSfx() {
        if (this._isMuted) return null;
        if (this._smallWheelSpinSound && this._smallWheelSpinSound.isPlaying) {
            this._smallWheelSpinSound.stop();
        }
        const sound = this._smallWheelSpinSound = this.scene.sound.add(`letter_wheel_spinning`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        return sound;
    }

    public playWheelSpinningSfx() {
        if (this._isMuted) return null;
        const sound = this._wheelSpinSound = this.scene.sound.add(`wheel_spinning`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        return sound;
    }

    public playBonusGetBalls() {
        if (this._isMuted) return null;
        const sound = this._wheelSpinSound = this.scene.sound.add(`get_balls`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        return sound;
    }


    public playBonusLights() {
        if (this._isMuted) return null;
        const sound = this._wheelSpinSound = this.scene.sound.add(`bonus_lights`);
        sound.setVolume(SFX_VOLUME);
        sound.play();
        return sound;
    }

    private _onMuteChange() {
        this._isMuted = this.scene.registry.get('sfxMute');
        this._ambientSound.setMute(this._isMuted);
    }
}
