import Phaser from "phaser";
import {EVENTS} from "../../utils/events";
import ScaleButton from "./scaleButton";
import ScaleManager = Phaser.Scale.ScaleManager;
import ToggleButton from "./toggleButton";
import api from "../../api";

export default class HUD extends Phaser.GameObjects.Container {
    private _tapButton: ScaleButton;
    private _autoplayButton: ToggleButton;
    private _autoplayEnabled: boolean;
    private _settingsButton: ScaleButton;
    private _balanceText: Phaser.GameObjects.Text;
    constructor(scene: Phaser.Scene) {
        super(scene);

        this._autoplayEnabled = false;

        this._create();
    }

    private _create() {
        this._createTapButton();
        this._createAutoplayButton();
        this._createSettingsButton();
        this._createBalanceText()
    }

    _createTapButton() {
        this._tapButton = new ScaleButton(this.scene, {
            x: 0,
            y: 0,
            bgTexture: 'tap_button_bg',
            centerTexture: 'spin_button',
            labelText: 'TAP',
        });
        this._tapButton.setScale(this.scene.registry.get('scaleFactor'));
        this.add(this._tapButton);

        this._tapButton.on(EVENTS.POINTER_UP, () => {
            if (this._tapButton.visible && !this._autoplayEnabled) this.scene.events.emit(EVENTS.LAUNCH_UP);
        });

        this._tapButton.on(EVENTS.POINTER_DOWN, () => {
            if (this._tapButton.visible && !this._autoplayEnabled) this.scene.events.emit(EVENTS.LAUNCH_DOWN);
        });

        this.scene.input.on(EVENTS.POINTER_UP, () => {
            if (!this._tapButton.visible && !this._autoplayEnabled) this.scene.events.emit(EVENTS.LAUNCH_UP);
        });

        this.scene.input.on(EVENTS.POINTER_DOWN, () => {
            if (!this._tapButton.visible && !this._autoplayEnabled) this.scene.events.emit(EVENTS.LAUNCH_DOWN);
        });

        if (this.scene.input.keyboard) {
            const spaceKeyObj = this.scene.input.keyboard.addKey('SPACE');
            spaceKeyObj.on('down', () => {
                if (!this._autoplayEnabled) this.scene.events.emit(EVENTS.LAUNCH_DOWN);
            });

            spaceKeyObj.on('up', () => {
                if (!this._autoplayEnabled) this.scene.events.emit(EVENTS.LAUNCH_UP);
            });
        }

    }

    _createAutoplayButton() {
        const autoplayButton = this._autoplayButton = new ToggleButton(this.scene, 0, 0, 'autoplay_button', 'autoplay_stop_button', async (toggled: boolean) => {
            if (toggled) {
                await api().openAutoplay();
            } else {
                global.game.stopAutoplay();
            }
        });
        this.add(autoplayButton);
    }

   toggleAutoplayButtonState(enabled: boolean) {
        this._autoplayButton.setEnabledState(enabled);
    }

    public setAutoplay(value: boolean, autoplayBalance?: number) {
        const parentSize = this.scene.scale.parentSize;
        if (autoplayBalance) this.setAutoplayBalance(autoplayBalance);
        this._autoplayEnabled = value;
        this._balanceText.visible = value;
        this._tapButton.visible = parentSize.width > parentSize.height && !value;
        this._autoplayButton.setToggled(value);
    }

    public setAutoplayBalance(autoplayBalance: number) {
        this._balanceText.setText(`Autoplay\n${autoplayBalance}`);
    }

    _createSettingsButton() {
        const settingsButton = this._settingsButton = new ScaleButton(this.scene, {
            x: 0,
            y: 0,
            bgTexture: '',
            centerTexture: 'settings_button',
            labelText: '',
        });
        settingsButton.setScale(this.scene.registry.get('scaleFactor'));
        this.add(settingsButton);

        settingsButton.on(EVENTS.POINTER_UP, async () => {
            this.scene.events.emit(EVENTS.SHOW_SETTINGS);
            await api().openSettings();
        });
        this.add(settingsButton);
    }

    _createBalanceText() {
        const balanceText = this._balanceText = this.scene.add.text(0, 0, '0', {
            fontFamily: 'joystix_monospace',
            fontSize: 18,
            color: '#ffffff',
            align: 'center',
            stroke: '#4BD9D7',
            strokeThickness: 4,
        });
        balanceText.setOrigin(0.5);
        balanceText.visible = false;
        this.add(balanceText);
    }

    resize(scale: ScaleManager) {
        //this._tapButton.setScale(0.12);
        this._tapButton.setScale(this.scene.registry.get('scaleFactor') * 0.6);
        this._autoplayButton.setScale(this.scene.registry.get('scaleFactor') * (scale.parentSize.width > scale.parentSize.height ? 2 : 2));
        this._settingsButton.setScale(this.scene.registry.get('scaleFactor') * (scale.parentSize.width > scale.parentSize.height ? 2 : 2));


        if (scale.canvasBounds.x > 0) {
            this._tapButton.x = scale.gameSize.width - 150
            this._tapButton.y = scale.gameSize.height - 80;
        } else {
            this._tapButton.x = scale.canvasBounds.x * scale.displayScale.x + scale.canvasBounds.width * scale.displayScale.x - 150
            this._tapButton.y = scale.canvasBounds.y * scale.displayScale.y + scale.canvasBounds.height * scale.displayScale.y - 80;
        }

        if (scale.parentSize.width > scale.parentSize.height) {
            this._tapButton.setVisible(!this._autoplayEnabled);
        } else {
            this._tapButton.setVisible(false);
        }

        if (scale.parentSize.width > scale.parentSize.height) {
            this._autoplayButton.x = this._tapButton.x + 100;
            this._autoplayButton.y = this._tapButton.y;

            this._balanceText.x = this._tapButton.x;
            this._balanceText.y = this._tapButton.y;

            this._settingsButton.x = this._tapButton.x - 100;
            this._settingsButton.y = this._tapButton.y;
        } else {
            this._balanceText.x = scale.gameSize.width * 0.5;
            this._balanceText.y = scale.canvasBounds.y * scale.displayScale.y + scale.canvasBounds.height * scale.displayScale.y - 120;

            this._autoplayButton.x = scale.canvasBounds.x * scale.displayScale.x + scale.canvasBounds.width * scale.displayScale.x - 40;
            this._autoplayButton.y = scale.canvasBounds.y * scale.displayScale.y + scale.canvasBounds.height * scale.displayScale.y - 120;

            this._settingsButton.x = -scale.canvasBounds.x * scale.displayScale.x + 40;
            this._settingsButton.y = scale.canvasBounds.y * scale.displayScale.y + scale.canvasBounds.height * scale.displayScale.y - 120;
        }
    }
}
