import defaultSkin from '../skins/default';
import bonusSkin from '../skins/bonus';
import {SkinItem} from "../types/skin";
import MusicController from "../objects/audio/musicController";
import SFXController from "../objects/audio/sfxController";

export default class PreloadScene extends Phaser.Scene {
  private _loadingContainer: HTMLElement | null;
  private _progressBar: HTMLElement | null;
  constructor() {
    super({ key: 'PreloadScene' });


    this._progressBar = document.getElementById('loading-container');
    this._loadingContainer = document.getElementById('progress-fill');
  }

  preload() {
    if (process.env.ASSET_PATH) {
      this.load.baseURL = process.env.ASSET_PATH;
      this.load.crossOrigin = 'anonymous';
    }

    this._preloadAudio();

    this._loadPopupAssets();

    this._loadWheelAssets();

    this._loadWinAnimationAssets();

    this._loadHudAssets();

    this._preloadDefaultSkin();

    this._preloadBonusSkin();

    this.load.image('special_pin', 'assets/img/special_pin.png');
    this.load.image('special_pin_glow_red', 'assets/img/special_pin_glow_red.png');

    this.load.image('ball_shadow', 'assets/img/ball_shadow.png');

    this.load.image('top_board', 'assets/img/top_board.png');
    this.load.image('top_board_glow', 'assets/img/top_board_glow.png');

    this.load.image('yellow_light', 'assets/img/yellow_light.png');
    this.load.image('white_light', 'assets/img/white_light.png');

    this.load.image('smoke', 'assets/img/smoke.png');

    this.load.on('progress', this.loadUpdate, this);
  }

  _preloadAudio() {
    this.load.audio('music', ['assets/audio/ClassicModeTheme.m4a', 'assets/audio/ClassicModeTheme.ogg']);
    this.load.audio('music_special', ['assets/audio/SpecialRoundTheme.m4a', 'assets/audio/SpecialRoundTheme.ogg']);
    this.load.audio('ambient', ['assets/audio/ambient.mp3']);

    this.load.audio('launch', 'assets/audio/ball_released.mp3');
    this.load.audio('tab', 'assets/audio/hit_wheel_letters.mp3');
    this.load.audio('tab_finish', 'assets/audio/wheel_letters_fall_down.mp3');
    this.load.audio('wheel_total_win', 'assets/audio/wheel_total_win.mp3');
    this.load.audio('wheel_win', 'assets/audio/wheel_win.mp3');
    this.load.audio('letter_wheel_spinning', 'assets/audio/letter_wheel_spinning.mp3');
    this.load.audio('wheel_spinning', 'assets/audio/wheel_spinning.mp3');
    this.load.audio('wheel_fade_in', 'assets/audio/wheel_fade_in.mp3');
    this.load.audio('wheel_fade_out', 'assets/audio/wheel_fade_out.mp3');

    this.load.audio('peg_1', 'assets/audio/peg_1.mp3');
    this.load.audio('peg_2', 'assets/audio/peg_2.mp3');
    this.load.audio('peg_3', 'assets/audio/peg_3.mp3');
    this.load.audio('peg_4', 'assets/audio/peg_4.mp3');
    this.load.audio('peg_5', 'assets/audio/peg_5.mp3');
    this.load.audio('peg_6', 'assets/audio/peg_6.mp3');
    this.load.audio('peg_7', 'assets/audio/peg_7.mp3');

    this.load.audio('bumper_1', 'assets/audio/bumper_1.mp3');
    this.load.audio('bumper_2', 'assets/audio/bumper_2.mp3');

    this.load.audio('bumper_special_1', 'assets/audio/bumper_special_1.mp3');
    this.load.audio('bumper_special_2', 'assets/audio/bumper_special_2.mp3');
    this.load.audio('bumper_special_3', 'assets/audio/bumper_special_3.mp3');
    this.load.audio('end_slot_empty', 'assets/audio/ball_falls_empty_zones.mp3');
    this.load.audio('end_slot_blue', 'assets/audio/end_slot_blue.mp3');
    this.load.audio('end_slot_green', 'assets/audio/end_slot_green.mp3');
    this.load.audio('end_slot_yellow', 'assets/audio/end_slot_yellow.mp3');
    this.load.audio('end_slot_red', 'assets/audio/end_slot_red.mp3');
    this.load.audio('bonus_lights', 'assets/audio/bonus_lights.mp3');

    this.load.audio('ball_enter_tube', 'assets/audio/ball_entering_the_tube.mp3');
    this.load.audio('ball_exit_tube', 'assets/audio/ball_exit_the_tube.mp3');
    this.load.audio('ball_in_tube', 'assets/audio/ball_through_the_tube.mp3');

    this.load.audio('general_button', 'assets/audio/general_button.mp3');
    this.load.audio('spin_button', 'assets/audio/spin_button.mp3');

    this.load.audio('get_balls', 'assets/audio/get_balls.mp3');
  }

  _loadPopupAssets() {
    this.load.image('button_bg', 'assets/img/popup/button_bg.png');
    this.load.image('button_lit', 'assets/img/popup/button_lit.png');

    this.load.spineJson("randomizer_data", "assets/img/popup/randomizer/LINKO-Game-Machine-09.json");
    this.load.spineAtlas("randomizer_atlas", "assets/img/popup/randomizer/machine_01.atlas");
  }

  _loadWinAnimationAssets() {
    this.load.spineJson("big_win_data", "assets/img/win_animations/big-win.json");
    this.load.spineAtlas("big_win_atlas", "assets/img/win_animations/big-win.atlas");

    this.load.spineJson("mega_win_data", "assets/img/win_animations/mega-win.json");
    this.load.spineAtlas("mega_win_atlas", "assets/img/win_animations/mega-win.atlas");

    this.load.spineJson("super_win_data", "assets/img/win_animations/super-win.json");
    this.load.spineAtlas("super_win_atlas", "assets/img/win_animations/super-win.atlas");

    this.load.spineJson("total_win_data", "assets/img/win_animations/total-win.json");
    this.load.spineAtlas("total_win_atlas", "assets/img/win_animations/total-win.atlas");

    this.load.spineJson("ultimate_win_data", "assets/img/win_animations/ultimate-win.json");
    this.load.spineAtlas("ultimate_win_atlas", "assets/img/win_animations/ultimate-win.atlas");

    this.load.spineJson("wheel_win_data", "assets/img/win_animations/Wheel.json");
    this.load.spineAtlas("wheel_win_atlas", "assets/img/win_animations/Wheel.atlas");
  }

  _loadWheelAssets() {
    this.load.image('wheel_screen_bg', 'assets/img/wheel_screen/bg.png');
    this.load.image('wheel_screen_bg_glow', 'assets/img/wheel_screen/bg_glow.png');
    this.load.image('wheel', 'assets/img/wheel_screen/wheel.png');
    this.load.image('wheel_inner', 'assets/img/wheel_screen/wheel_inner.png');
    this.load.image('wheel_screen_border_bars', 'assets/img/wheel_screen/border.png');
    this.load.image('wheel_screen_border_glow', 'assets/img/wheel_screen/border_light.png');
    this.load.image('wheel_sparkle', 'assets/img/wheel_screen/sparkle.png');
    this.load.image('wheel_screen_arrows', 'assets/img/wheel_screen/wheel_screen_arrows.png');
    this.load.image('wheel_frame', 'assets/img/wheel_screen/wheel_frame.png');
    this.load.image('wheel_neon', 'assets/img/wheel_screen/wheel_neon.png');
    this.load.image('spin_button_shadow', 'assets/img/wheel_screen/spin_button_shadow.png');
    this.load.image('spin_button_rainbow', 'assets/img/wheel_screen/spin_button_rainbow.png');
    this.load.image('spin_button_bg', 'assets/img/wheel_screen/spin_button_bg.png');
    this.load.image('spin_button', 'assets/img/wheel_screen/spin_button.png');
    this.load.image('wheel_arrow', 'assets/img/wheel_screen/wheel_arrow.png');
  }

  _loadHudAssets() {
    this.load.image('autoplay_button', 'assets/img/hud/autoplay.png');
    this.load.image('autoplay_stop_button', 'assets/img/hud/autoplay_stop.png');
    this.load.image('settings_button', 'assets/img/hud/settings.png');
    this.load.image('tap_button_bg', 'assets/img/hud/tap_button_bg.png');
  }

  _preloadDefaultSkin() {
    Object.keys(defaultSkin).forEach((key) => {
      const item = defaultSkin[key];
      if (item instanceof Array) {
        item.forEach((subItem) => {
          this._preloadSkinItem(subItem);
        })
      } else {
        this._preloadSkinItem(item);
      }
    });
  }

  _preloadBonusSkin() {
    Object.keys(bonusSkin).forEach((key) => {
      const item = bonusSkin[key];
      if (item instanceof Array) {
        item.forEach((subItem) => {
          this._preloadSkinItem(subItem);
        })
      } else {
        this._preloadSkinItem(item);
      }
    });
  }

  _preloadSkinItem(item: SkinItem) {
    if (!item) return;
    if (item.type === 'image' && item.path && item.key) {
      this.load.image(item.key, item.path);
    } else if (item.type === 'atlas' && item.path && item.key && item.json) {
      this.load.atlas(item.key, item.path, item.json.path);
    } else if (item.type === 'json' && item.path && item.key) {
      this.load.json(item.key, item.path);
    } else if (item.type === 'audio' && item.path && item.key) {
      this.load.audio(item.key, item.path);
    } else if (item.type === 'spine' && item.json && item.atlas) {
      this.load.spineJson(item.json.key, item.json.path);
      this.load.spineAtlas(item.atlas.key, item.atlas.path);
    }
  }

  create() {
    if (this._progressBar) this._progressBar.style.display = 'none';

    if (!global.game.musicController) global.game.musicController = new MusicController(this);
    if (!global.game.sfxController) global.game.sfxController = new SFXController(this);

    this.scene.start('MainScene');
    /**
     * This is how you would dynamically import the mainScene class (with code splitting),
     * add the mainScene to the Scene Manager
     * and start the scene.
     * The name of the chunk would be 'mainScene.chunk.js
     * Find more about code splitting here: https://webpack.js.org/guides/code-splitting/
     */
    // let someCondition = true
    // if (someCondition)
    //   import(/* webpackChunkName: "mainScene" */ './mainScene').then(mainScene => {
    //     this.scene.add('MainScene', mainScene.default, true)
    //   })
    // else console.log('The mainScene class will not even be loaded by the browser')
  }

  private loadUpdate(progress:number) {
    if (this._loadingContainer) this._loadingContainer.style.width = `${progress * 100}%`;
  }
}
