//import Resize from './utils/resize';
import {EVENTS} from "./utils/events";
import MusicController from "./objects/audio/musicController";
import SFXController from "./objects/audio/sfxController";


class Game extends Phaser.Game {
  private _mainScene: Phaser.Scene;

  public musicController: MusicController;
  public sfxController: SFXController;
  constructor(config) {
    super(config);

    global.game = this;

    this.registry.set('musicMute', false);
    this.registry.set('sfxMute', false);

    //this._gameScale = new Resize(this);

    this.scene.start('PreloadScene');
  }

  public toggleMusic(musicOn: boolean) {
    this.registry.set('musicMute', !musicOn);
  }

  public toggleSFX(sfxOn: boolean) {
    this.registry.set('sfxMute', !sfxOn);
  }

  startAutoplay({power, autoplayBalance}: {power: number, autoplayBalance: number}) {
    console.log('Game start autoplay');
    if (this.registry.get('autoplayBalance') <= 0) return;
    this.registry.set('autoplayBalance', autoplayBalance);
    this.registry.set('autoplayPower', power);
    this.registry.set('autoplay', true);
    console.log('this.game.registry: ', this.registry);
  }

  stopAutoplay() {
    this.registry.set('autoplay', false);
  }

  showMessage(message: string) {
    this.events.emit(EVENTS.SHOW_MESSAGE, message);
  }
}


export default Game;
