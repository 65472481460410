import {getBoardConfig, getWinType} from "../../api/plinkoApi.mock";

export type BalanceResponse = {
    balance: number;
    wheelRounds: WheelRoundConfig[] | null;
    bonusRounds: BonusRoundConfig[] | null;
}

export type BoardConfigResponse = {
    map: number[][]; // 2d array
    balance: BalanceResponse;
    tabs: {id: number, value:string}[];
    winFieldsValues: string[];
}

export type TargetSlotResponse = {
    randomSeed: string;
    targetSlot: number | null;
    miss: string | null;
    balance: BalanceResponse;
    balanceChange: number;
    bonusBalanceChange?: number;
    tab: { id: number, value: string } | false;
    bumperSide: Number | false;
    railSide: 0 | 1 | false;
    wheelRound?: WheelRoundConfig | null;
    isSpecialBall: boolean;
}

export type WheelResponse = {
    value: number
    rotation: number
}

export type WheelRoundConfig = { id: string };

export type BonusRoundConfig = {
    id: string,
    type: string,
    balls: number,
    balanceOrder: number[],
}

export type BonusRoundTargets = {targetSlot: number, value: number}[];

export const SPECIAL_TYPES = {
    x0: 'x0',
    x2: 'x2',
    x5: 'x5',
    x10: 'x10',
    x50: 'x50',
    x100: 'x100',
}

export const MissSide = {
    left: 'left',
    right: 'right',
}

export interface LinkoApi {
    getBoardConfig(getBoardConfig?: BonusRoundConfig): Promise<BoardConfigResponse>;

    launchBonusBall(id: string, launchPower: number): Promise<TargetSlotResponse>;

    launchNormalBall(launchPower: number): Promise<TargetSlotResponse>;

    getBonusRound(id: string): Promise<BonusRoundConfig>;

    getWheelRound(id: string): Promise<WheelRoundConfig>;

    getWinType(value: number): Promise<string>;

    spinWheel(id:string): Promise<WheelResponse>;

    openSettings(): Promise<boolean>;

    openAutoplay(): Promise<boolean>;
}
