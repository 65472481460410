export function createButtonFxAnimation(postFx: Phaser.FX.Bloom, values = [1.7, 1, 2, 1]) {
    return {
        repeat: -1,
        tweens: [
            {
                delay: 1000,
                targets: postFx,
                blurStrength: values[0],
                ease: 'Quad.easeIn',
                duration: 1200,
            }, {
                targets: postFx,
                blurStrength: values[1],
                ease: 'Quad.easeOut',
                duration: 800,
            }, {
                targets: postFx,
                blurStrength: values[2],
                ease: 'Quad.easeIn',
                duration: 1200,
            }, {
                targets: postFx,
                blurStrength: values[3],
                ease: 'Quad.easeOut',
                duration: 800,
            }
        ]
    }
}
