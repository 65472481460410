export function createBackgroundFxAnimation(postFx: Phaser.FX.Bloom, values = [1.2, 1, 1.2, 1, 1.2, 1, 1.5, 1, 0.1, 1, 0.1, 1]) {
    return {
        repeat: -1,
        tweens: [
            {
                delay: 1000,
                targets: postFx,
                blurStrength: values[0],
                ease: 'Quad.easeIn',
                duration: 4000,
            }, {
                targets: postFx,
                blurStrength: values[1],
                ease: 'Quad.easeOut',
                duration: 2000,
            }, {
                targets: postFx,
                blurStrength: values[2],
                ease: 'Quad.easeIn',
                duration: 4000,
            }, {
                targets: postFx,
                blurStrength: values[3],
                ease: 'Quad.easeOut',
                duration: 2000,
            }, {
                targets: postFx,
                blurStrength: values[4],
                ease: 'Quad.easeIn',
                duration: 4000,
            }, {
                targets: postFx,
                blurStrength: values[5],
                ease: 'Quad.easeOut',
                duration: 2000,
            }, {
                targets: postFx,
                blurStrength: values[6],
                ease: 'Quad.easeIn',
                duration: 8000,
            }, {
                targets: postFx,
                blurStrength: values[7],
                ease: 'Quad.easeOut',
                duration: 8000,
            }, {
                targets: postFx,
                blurStrength: values[8],
                ease: 'Quad.easeOut',
                duration: 2000,
            }, {
                targets: postFx,
                blurStrength: values[9],
                ease: 'Quad.easeOut',
                duration: 1000,
            }, {
                targets: postFx,
                blurStrength: values[10],
                ease: 'Quad.easeOut',
                duration: 2000,
            }, {
                targets: postFx,
                blurStrength: values[11],
                ease: 'Quad.easeOut',
                duration: 1000,
            },
        ]
    }
}
