import {Scene} from "phaser";
import Options = Phaser.Types.GameObjects.Graphics.Options;
import Ball from "./ball";
import {SkinItem} from "../types/skin";

class Point {
    public x: number;
    public y: number;
    public time: number;
    constructor(x: number, y: number, time: number) {
        this.x = x;
        this.y = y;
        this.time = time;
    }
}

const Color = Phaser.Display.Color;

const normalizeValue = function (value: number, min: number, max: number) {
    return (value - min) / (max - min);
};

const linearInterpolation = function (norm: number, min: number, max: number) {
    return (max - min) * norm + min;
};

export default class Trail extends Phaser.GameObjects.Graphics {
    private _positions: Point[];
    private _stopped: boolean;
    private _thickness: { start: number, end: number };
    private _opacity: number;
    private _pointLifetime: number;
    private _maxPoints: number;
    constructor(scene: Scene, trailOptions: { thickness: { start: number, end: number }, pointLifetime: number, maxPoints: number, opacity: number }, graphicsOptions: Options) {
        super(scene, graphicsOptions);

        this._stopped = false;
        this._thickness = trailOptions.thickness;
        this._pointLifetime = trailOptions.pointLifetime;
        this._maxPoints = trailOptions.maxPoints;
        this._opacity = trailOptions.opacity;
        this._positions = [];
    }

    public enableGlow(glowColor: number) {
        this.postFX.addGlow(glowColor, 4, 0, false, 0.1, 8);
    }

    private _clearPositions() {
        this._positions = [];
    }

    stop() {
        this._stopped = true;
    }

    public update(time:number, delta: number, ball: Ball, tailColor: number) {
        if (this._stopped) return;
        this._positions.unshift(new Point(ball.x, ball.y, this._pointLifetime));
        if (this._positions.length > this._maxPoints)
            this._positions.pop();

        this.clear();
        if (this._positions.length > 0) {
            this.beginPath();
            this.moveTo(this._positions[0].x, this._positions[0].y);
            for (let index = 1; index < this._positions.length - 1; ++index) {
                const point = this._positions[index + 1];
                this.lineTo(point.x, point.y);
                this.lineStyle(
                    linearInterpolation((this._positions.length - index) / (this._positions.length), this._thickness.end, this._thickness.start),
                    tailColor,
                    this._opacity,
                );
                this.strokePath();
            }

            //trail.closePath();
        }
        for (let index = 0; index < this._positions.length; ++index) {
            const point = this._positions[index];

            point.time -= 0.5;
            if (point.time <= 0) {
                this._positions.splice(index, 1);
                index -= 1;
            }
        }

        super.update(time, delta);
    }

    public destroy(fromScene?: boolean) {
        this._clearPositions();
        super.destroy(fromScene);
    }
}
