import {Scene} from "phaser";

export function scaleInOut (scene: Scene, target: any, scale: number = 1.1, durationIn = 1000, durationOut = 0, delayIn = 0, delayOut = 0, repeat = 0, yoyo = false) {
    return {
        targets: target,
        scale: scale,
        duration: durationIn,
        delay: delayIn,
        ease: 'Quad.easeIn',
        repeat: repeat,
        yoyo: yoyo,
        onComplete: durationOut ? () => {
            scene.tweens.add({
                targets: target,
                scale: 1,
                duration: durationOut,
                delay: delayOut,
                ease: 'Quad.easeOut',
            });
        } : undefined,
    };
}

export function fadeInOut (scene: Scene, target: any, durationIn = 1000, durationOut = 0, delayIn = 0, delayOut = 0, repeat = 0, yoyo = false) {
    return {
        targets: target,
        alpha: 1,
        duration: durationIn,
        delay: delayIn,
        ease: 'Quad.easeIn',
        repeat: repeat,
        yoyo: yoyo,
        onComplete: durationOut ? () => {
            scene.tweens.add({
                targets: target,
                alpha: 0,
                duration: durationOut,
                delay: delayOut,
                ease: 'Quad.easeOut',
            });
        } : undefined,
    };
}

export function blink (targets: any[], scale: number, duration = 1000) {
    return {
        targets: targets,
        scale: scale,
        alpha: 0,
        ease: 'Quad.easeInOut',
        duration: duration,
        yoyo: true,
        loop: -1,
    }
}
