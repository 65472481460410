import Group = Phaser.GameObjects.Group;
import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import Rectangle = Phaser.Geom.Rectangle;
import {Scene} from "phaser";
import Pool from "../../pool";
import {SkinItem} from "../../../types/skin";

export default class BonusBgParticles extends Container {
    private _bgLayer1: Image;
    private _linesPool: Pool;
    private _skin: SkinItem;
    private _highlightColor: number;
    constructor(scene: Scene, bgLayer1: Image, bgParticlesSkin: SkinItem, highlightColor: number) {
        super(scene);
        this._bgLayer1 = bgLayer1;
        this._skin = bgParticlesSkin;
        this._highlightColor = highlightColor;
        console.log('bgParticlesSkin', this._skin);

        this._createPoints();
        this._createLines();
    }

    _createPoints() {
        const particles = this.scene.add.particles(this._skin.position.x, this._skin.position.y, this._skin.key, {
            frame: [this._skin.data!.frames[0], this._skin.data!.frames[1]],
            lifespan: 4000,
            frequency: 100,
            speedY: {min: -50, max: -100},
            scale: {start: 1, end: 0, ease: 'Quad.easeIn', random: true},
            blendMode: 'ADD',
            tint: this._highlightColor,
        });
        particles.addEmitZone({
            quantity: 0,
            type: 'random',
            source: new Rectangle(
                -this._bgLayer1.displayWidth * 0.3,
                -this._bgLayer1.displayHeight * 0.3,
                this._bgLayer1.displayWidth * 0.6,
                this._bgLayer1.displayHeight * 0.85
            )
        });
        this.add(particles);
    }

    _createLines() {
        this._linesPool = new Pool(this.scene, undefined, this._createLine.bind(this));
        this._linesPool.initializeWithSize(6);
        for (let i = 0; i < 6; i++) {
            this.scene.time.delayedCall(i * 500, () => {
                this._animateLines();
            })
        }
    }

    private _createLine() {
        const line = this.scene.add.image(0, 0, this._skin.key, this._skin.data!.frames[2]);
        line.setTint(this._highlightColor);
        line.setOrigin(0.5, 0.5);
        return line;
    }

    _animateLines() {
        if (!this.scene) return;

        const posX = this._bgLayer1.x + Math.random() > 0.5 ? this._bgLayer1.displayWidth * 0.48 : -this._bgLayer1.displayWidth * 0.48;
        const posY = this._bgLayer1.y + Phaser.Math.Between(this._bgLayer1.displayHeight * 0.33, this._bgLayer1.displayHeight * 0.5);
        const line = this._linesPool.spawn(posX, posY);
        line.setScale(0, 0.5);
        this.add(line);

        const duration = Phaser.Math.Between(3000, 4000);
        this.scene.tweens.add({
            targets: line,
            x: this._bgLayer1.x,
            scaleX: Phaser.Math.FloatBetween(0.05, 0.5),
            duration: duration,
            ease: 'Sine.easeIn',
            onComplete: () => {
                if (!this.scene) return;
                this.scene.tweens.add({
                    targets: line,
                    x: posX > this._bgLayer1.x ? -this._bgLayer1.displayWidth * 0.48 : this._bgLayer1.displayWidth * 0.48,
                    scaleX: 0,
                    duration: duration,
                    ease: 'Sine.easeOut',
                    onComplete: () => {
                        if (!this.scene) return;
                        this._linesPool.despawn(line);
                        this._animateLines();
                    }
                });
            }
        });
    }
}
