import Phaser, {Scene} from "phaser";
import {DEBUG} from "../../data/constants";
import {Skin, SkinItem, SkinItems} from "../../types/skin";
import {VisualObject} from "../../types/visualObject";
import Image = Phaser.GameObjects.Image;

export function createRail(scene: Scene, x: number, y: number, side: 'left' | 'right', skin: Skin) {
    const pipeSkin = (side === 'left' ? skin.pipeLeft : skin.pipeRight) as SkinItem;
    const railView = scene.add.image(x + pipeSkin.position.x, y + pipeSkin.position.y, pipeSkin.key);
    railView.setOrigin(pipeSkin.origin.x, pipeSkin.origin.y);

    const pipeTopSkin = (side === 'left' ? skin.pipeLeftTop : skin.pipeRightTop) as SkinItem;
    const railViewTop = scene.add.image(
        railView.x + pipeTopSkin.position.x,
        railView.y + pipeTopSkin.position.y,
        pipeTopSkin.key);
    railViewTop.setOrigin(pipeTopSkin.origin.x, pipeTopSkin.origin.y);

    const {
        arrow,
        arrowGlow1,
        arrowGlow2,
    } = createRailArrows(scene, x, y, side, skin);

    const pathDrawn = scene.add.graphics({x: 0, y: 0});
    pathDrawn.lineStyle(4, 0xff0000, 1);

    let path = createPath(railView, pipeSkin);

    if (DEBUG) path.draw(pathDrawn, 64);

    let ringGlows: VisualObject[] = [];
    if (skin.pipeRingGlow) {
        ringGlows = createRingGlows(scene, path, side, skin.pipeRingGlow as SkinItem);
        railView.setData('ringGlows', ringGlows);
    }

    if (arrow && arrowGlow1 && arrowGlow2) {
        const arrowBlinkTween = createArrowBlinkTween(scene, arrowGlow1, arrowGlow2);
        railView.setData('arrowBlinkTween', arrowBlinkTween);
    }

    return {railView, railViewTop, arrow, arrowGlow1, arrowGlow2, pathDrawn, ringGlows, path};
}

function createRailArrows (scene: Scene, x: number, y: number, side : 'left' | 'right', skin: Skin) {
    const arrowItems: {arrow: Image | null, arrowGlow1: Image | null, arrowGlow2: Image | null} = {
        arrow: null, arrowGlow1: null, arrowGlow2: null
    }

    const arrowSkin = (side === 'left' ? skin.arrowLeft : skin.arrowRight) as SkinItem;
    if (arrowSkin) {
        const arrow = scene.add.image(x + arrowSkin.position.x, y + arrowSkin.position.y, arrowSkin.key);
        arrow.setOrigin(arrowSkin.origin.x, arrowSkin.origin.y);
        arrowItems.arrow = arrow;

        const arrowGlow1Skin = (side === 'left' ? skin.arrowLeftGlow1 : skin.arrowRightGlow1) as SkinItem;

        if (arrowGlow1Skin) {
            const arrowGlow1 = scene.add.image(arrow.x + arrowGlow1Skin.position.x, arrow.y + arrowGlow1Skin.position.y, arrowGlow1Skin.key);
            arrowGlow1.setOrigin(arrowGlow1Skin.origin.x, arrowGlow1Skin.origin.y);
            arrowGlow1.alpha = 0;
            arrowItems.arrowGlow1 = arrowGlow1;
        }

        const arrowGlow2Skin = (side === 'left' ? skin.arrowLeftGlow2 : skin.arrowRightGlow2) as SkinItem;
        if (arrowGlow2Skin) {
            const arrowGlow2 = scene.add.image(arrow.x + arrowGlow2Skin.position.x, arrow.y + arrowGlow2Skin.position.y, arrowGlow2Skin.key);
            arrowGlow2.setOrigin(arrowGlow2Skin.origin.x, arrowGlow2Skin.origin.y);
            arrowGlow2.alpha = 0;
            arrowItems.arrowGlow2 = arrowGlow2;
        }
    }

    return arrowItems;
}

function createRingGlows (scene: Scene, path: Phaser.Curves.Path, side: 'left' | 'right', pipeRingGlowSkin: SkinItem) : VisualObject[] {
    const ringGlowPositions = side === 'left' ? pipeRingGlowSkin!.data!.leftPipeRings : pipeRingGlowSkin!.data!.rightPipeRings;
    return ringGlowPositions.map((ringGlowPos) => {
        const ringPos = path.getPoint(ringGlowPos.t);
        const tangent = path.getTangent(ringGlowPos.t);
        const ringGlow = scene.add.image(
            ringPos.x + ringGlowPos.position.x,
            ringPos.y + ringGlowPos.position.y,
            `pipe_ring_glow`
        );
        ringGlow.setData('t', ringGlowPos.t);
        ringGlow.setRotation(tangent.angle() - Math.PI / 2 + ringGlowPos.angle);
        ringGlow.setAlpha(0);
        createRingGlowTween(scene, ringGlow);
        return ringGlow;
    })
}

function createPath (railView: Phaser.GameObjects.Image, railSkin: SkinItem) {
    let skinPath: {x: number, y:number}[] = railSkin.data!.path.slice();
    let path: Phaser.Curves.Path = new Phaser.Curves.Path(railView.x + railView.displayWidth * skinPath[0].x, railView.y + railView.displayHeight * skinPath[0].y);
    const splineTo = skinPath.slice(1)
        .map((point) => new Phaser.Math.Vector2(railView.x + railView.displayWidth * point.x, railView.y + railView.displayHeight * point.y));
    path.splineTo(splineTo);
    return path;
}

function createArrowBlinkTween (scene: Scene, arrowGlow1: Phaser.GameObjects.Image, arrowGlow2: Phaser.GameObjects.Image) {
    return scene.tweens.chain({
        tweens: [
            {
                targets: [arrowGlow1],
                alpha: 1,
                duration: 400,
                ease: 'Quad.easeInOut',
            },{
                targets: [arrowGlow2],
                alpha: 1,
                duration: 400,
                ease: 'Quad.easeInOut',
            },{
                targets: [arrowGlow1],
                alpha: 0,
                duration: 400,
                ease: 'Quad.easeInOut',
            },{
                targets: [arrowGlow2],
                alpha: 0,
                duration: 400,
                ease: 'Quad.easeInOut',
            },
        ],
        loop: -1,
    });
}

export function createRingGlowTween (scene: Scene, ringGlow: Phaser.GameObjects.Image) {
    const glowTween = scene.tweens.add({
        paused: true,
        targets: [ringGlow],
        alpha: 1,
        duration: 200,
        ease: 'Sine.easeIn',
        onComplete: () => {
            scene.tweens.add({
                targets: [ringGlow],
                alpha: 0,
                duration: 200,
                ease: 'Sine.easeOut',
                delay: 600,
            });
        }
    });

    ringGlow.setData('glowTween', glowTween);
    return glowTween;
}
