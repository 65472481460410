import { SpineGameObject } from "@esotericsoftware/spine-phaser/dist/SpineGameObject";
import {Skin, SkinItem, SkinItems} from "../types/skin";

const TAB_SIZE = 42;

export default class Tabs extends Phaser.GameObjects.Container {
    private _tabsCount: number;
    private _tabs: Phaser.GameObjects.Image[] = [];
    private _nextHits: Phaser.GameObjects.Image[] = [];
    private _arrowGlowTw: Phaser.Tweens.Tween;
    private _spineWheel: SpineGameObject;
    private _tabsValues: { id: number; value: string }[];
    constructor(scene: Phaser.Scene, skin:Skin, tabs: {id: number, value: string}[] = []) {
        super(scene);
        this._tabsValues = tabs;
        this._create(skin);
    }

    private _create(skin: Skin) {
        const wheelSkin = skin.wheel as SkinItem;
        if (wheelSkin) {
            this._spineWheel = this.scene.make.spine({
                x: wheelSkin.position.x, y: wheelSkin.position.y,
                dataKey: wheelSkin.json!.key, atlasKey: wheelSkin.atlas!.key
            });
            this._spineWheel.setScale(0.5);
            this._spineWheel.animationState.addListener({
                start: (entry) => {
                    console.log(`Started animation`, entry);
                },
                complete: (entry) => {
                    console.log(`Completed animation`, entry);
                    if (entry && entry.animation?.name === 'wheel') {
                        this._spineWheel.animationState.setAnimation(0, 'speed 1 sec', true);
                    }
                },
            })
            this.add(this._spineWheel);
            this._spineWheel.animationState.setAnimation(0, 'speed 1 sec', true);
        }


        const wheelArrowsSkin = skin.wheelArrows as SkinItem;
        if (wheelArrowsSkin) {
            const arrows = this.scene.add.image(wheelArrowsSkin.position.x, wheelArrowsSkin.position.y, wheelArrowsSkin.key);
            this.add(arrows);
        }


        const wheelTabsSkins = skin.wheelTabs as SkinItems;
        const wheelTabsPressedSkins = skin.wheelPressedTabs as SkinItems;
        const wheelArrowGlowSkin = skin.wheelArrowGlow as SkinItem;

        if (wheelTabsSkins && wheelTabsSkins.length > 0) {
            this._tabs = this._tabsValues.map((tab, index) => {
                return this._createTab(tab, index, wheelTabsSkins[index], wheelArrowGlowSkin, wheelTabsPressedSkins[index]);
            });
        }

    }

    private _createTab({id, value}: {id: number, value: string}, i: number, wheelTabSkin: SkinItem, wheelArrowGlowSkin: SkinItem, wheelTabPressedSkin: SkinItem) {
        const tab = this.scene.add.image(wheelTabSkin.position.x,  wheelTabSkin.position.y, wheelTabSkin.key);
        tab.setData('id', id);
        tab.setData('value', value);
        tab.setData('pressed', false);
        tab.setData('pressedSkin', wheelTabPressedSkin);
        tab.setOrigin(wheelTabSkin.origin.x, wheelTabSkin.origin.y);
        this.addAt(tab, 1);

        const glowPositions: {x: number, y: number}[] = wheelArrowGlowSkin.data!.positions.slice();
        const arrowGlow = this.scene.add.image(glowPositions[i].x , glowPositions[i].y, wheelArrowGlowSkin.key);
        arrowGlow.setOrigin(wheelArrowGlowSkin.origin.x, wheelArrowGlowSkin.origin.y);
        this.add(arrowGlow);
        tab.setData('arrowGlow', arrowGlow);

        const arrowGlowTw = this.scene.tweens.add({
            targets: arrowGlow,
            alpha: 0,
            duration: 600,
            ease: 'Sine.easeInOut',
            yoyo: true,
            repeat: -1,
        });

        tab.setData('arrowGlowTw', arrowGlowTw);
        return tab;
    }

    getTabToHit(tab: {id: number, value: string}) {
        const nextHit = this._tabs.find((t) => t.getData('id') === tab.id);
        if (nextHit) {
            this._nextHits.push(nextHit);
            return nextHit;
        }
        return null;
    }

    hitTab(tab: {id: number, value: string}) {
        if (this._nextHits.length > 0) {
            const nextHit = this._nextHits.find((t) => t.getData('id') === tab.id);
            if (nextHit && !nextHit.getData('pressed')) {
                const pressedSkin = nextHit.getData('pressedSkin');
                nextHit.setPosition(pressedSkin.position.x, pressedSkin.position.y);
                nextHit.getData('arrowGlow').setVisible(false);
                nextHit.setData('pressed', true);
                nextHit.setTexture(pressedSkin.key);
                nextHit.setOrigin(pressedSkin.origin.x, pressedSkin.origin.y);

                if (this._spineWheel.animationState.getCurrent(0)?.animation?.name !== 'wheel'){
                    global.game.sfxController.playSmallWheelSpinningSfx();
                    this._spineWheel.animationState.setAnimation(0, 'wheel', false);
                }
            }
        }
    }

    bonusAnimation() {
        this._tabs.forEach((tab, index) => {
            const tabGlowTw = tab.getData('arrowGlowTw');
            tabGlowTw.delay = index * 100;
            tabGlowTw.restart();
            tabGlowTw.setTimeScale(6);
        });
    }

    stopBonusAnimation() {
        this._tabs.forEach((tab, index) => {
            const tabGlowTw = tab.getData('arrowGlowTw');
            tabGlowTw.delay = 0;
            tabGlowTw.restart();
            tabGlowTw.setTimeScale(1);
        });
    }
}
