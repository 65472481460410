import Phaser from "phaser";
import Trail from "./trail";
import {SkinItem} from "../types/skin";
import * as console from "console";

export default class Ball extends Phaser.GameObjects.Sprite {
    private _bonusType: string | undefined;
    private _skin: SkinItem;
    get shadow(): Phaser.GameObjects.Image {
        return this._shadow;
    }

    get specialPinsHit(): number {
        return this._specialPinsHit;
    }

    private _specialPinsHit: number = 0;
    private _shadow: Phaser.GameObjects.Image;
    private _trail: Trail;
    private _tail: Trail;
    private _firstSpecialPinHit: boolean = false;

    constructor(scene: Phaser.Scene, x: number, y: number, skin: SkinItem, bonusType?: string) {
        super(scene, x, y, skin.key);

        this._skin = skin;
        this._bonusType = bonusType;
    }

    setShadow(shadow: Phaser.GameObjects.Image) {
        this._shadow = shadow;
    }

    setTrail(trail: Trail) {
        this._trail = trail;
    }

    setTail(tail: Trail) {
        this._tail = tail;
    }

    stopTrail() {
        if (this._trail) this._trail.stop();
        if (this._tail) {
            this._tail.destroy();
        }
    }

    setFirstSpecialPinHit(value: boolean) {
        this._firstSpecialPinHit = value;
        if (value) {
            const glowColor = this._bonusType ? (this._skin.data!.trail.glow[this._bonusType][0] || this._skin.data!.trail.glow['default'][0]) : this._skin.data!.trail.glow;
            this._tail.enableGlow(glowColor);
        }

        if ( !this.getData('isSpecialBall') ) {
            this.scene.time.delayedCall(1000, () => {
                this.scene.tweens.add({
                    targets: this._trail,
                    alpha: 0,
                    duration: 500,
                    ease: 'Linear',
                    onComplete: () => {
                        this._trail.destroy();
                    }
                })
            });
        }
    }

    addSpecialPinHit() {
        this._specialPinsHit++;
    }

    update(time: number, delta: number) {
        if (this._firstSpecialPinHit) {
            const tailColor = this._skin.data!.trail.color;
            if (this._trail) this._trail.update(time, delta, this, tailColor);
            if (this._tail) this._tail.update(time, delta, this, tailColor);
        }
    }

    destroy(fromScene?: boolean) {
        if (this._shadow) this._shadow.destroy();
        if (this._trail) this._trail.destroy();
        if (this._tail) this._tail.destroy();
        super.destroy(fromScene);
    }
}
