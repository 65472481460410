export const EVENTS = {
    POINTER_DOWN: 'pointerdown',
    POINTER_UP: 'pointerup',
    POINTER_OVER: 'pointerover',
    POINTER_OUT: 'pointerout',
    UPDATE: 'update',
    POST_UPDATE: 'postupdate',
    DESTROY: 'destroy',
    DRAG: 'drag',
    DRAG_START: 'dragstart',
    DRAG_END: 'dragend',
    BUTTON_TAPPED: 'button_tapped',
    LAUNCH_DOWN: 'launch_down',
    LAUNCH_UP: 'launch_up',
    NO_BALLS_LEFT: 'no_balls_left',
    SHOW_BONUS_PROMPT: 'show_bonus_prompt',
    BONUS_PROMPT_HIDDEN: 'bonus_propt_hidden',
    BONUS_ANIMATION_STARTED: 'bonus_animation_started',
    BONUS_ANIMATION_STOPPED: 'bonus_animation_stopped',
    BONUS_ROUND_COMPLETE: 'bonus_round_complete',
    BONUS_COMPLETE: 'bonus_complete',
    RANDOMIZER_COMPLETE: 'randomizer_complete',
    BALANCE_CHANGE: 'balance_change',
    BALANCE_CHANGE_VALIDATED: 'balance_change_validated',
    GAME_INITIALIZED: 'game_initialized',
    START_WHEEL: 'start_wheel',
    SPECIAL_SPIN_COMPLETE: 'special_spin_complete',
    SHOW_SETTINGS: 'show_settings',
    WORLD_STEP: 'worldstep',
    CHANGED_DATA_AUTOPLAY: 'changedata-autoplay',
    CHANGED_DATA_MUSIC_MUTE: 'changedata-musicMute',
    CHANGED_DATA_SFX_MUTE: 'changedata-sfxMute',
    CHANGED_DATA_AUTOPLAY_BALANCE: 'changedata-autoplayBalance',
    SHOW_MESSAGE: 'show_message',
};
