import { LinkoApi } from '../types/api/responseTypes';
import * as mockApi from './plinkoApi.mock';
import * as mockWheelApi from './wheelApi.mock';
import * as mockUiApi from './uiApi.mock';

let api: LinkoApi = {
  ...mockApi,
  ...mockWheelApi,
  ...mockUiApi,
};

const getApi = (): LinkoApi => {
  return api;
}

export const setImplementation = (impl: LinkoApi) => {
  api = impl;
}

export default getApi;
