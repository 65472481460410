import {Scene} from 'phaser';
import WebAudioSound = Phaser.Sound.WebAudioSound;

export function fadeIn (scene: Scene, audio: Phaser.Sound.HTML5AudioSound | WebAudioSound, volume: number = 1, duration: number = 1000): Promise<Phaser.Sound.BaseSound> {
  const s = {
    v: 0
  }
  return new Promise((resolve) => {
    scene.tweens.add({
      targets: s,
      v: { from: 0, to: volume },
      duration,
      ease: 'Linear',
      onUpdate: (tween, target, key, current, previous, param) => {
        audio.volume = s.v;
      },
      onComplete: () => {
        resolve(audio);
      }
    })
  })
}

export function fadeOut (scene: Scene, audio: Phaser.Sound.HTML5AudioSound | WebAudioSound, duration: number = 1000): Promise<Phaser.Sound.BaseSound> {
  const s = {
    v: audio.volume
  }
  return new Promise((resolve) => {
    scene.tweens.add({
      targets: s,
      volume: { from: s.v, to: 0 },
      duration,
      ease: 'Linear',
      onUpdate: (tween, target, key, current, previous, param) => {
        audio.volume = s.v;
      },
      onComplete: () => resolve(audio)
    })
  })
}
